import React, {useContext, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../rpcSender";
import {DebugDeleteProviderRequest, DebugDeleteProviderResponse} from "../../../provider_api";
import {useNavigate} from "react-router-dom";
import {ProviderProfileContext} from "../../../ProviderProfileProvider";

export const DeleteBusinessDebug = () => {

  const {provider, setProvider, setBusinessProfile} = useContext(ProviderProfileContext);
  const {getIdTokenClaims} = useAuth0();
  const [error, setError] = useState<string>()
  const navigate = useNavigate();

  const deleteRpc = (resetOnly: boolean) => {

    let request = DebugDeleteProviderRequest.encode(new DebugDeleteProviderRequest({
      resetOnly: resetOnly,
    })).finish();
    
    SendRpc(getIdTokenClaims, 'debug_delete_provider', request)
        .then(value => {
          let response = DebugDeleteProviderResponse.decode(value);
          if (response.okay) {
            setProvider(response.provider);
            setBusinessProfile(response.businessProfile);
          } else {
            setError("error deleting the thing")
          }

        })
        .catch(e => {
          console.log(e);
          setError("error deleting the thing")
        });
  }


  return (
      <div style={{display: 'flex', flexDirection: 'column', padding: 20, gap: 20}}>
        <button onClick={() => deleteRpc(true)}>Reset your provider and business</button>
        <button onClick={() => deleteRpc(false)}>Delete them both! You'll need a new invite code.</button>
        {error && <div style={{color: 'red'}}>error deleting profile</div>}
      </div>
  );
}