import React from 'react';
import './Onboarding.css';
import {FieldWithError} from "./CreateProviderProfile";
import TimezoneSelect from "react-timezone-select"

type Props = {
  label: string;
  value: FieldWithError<string>;
  onChange: (value: string) => void;
}

export const LabeledTimeZoneInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'OnboardingTextInputLabel'}>{props.label}</div>

    <TimezoneSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 0,
            padding: 0,
            margin: 0,
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            padding: 12,
          }),
        }}
        classNames={{
          // input: (s) => 'TimeZoneInputLabel',
          container: (s) => 'OnboardingTextInput',
        }}
        value={props.value.value || ''} onChange={v => props.onChange(v.value)}/>

  </div>

}