import {Link, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {SendRpc} from '../../rpcSender';
import {useAuth0} from '@auth0/auth0-react';
import {useProviderProfile} from "../../ProviderProfileProvider";
import {
  AcceptLoginInviteRequest,
  AcceptLoginInviteResponse,
  CheckLoginInviteRequest,
  CheckLoginInviteResponse
} from "../../provider_api";

export const RedeemTeamMemberInvite = () => {

  const {providerId, inviteCode} = useParams()
  const navigate = useNavigate();

  const {user} = useAuth0();
  const {getIdTokenClaims} = useAuth0();
  const {provider, setProvider, businessProfile, setBusinessProfile} = useProviderProfile();

  // if the rpc is out
  const [checkingInvite, setCheckingInvite] = useState(true);
  const [checkInviteError, setCheckInviteError] = useState('');
  const [checkInviteResponse, setCheckInviteResponse] = useState<CheckLoginInviteResponse | null>();

  const [redeemError, setRedeemError] = useState('');

  useEffect(() => {

    setCheckInviteResponse(null);

    if (!inviteCode) {
      setCheckingInvite(false);
      return;
    }

    setCheckingInvite(true)

    let request = CheckLoginInviteRequest.encode(new CheckLoginInviteRequest({
      inviteCode: inviteCode,
      providerId: providerId,
    })).finish();

    SendRpc(getIdTokenClaims, 'action/check_login_invite', request).then(r => {
      let response = CheckLoginInviteResponse.decode(r);
      setCheckInviteResponse(response);
    }).catch(e => {
      console.log(e);
      setCheckInviteError('Error checking your invite. Please try again later')
    }).finally(() => {
      setCheckingInvite(false);
    });
  }, [inviteCode]);


  const handleAccept = () => {

    setRedeemError('');

    let request = new AcceptLoginInviteRequest({
      providerId: providerId,
      inviteCode: inviteCode,
    });

    let proto = AcceptLoginInviteRequest.encode(request).finish();

    SendRpc(getIdTokenClaims, 'action/accept_login_invite', proto).then(r => {
      let response = AcceptLoginInviteResponse.decode(r);
      console.log(response, JSON.stringify(response));
      if (response.okay) {
        // This changes the context value which forces a rerender
        setProvider(response.provider);
        setBusinessProfile(response.businessProfile)
        navigate('/');
      } else {
        setRedeemError('Response not okay')
      }
    }).catch(e => {
      console.log(e);
      setRedeemError(e);
    });
  }

  // Note this branch happens as soon as there is a profile, aka as soon
  // as the redeem invite succeeds. Any special UI transition should be here.
  if (businessProfile) {
    return <div>Welcome to the Kenko Community, {user?.nickname}!
      <br/><br/>
      Let's start setting up your 
      <Link to={'/'}>Kenko provider profile</Link>
    </div> 
  }

  if (checkingInvite) {
    return <div>Looking up your invite...</div>
  }

  if (checkInviteError || !checkInviteResponse?.okay) {
    return <div>error checking invite code. it may be invalid or expired</div>
  }

  return <div>
    thanks for your interest in becoming a Kenko provider!
    <br/><br/>
    you've been invited to join <b>{checkInviteResponse.businessName || 'unknown name'}</b>
    <br/><br/>
    would you like to join as {user?.email}?&nbsp;
    <button onClick={handleAccept}>yes!</button>
    {redeemError && <div style={{color: 'red'}}>Error redeeming, sorry!</div>}
  </div>
}
