import React, {useEffect, useState} from 'react';
import {
  CreateOrUpdateTeamMemberRequest,
  CreateOrUpdateTeamMemberResponse, GenerateLoginInviteRequest, GenerateLoginInviteResponse,
  GetLoginsForBusinessRequest,
  GetLoginsForBusinessResponse,
  IApiProviderLoginProto,
  space
} from "../../../../provider_api";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../../rpcSender";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FieldWithError} from "../../Survey/CreateProviderProfile";
import {LabeledTextInput} from "../../Survey/LabeledTextInput";
import {LabeledPhoneInput} from "../../Survey/LabeledPhoneInput";
import {PhotoUpload} from "../BusinessProfile/PhotoUpload";
import {RpcState} from "../../../../RpcState";
import ProviderBioProto = space.kenko.proto.ProviderBioProto;


export type ServiceData = {
  sku: FieldWithError<string>,
  name: FieldWithError<string>,
  category: FieldWithError<string>,
  description: FieldWithError<string>,
  price: FieldWithError<number>,
  currency: FieldWithError<string>,
  length: FieldWithError<number>,
}

// This is the page for editing (or adding) a service.
export const AddOrUpdateTeam = () => {

  // If editing, the provider id is a param. 
  const {providerIdParam} = useParams();
  const isEditing = !!providerIdParam;

  // Login info is loaded via a separate RPC. This lives outside the profile
  const [login, setLogin] = useState<IApiProviderLoginProto | null>();
  const [rpcState, setRpcState] = useState<RpcState>({});

  const {businessProfile, setBusinessProfile} = useProviderProfile();
  const {getIdTokenClaims} = useAuth0();

  const [genInviteRpcState, setGenInviteRpcState] = useState<RpcState>({});
  const [inviteCode, setInviteCode] = useState<string>();

  const [providerId, setProviderId] = useState<FieldWithError<string>>({value: ''});
  const [firstName, setFirstName] = useState<FieldWithError<string>>({value: ''});
  const [lastName, setLastName] = useState<FieldWithError<string>>({value: ''});
  const [bio, setBio] = useState<FieldWithError<string>>({value: ''});
  const [email, setEmail] = useState<FieldWithError<string>>({value: ''});
  const [phone, setPhone] = useState<FieldWithError<string>>({value: ''});
  // const [isAdmin, setIsAdmin] = useState<FieldWithError>({value: ''});
  // const [isPractitioner, setIsPractitioner] = useState<FieldWithError>({value: ''});
  const [jobTitle, setJobTitle] = useState<FieldWithError<string>>({value: ''});
  const [imageUrl, setImageUrl] = useState<FieldWithError<string[]>>({value: []});

  const navigate = useNavigate();


  useEffect(() => {

    console.log('bio ', providerIdParam)

    // Initial population of values if this is in edit mode.
    if (providerIdParam) {
      if (!businessProfile?.providers) {
        return;
      }

      for (let i = 0; i < businessProfile.providers.length; i++) {
        let bio = businessProfile.providers[i];
        if (bio.providerId == providerIdParam) {
          setProviderId({value: bio.providerId});
          setFirstName({value: bio.firstName});
          setEmail({value: bio.email});
          // setIsAdmin({value: bio.isAdmin});
          // setIsPractitioner({value: bio.isPractitioner});
          setLastName({value: bio.lastName});
          setImageUrl({value: bio.imageUrls});
          setJobTitle({value: bio.jobTitle});
          setPhone({value: bio.phone});
          setBio({value: bio.bio});
        }
      }
    }
  }, [])

  useEffect(() => {
    // You don't need to fetch login info when adding a new person
    if (!providerIdParam) {
      setRpcState({state: 'none'});
      return;
    }

    const request = GetLoginsForBusinessRequest.encode(new GetLoginsForBusinessRequest({
      providerIds: [providerIdParam]
    })).finish();

    setRpcState({state: 'pending'});
    SendRpc(getIdTokenClaims, 'get_logins', request)
        .then(r => {
          const response = GetLoginsForBusinessResponse.decode(r);
          if (!response.okay) {
            setRpcState({state: 'error', message: 'unknown error fetching login'});
            return;
          }

          if (response.provider.length > 0) {
            if (response.provider[0].providerId != providerIdParam) {
              console.log('Error: provider ids not matching!');
              setRpcState({state: 'error', message: 'wrong provider id loaded!'});
              setLogin(null);
              return;
            }

            setLogin(response.provider[0])
            setRpcState({state: 'success'});
            return;
          }

          // Success but there was no login found.
          setLogin(null);
          setRpcState({state: 'success'});

        })
        .catch(e => {
          setRpcState({state: 'error', message: e});
        })
  }, []);

  const generateInvite = () => {
    // Clear the error
    setGenInviteRpcState({});

    const request = GenerateLoginInviteRequest.encode(new GenerateLoginInviteRequest({
      providerId: providerIdParam,
    })).finish();

    SendRpc(getIdTokenClaims, 'generate_login_invite', request)
        .then(r => {
          const response = GenerateLoginInviteResponse.decode(r);
          if (!response.okay || !response.inviteCode) {
            setGenInviteRpcState({state: 'error', message: 'unknown error generating invite'});
            return;
          }

          setGenInviteRpcState({state: 'success'})
          setInviteCode(response.inviteCode);
        })
        .catch(e => {
          setGenInviteRpcState({state: 'error', message: e})
        });
  }


  const submit = () => {

    // Clear the error
    setRpcState({});

    const request = CreateOrUpdateTeamMemberRequest.encode(new CreateOrUpdateTeamMemberRequest({
      isUpdate: isEditing,
      bio: new ProviderBioProto({
        providerId: providerId.value,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        bio: bio.value,
        phone: phone.value,
        // isAdmin: isAdmin.value,
        // isPractitioner: isPractitioner.value,
        imageUrls: imageUrl.value,
        jobTitle: jobTitle.value,
      })
    })).finish();

    SendRpc(getIdTokenClaims, 'create_team_member', request)
        .then(bytes => {
          let response = CreateOrUpdateTeamMemberResponse.decode(bytes)
          if (response.errors && response.errors.length > 0) {
            response.errors.forEach(error => {
              switch (error.fieldName) {
                case 'providerId':
                  setProviderId(providerId => ({...providerId, error: error.error}));
                  break;
                case 'firstName':
                  setFirstName(name => ({...name, error: error.error}))
                  break;
                case 'lastName':
                  setLastName(name => ({...name, error: error.error}))
                  break;
                case 'bio':
                  setBio(name => ({...name, error: error.error}))
                  break;
                case 'email':
                  setEmail(email => ({...email, error: error.error}))
                  break;
                  // case 'isAdmin':
                  //   setIsAdmin(isAdmin => ({...isAdmin, error: error.error}))
                  //   break;
                  // case 'isPractitioner':
                  //   setIsPractitioner(isPractitioner => ({...isPractitioner, error: error.error}))
                  //   break;
                case 'imageUrl':
                  setImageUrl(imageUrl => ({...imageUrl, error: error.error}));
                  break;
                default:
                  setRpcState({state: 'error', message: error.error || ''})
              }
            })

            setRpcState({state: 'error', message: 'Error creating service. Please fix any invalid fields.'})

          } else if (!response.okay) {
            setRpcState({state: 'error', message: 'Unknown error creating service. Please try again later.'})
          }

          if (response.newProfile) {

            setBusinessProfile(response.newProfile);

            if (isEditing) {
              // Set the message that it was updated
              setRpcState({
                message: `Last saved at ${
                    new Date()?.toLocaleTimeString(undefined, {
                      hour: 'numeric',
                      minute: '2-digit',
                    })}`
              });

            } else {
              // Leave the page.
              navigate("/team");
            }
          }
        }).catch(reason => {
      setRpcState({state: 'error', message: 'Error creating team member, code: ' + 33})
    })
  }

  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>
          {isEditing ? `Edit team member ${firstName.value}` : `Add a new team member`}
        </h1>
        <Link to={'/team'}>
          <button className={'BusinessProfileButton StandardButton'}>
            <>Back</>
          </button>
        </Link>
        <button onClick={(e) => {
          e.preventDefault();
          submit()
        }} className={'BusinessProfileButton DarkButton'}>
          Save
        </button>
      </div>

      <div className={'SectionHeaderRow'}>
        <div style={{flex: 1, textAlign: "end"}}>
          {rpcState.state == 'error' && <div className={'InputError'}>{rpcState.message}</div>}
          {rpcState.state != 'error' && rpcState.message && <div className={''}>{rpcState.message}</div>}
        </div>
      </div>
    </div>

    <div className={'ProviderToolAreaScroll'}>
      <div className={'ProviderToolAreaContent'}>

        <br/>
        <br/>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <form style={{display: 'flex', flexDirection: 'column', gap: 10}}>

            {/*<LabeledTextInput label={'Provider ID'} disabled={isEditing}*/}
            {/*                  value={providerId} onChange={v => setProviderId({value: v})} inputType={'text'}/>*/}

            <div className={'Flex1 HorizontalFlex'}>
              <LabeledTextInput label={'First name'} value={firstName} onChange={v => setFirstName({value: v})}
                                inputType={'text'}/>
              <LabeledTextInput label={'Last name'} value={lastName} onChange={v => setLastName({value: v})}
                                inputType={'text'}/>
            </div>

            <LabeledTextInput label={'Biography'} value={bio} onChange={v => setBio({value: v})}
                              inputType={'textarea'}/>
            <LabeledTextInput label={'Email'} value={email} onChange={v => setEmail({value: v})} inputType={'text'}
                              helpText={`We'll send an invite link to this email address`}/>

            <LabeledPhoneInput label={'Phone number'} value={phone} onChange={v => setPhone({value: v})}/>
            <LabeledTextInput label={'Job title'} value={jobTitle} onChange={v => setJobTitle({value: v})}
                              inputType={'text'}/>
            {/*<LabeledYesNoInput label={'Admin'} helpText={'Does this user have admin privileges?'}*/}
            {/*                   value={isAdmin} onChange={v => setIsAdmin({value: v})}/>*/}
            {/*<LabeledYesNoInput label={'Practitioner'} helpText={'Is this person a practitioner?'}*/}
            {/*                   value={isPractitioner} onChange={v => setIsPractitioner({value: v})}/>*/}

            <PhotoUpload locationName={'Team member'} imageUrl={imageUrl.value?.length ? imageUrl.value[0] : ''}
                // hack enforcement of one url
                         onImageUrlChange={value => setImageUrl({value: [value]})}/>

          </form>
        </div>

        <br/>
        <br/>

        {/* the login parts are only shown when editing an existing team member
         this lets you see their login details and resend an invite if necessary
         */}
        {isEditing && <>
          <h2>Login information</h2>

          {rpcState.state == 'pending' && <div>Loading...</div>}
          {rpcState.state == 'error' && <div className={'ErrorText'}>{rpcState.message}</div>}
          {rpcState.state == 'success' && login &&
              <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                <div>Provider ID: {login.providerId}</div>
                <div>
                  Login ID: {login.loginId ||
                    <>User has never logged in
                      <div>
                        <button onClick={generateInvite}> click here to generate a new invite?</button>
                        {genInviteRpcState.state == 'error' &&
                            <div className={'ErrorText'}>{genInviteRpcState.message}</div>}
                        {inviteCode &&
                            <div>Here's the new invite code:
                              <a href={`/providerInvite/${providerIdParam}/${inviteCode}`}>
                                Copy this link address and send to the person!
                              </a>
                            </div>}
                      </div>
                    </>}
                </div>
                <div>Last login email: {login.lastLoginEmail || 'Unknown'}</div>
                <div>Last login time: {login.lastLoginTimestamp ?
                    new Date(login.lastLoginTimestamp as number).toLocaleString() : 'Unknown'}</div>
                <div>Roles: {login?.roles?.join(', ') || 'None'}</div>
              </div>
          }
          {rpcState.state == 'success' && !login &&
              <div style={{display: 'flex', flexDirection: 'column', gap: 2}}>
                <div>Couldn't find an associated login. This is an error</div>
              </div>
          }
        </>
        }

      </div>
    </div>
  </div>
};

