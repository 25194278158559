import React from 'react';
import './Onboarding.css';
import {CountryDropdown} from "react-country-region-selector";
import {FieldWithError} from "./CreateProviderProfile";
import PhoneInput from 'react-phone-number-input'

// The value is in in E.164 format e.g. "+12345678900".
type Props = {
  label: string;
  value: FieldWithError<string>;
  onChange: (value: string) => void;
}

export const LabeledPhoneInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'OnboardingTextInputLabel'}>{props.label}</div>
    <PhoneInput
        placeholder="Enter phone number"
        defaultCountry={'US'}
        value={props.value.value || ''}
        onChange={v => props.onChange(v as string)}/>
    {props.value.error && <div className={'OnboardingInputError'}>{props.value.error}</div>}
  </div>
  
}