import React from "react";
import {LabeledTextInput} from "../../Survey/LabeledTextInput";
import {LabeledPhoneInput} from "../../Survey/LabeledPhoneInput";
import {space} from "../../../../provider_api";
import {LocationData} from "./BusinessProfile";
import {LabeledAddressInput} from "../../Survey/LabeledAddressInput";
import {LabeledTimeZoneInput} from "../../Survey/LabeledTimeZoneInput";

type Props = {
  // The initial value of the thing.
  value: LocationData

  onNameChange: (name: string) => void;
  onAddressChange: (address: google.maps.places.PlaceResult | null) => void;
  onTimeZoneChange: (timeZone: string) => void;
  onPhoneChange: (phone: string) => void;
  onEmailChange: (email: string) => void;

}

export const BusinessLocation = (props: Props) => {

  return <div className={'InputSection'}>
    <LabeledTextInput label={'Location name'} value={props.value.name} onChange={props.onNameChange}
                      inputType={'text'}/>
    <LabeledAddressInput label={'Address'} value={props.value.address} onChange={props.onAddressChange}
                         helpText={props.value.lat ? `Lat: ${props.value.lat}, Lng: ${props.value.lng}` : ''}/>

    <LabeledTimeZoneInput label={'Time Zone'}
                          value={props.value.timeZone} onChange={props.onTimeZoneChange}/>
    <LabeledPhoneInput label={'Phone number'} value={props.value.phone} onChange={props.onPhoneChange}/>
    <LabeledTextInput label={'Email address'} value={props.value.email} onChange={props.onEmailChange}
                      inputType={'email'}/>
  </div>

}