import React, {useState} from 'react';
import {space} from "../../../../provider_api";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {Link} from "react-router-dom";
import IServiceProto = space.kenko.proto.IServiceProto;
import IPracticeDetailsProto = space.kenko.proto.IPracticeDetailsProto;
import {RpcState} from "../../../../RpcState";

export const Services = () => {

  const {businessProfile, setBusinessProfile} = useProviderProfile();

  const [newService, setNewService] = useState<IServiceProto | null>();


  const extractCategories = (practice: IPracticeDetailsProto | undefined | null) => {

    let categories: (string | null | undefined) [] = [];

    practice?.services?.forEach(service => {

      let category = service.category;
      if (categories.indexOf(category) < 0) {
        categories.push(category);
      }
    })

    return categories;
  }

  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Service Offerings</h1>
        <Link to={'add'} state={{newService: true}}>
          <button className={'BusinessProfileButton DarkButton'}>
            Add
          </button>
        </Link>
      </div>
    </div>

    <div className={'ProviderToolAreaScroll'}>
      <div className={'ProviderToolAreaContent'}>

        {extractCategories(businessProfile?.practice)?.map(category => {

          return <>
            <h1>{category || 'Uncategorized'}</h1>

            {businessProfile?.practice?.services && businessProfile.practice.services.filter((service, idx, array) => {
              return category == service.category;
            }).map(service => {
              return <Link to={`/services/edit/${service.sku}`}>
                <div className={'ServiceBox'}>
                  <div className={'ServiceName'}>{service.name}</div>
                  <div className={'ServiceLength'}>{service.lengthMinutes} min</div>
                </div>
              </Link>
            })}
          </>
        })}

        {!businessProfile?.practice?.services && <div>
          <br/><br/>
          You don't have any services defined! Click "Add" above to create your first service.
        </div>}

      </div>
    </div>
  </div>

};

