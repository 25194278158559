import React from 'react';
import './Onboarding.css';
import {CountryDropdown} from "react-country-region-selector";
import {FieldWithError} from "./CreateProviderProfile";

type Props = {
  value: FieldWithError<boolean>;
  onChange: (value: boolean) => void;
}

export const AcceptTosInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'CreateProfileCheckboxContainer'}>
      <input type={'checkbox'} className={'CreateProfileCheckbox'} 
             checked={props.value.value || false}
             onChange={e => {
               props.onChange(e.target.checked)
             }}/>
      I agree to the Privacy Policy, Terms of Service and Terms of Business.
    </div>
    {props.value.error && <div className={'OnboardingInputError'}>{props.value.error}</div>}
  </div>;
}