/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.AvailabilityBlockProto = (function() {

    /**
     * Properties of an AvailabilityBlockProto.
     * @exports IAvailabilityBlockProto
     * @interface IAvailabilityBlockProto
     * @property {number|null} [dayOfWeek] AvailabilityBlockProto dayOfWeek
     * @property {space.kenko.proto.ILocalTimeProto|null} [startTime] AvailabilityBlockProto startTime
     * @property {space.kenko.proto.ILocalTimeProto|null} [endTime] AvailabilityBlockProto endTime
     */

    /**
     * Constructs a new AvailabilityBlockProto.
     * @exports AvailabilityBlockProto
     * @classdesc Represents an AvailabilityBlockProto.
     * @implements IAvailabilityBlockProto
     * @constructor
     * @param {IAvailabilityBlockProto=} [properties] Properties to set
     */
    function AvailabilityBlockProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AvailabilityBlockProto dayOfWeek.
     * @member {number} dayOfWeek
     * @memberof AvailabilityBlockProto
     * @instance
     */
    AvailabilityBlockProto.prototype.dayOfWeek = 0;

    /**
     * AvailabilityBlockProto startTime.
     * @member {space.kenko.proto.ILocalTimeProto|null|undefined} startTime
     * @memberof AvailabilityBlockProto
     * @instance
     */
    AvailabilityBlockProto.prototype.startTime = null;

    /**
     * AvailabilityBlockProto endTime.
     * @member {space.kenko.proto.ILocalTimeProto|null|undefined} endTime
     * @memberof AvailabilityBlockProto
     * @instance
     */
    AvailabilityBlockProto.prototype.endTime = null;

    /**
     * Creates a new AvailabilityBlockProto instance using the specified properties.
     * @function create
     * @memberof AvailabilityBlockProto
     * @static
     * @param {IAvailabilityBlockProto=} [properties] Properties to set
     * @returns {AvailabilityBlockProto} AvailabilityBlockProto instance
     */
    AvailabilityBlockProto.create = function create(properties) {
        return new AvailabilityBlockProto(properties);
    };

    /**
     * Encodes the specified AvailabilityBlockProto message. Does not implicitly {@link AvailabilityBlockProto.verify|verify} messages.
     * @function encode
     * @memberof AvailabilityBlockProto
     * @static
     * @param {IAvailabilityBlockProto} message AvailabilityBlockProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AvailabilityBlockProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dayOfWeek);
        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
            $root.space.kenko.proto.LocalTimeProto.encode(message.startTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
            $root.space.kenko.proto.LocalTimeProto.encode(message.endTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AvailabilityBlockProto message, length delimited. Does not implicitly {@link AvailabilityBlockProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AvailabilityBlockProto
     * @static
     * @param {IAvailabilityBlockProto} message AvailabilityBlockProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AvailabilityBlockProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AvailabilityBlockProto message from the specified reader or buffer.
     * @function decode
     * @memberof AvailabilityBlockProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AvailabilityBlockProto} AvailabilityBlockProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AvailabilityBlockProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AvailabilityBlockProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.dayOfWeek = reader.int32();
                    break;
                }
            case 2: {
                    message.startTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.endTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AvailabilityBlockProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AvailabilityBlockProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AvailabilityBlockProto} AvailabilityBlockProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AvailabilityBlockProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AvailabilityBlockProto message.
     * @function verify
     * @memberof AvailabilityBlockProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AvailabilityBlockProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
            if (!$util.isInteger(message.dayOfWeek))
                return "dayOfWeek: integer expected";
        if (message.startTime != null && message.hasOwnProperty("startTime")) {
            var error = $root.space.kenko.proto.LocalTimeProto.verify(message.startTime);
            if (error)
                return "startTime." + error;
        }
        if (message.endTime != null && message.hasOwnProperty("endTime")) {
            var error = $root.space.kenko.proto.LocalTimeProto.verify(message.endTime);
            if (error)
                return "endTime." + error;
        }
        return null;
    };

    /**
     * Creates an AvailabilityBlockProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AvailabilityBlockProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AvailabilityBlockProto} AvailabilityBlockProto
     */
    AvailabilityBlockProto.fromObject = function fromObject(object) {
        if (object instanceof $root.AvailabilityBlockProto)
            return object;
        var message = new $root.AvailabilityBlockProto();
        if (object.dayOfWeek != null)
            message.dayOfWeek = object.dayOfWeek | 0;
        if (object.startTime != null) {
            if (typeof object.startTime !== "object")
                throw TypeError(".AvailabilityBlockProto.startTime: object expected");
            message.startTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.startTime);
        }
        if (object.endTime != null) {
            if (typeof object.endTime !== "object")
                throw TypeError(".AvailabilityBlockProto.endTime: object expected");
            message.endTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.endTime);
        }
        return message;
    };

    /**
     * Creates a plain object from an AvailabilityBlockProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AvailabilityBlockProto
     * @static
     * @param {AvailabilityBlockProto} message AvailabilityBlockProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AvailabilityBlockProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.dayOfWeek = 0;
            object.startTime = null;
            object.endTime = null;
        }
        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
            object.dayOfWeek = message.dayOfWeek;
        if (message.startTime != null && message.hasOwnProperty("startTime"))
            object.startTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.startTime, options);
        if (message.endTime != null && message.hasOwnProperty("endTime"))
            object.endTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.endTime, options);
        return object;
    };

    /**
     * Converts this AvailabilityBlockProto to JSON.
     * @function toJSON
     * @memberof AvailabilityBlockProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AvailabilityBlockProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AvailabilityBlockProto
     * @function getTypeUrl
     * @memberof AvailabilityBlockProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AvailabilityBlockProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AvailabilityBlockProto";
    };

    return AvailabilityBlockProto;
})();

$root.GetProviderProfileRequest = (function() {

    /**
     * Properties of a GetProviderProfileRequest.
     * @exports IGetProviderProfileRequest
     * @interface IGetProviderProfileRequest
     */

    /**
     * Constructs a new GetProviderProfileRequest.
     * @exports GetProviderProfileRequest
     * @classdesc Represents a GetProviderProfileRequest.
     * @implements IGetProviderProfileRequest
     * @constructor
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     */
    function GetProviderProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetProviderProfileRequest instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest instance
     */
    GetProviderProfileRequest.create = function create(properties) {
        return new GetProviderProfileRequest(properties);
    };

    /**
     * Encodes the specified GetProviderProfileRequest message. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileRequest message, length delimited. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileRequest message.
     * @function verify
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetProviderProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     */
    GetProviderProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileRequest)
            return object;
        return new $root.GetProviderProfileRequest();
    };

    /**
     * Creates a plain object from a GetProviderProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {GetProviderProfileRequest} message GetProviderProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetProviderProfileRequest to JSON.
     * @function toJSON
     * @memberof GetProviderProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileRequest
     * @function getTypeUrl
     * @memberof GetProviderProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileRequest";
    };

    return GetProviderProfileRequest;
})();

$root.GetProviderProfileResponse = (function() {

    /**
     * Properties of a GetProviderProfileResponse.
     * @exports IGetProviderProfileResponse
     * @interface IGetProviderProfileResponse
     * @property {boolean|null} [okay] GetProviderProfileResponse okay
     * @property {IApiProviderLoginProto|null} [provider] GetProviderProfileResponse provider
     * @property {IApiBusinessProfileProto|null} [businessProfile] GetProviderProfileResponse businessProfile
     */

    /**
     * Constructs a new GetProviderProfileResponse.
     * @exports GetProviderProfileResponse
     * @classdesc Represents a GetProviderProfileResponse.
     * @implements IGetProviderProfileResponse
     * @constructor
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     */
    function GetProviderProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileResponse okay.
     * @member {boolean} okay
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.okay = false;

    /**
     * GetProviderProfileResponse provider.
     * @member {IApiProviderLoginProto|null|undefined} provider
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.provider = null;

    /**
     * GetProviderProfileResponse businessProfile.
     * @member {IApiBusinessProfileProto|null|undefined} businessProfile
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.businessProfile = null;

    /**
     * Creates a new GetProviderProfileResponse instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse instance
     */
    GetProviderProfileResponse.create = function create(properties) {
        return new GetProviderProfileResponse(properties);
    };

    /**
     * Encodes the specified GetProviderProfileResponse message. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ApiProviderLoginProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.businessProfile != null && Object.hasOwnProperty.call(message, "businessProfile"))
            $root.ApiBusinessProfileProto.encode(message.businessProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileResponse message, length delimited. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.provider = $root.ApiProviderLoginProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.businessProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileResponse message.
     * @function verify
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ApiProviderLoginProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.businessProfile);
            if (error)
                return "businessProfile." + error;
        }
        return null;
    };

    /**
     * Creates a GetProviderProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     */
    GetProviderProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileResponse)
            return object;
        var message = new $root.GetProviderProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".GetProviderProfileResponse.provider: object expected");
            message.provider = $root.ApiProviderLoginProto.fromObject(object.provider);
        }
        if (object.businessProfile != null) {
            if (typeof object.businessProfile !== "object")
                throw TypeError(".GetProviderProfileResponse.businessProfile: object expected");
            message.businessProfile = $root.ApiBusinessProfileProto.fromObject(object.businessProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {GetProviderProfileResponse} message GetProviderProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
            object.businessProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ApiProviderLoginProto.toObject(message.provider, options);
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile"))
            object.businessProfile = $root.ApiBusinessProfileProto.toObject(message.businessProfile, options);
        return object;
    };

    /**
     * Converts this GetProviderProfileResponse to JSON.
     * @function toJSON
     * @memberof GetProviderProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileResponse
     * @function getTypeUrl
     * @memberof GetProviderProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileResponse";
    };

    return GetProviderProfileResponse;
})();

$root.UpdateBusinessProfileRequest = (function() {

    /**
     * Properties of an UpdateBusinessProfileRequest.
     * @exports IUpdateBusinessProfileRequest
     * @interface IUpdateBusinessProfileRequest
     * @property {IApiBusinessProfileProto|null} [businessProfile] UpdateBusinessProfileRequest businessProfile
     */

    /**
     * Constructs a new UpdateBusinessProfileRequest.
     * @exports UpdateBusinessProfileRequest
     * @classdesc Represents an UpdateBusinessProfileRequest.
     * @implements IUpdateBusinessProfileRequest
     * @constructor
     * @param {IUpdateBusinessProfileRequest=} [properties] Properties to set
     */
    function UpdateBusinessProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateBusinessProfileRequest businessProfile.
     * @member {IApiBusinessProfileProto|null|undefined} businessProfile
     * @memberof UpdateBusinessProfileRequest
     * @instance
     */
    UpdateBusinessProfileRequest.prototype.businessProfile = null;

    /**
     * Creates a new UpdateBusinessProfileRequest instance using the specified properties.
     * @function create
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {IUpdateBusinessProfileRequest=} [properties] Properties to set
     * @returns {UpdateBusinessProfileRequest} UpdateBusinessProfileRequest instance
     */
    UpdateBusinessProfileRequest.create = function create(properties) {
        return new UpdateBusinessProfileRequest(properties);
    };

    /**
     * Encodes the specified UpdateBusinessProfileRequest message. Does not implicitly {@link UpdateBusinessProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {IUpdateBusinessProfileRequest} message UpdateBusinessProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateBusinessProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessProfile != null && Object.hasOwnProperty.call(message, "businessProfile"))
            $root.ApiBusinessProfileProto.encode(message.businessProfile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateBusinessProfileRequest message, length delimited. Does not implicitly {@link UpdateBusinessProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {IUpdateBusinessProfileRequest} message UpdateBusinessProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateBusinessProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateBusinessProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateBusinessProfileRequest} UpdateBusinessProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateBusinessProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateBusinessProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateBusinessProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateBusinessProfileRequest} UpdateBusinessProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateBusinessProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateBusinessProfileRequest message.
     * @function verify
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateBusinessProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.businessProfile);
            if (error)
                return "businessProfile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateBusinessProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateBusinessProfileRequest} UpdateBusinessProfileRequest
     */
    UpdateBusinessProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateBusinessProfileRequest)
            return object;
        var message = new $root.UpdateBusinessProfileRequest();
        if (object.businessProfile != null) {
            if (typeof object.businessProfile !== "object")
                throw TypeError(".UpdateBusinessProfileRequest.businessProfile: object expected");
            message.businessProfile = $root.ApiBusinessProfileProto.fromObject(object.businessProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateBusinessProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {UpdateBusinessProfileRequest} message UpdateBusinessProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateBusinessProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.businessProfile = null;
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile"))
            object.businessProfile = $root.ApiBusinessProfileProto.toObject(message.businessProfile, options);
        return object;
    };

    /**
     * Converts this UpdateBusinessProfileRequest to JSON.
     * @function toJSON
     * @memberof UpdateBusinessProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateBusinessProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateBusinessProfileRequest
     * @function getTypeUrl
     * @memberof UpdateBusinessProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateBusinessProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateBusinessProfileRequest";
    };

    return UpdateBusinessProfileRequest;
})();

$root.ApiProviderLoginProto = (function() {

    /**
     * Properties of an ApiProviderLoginProto.
     * @exports IApiProviderLoginProto
     * @interface IApiProviderLoginProto
     * @property {string|null} [providerId] ApiProviderLoginProto providerId
     * @property {string|null} [businessId] ApiProviderLoginProto businessId
     * @property {string|null} [loginId] ApiProviderLoginProto loginId
     * @property {Array.<string>|null} [roles] ApiProviderLoginProto roles
     * @property {string|null} [lastLoginEmail] ApiProviderLoginProto lastLoginEmail
     * @property {number|Long|null} [lastLoginTimestamp] ApiProviderLoginProto lastLoginTimestamp
     */

    /**
     * Constructs a new ApiProviderLoginProto.
     * @exports ApiProviderLoginProto
     * @classdesc Represents an ApiProviderLoginProto.
     * @implements IApiProviderLoginProto
     * @constructor
     * @param {IApiProviderLoginProto=} [properties] Properties to set
     */
    function ApiProviderLoginProto(properties) {
        this.roles = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ApiProviderLoginProto providerId.
     * @member {string} providerId
     * @memberof ApiProviderLoginProto
     * @instance
     */
    ApiProviderLoginProto.prototype.providerId = "";

    /**
     * ApiProviderLoginProto businessId.
     * @member {string} businessId
     * @memberof ApiProviderLoginProto
     * @instance
     */
    ApiProviderLoginProto.prototype.businessId = "";

    /**
     * ApiProviderLoginProto loginId.
     * @member {string} loginId
     * @memberof ApiProviderLoginProto
     * @instance
     */
    ApiProviderLoginProto.prototype.loginId = "";

    /**
     * ApiProviderLoginProto roles.
     * @member {Array.<string>} roles
     * @memberof ApiProviderLoginProto
     * @instance
     */
    ApiProviderLoginProto.prototype.roles = $util.emptyArray;

    /**
     * ApiProviderLoginProto lastLoginEmail.
     * @member {string} lastLoginEmail
     * @memberof ApiProviderLoginProto
     * @instance
     */
    ApiProviderLoginProto.prototype.lastLoginEmail = "";

    /**
     * ApiProviderLoginProto lastLoginTimestamp.
     * @member {number|Long} lastLoginTimestamp
     * @memberof ApiProviderLoginProto
     * @instance
     */
    ApiProviderLoginProto.prototype.lastLoginTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new ApiProviderLoginProto instance using the specified properties.
     * @function create
     * @memberof ApiProviderLoginProto
     * @static
     * @param {IApiProviderLoginProto=} [properties] Properties to set
     * @returns {ApiProviderLoginProto} ApiProviderLoginProto instance
     */
    ApiProviderLoginProto.create = function create(properties) {
        return new ApiProviderLoginProto(properties);
    };

    /**
     * Encodes the specified ApiProviderLoginProto message. Does not implicitly {@link ApiProviderLoginProto.verify|verify} messages.
     * @function encode
     * @memberof ApiProviderLoginProto
     * @static
     * @param {IApiProviderLoginProto} message ApiProviderLoginProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiProviderLoginProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
        if (message.loginId != null && Object.hasOwnProperty.call(message, "loginId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.loginId);
        if (message.roles != null && message.roles.length)
            for (var i = 0; i < message.roles.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.roles[i]);
        if (message.lastLoginEmail != null && Object.hasOwnProperty.call(message, "lastLoginEmail"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastLoginEmail);
        if (message.lastLoginTimestamp != null && Object.hasOwnProperty.call(message, "lastLoginTimestamp"))
            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.lastLoginTimestamp);
        return writer;
    };

    /**
     * Encodes the specified ApiProviderLoginProto message, length delimited. Does not implicitly {@link ApiProviderLoginProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ApiProviderLoginProto
     * @static
     * @param {IApiProviderLoginProto} message ApiProviderLoginProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiProviderLoginProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ApiProviderLoginProto message from the specified reader or buffer.
     * @function decode
     * @memberof ApiProviderLoginProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ApiProviderLoginProto} ApiProviderLoginProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiProviderLoginProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ApiProviderLoginProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.businessId = reader.string();
                    break;
                }
            case 3: {
                    message.loginId = reader.string();
                    break;
                }
            case 4: {
                    if (!(message.roles && message.roles.length))
                        message.roles = [];
                    message.roles.push(reader.string());
                    break;
                }
            case 5: {
                    message.lastLoginEmail = reader.string();
                    break;
                }
            case 6: {
                    message.lastLoginTimestamp = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ApiProviderLoginProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ApiProviderLoginProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ApiProviderLoginProto} ApiProviderLoginProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiProviderLoginProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ApiProviderLoginProto message.
     * @function verify
     * @memberof ApiProviderLoginProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ApiProviderLoginProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            if (!$util.isString(message.loginId))
                return "loginId: string expected";
        if (message.roles != null && message.hasOwnProperty("roles")) {
            if (!Array.isArray(message.roles))
                return "roles: array expected";
            for (var i = 0; i < message.roles.length; ++i)
                if (!$util.isString(message.roles[i]))
                    return "roles: string[] expected";
        }
        if (message.lastLoginEmail != null && message.hasOwnProperty("lastLoginEmail"))
            if (!$util.isString(message.lastLoginEmail))
                return "lastLoginEmail: string expected";
        if (message.lastLoginTimestamp != null && message.hasOwnProperty("lastLoginTimestamp"))
            if (!$util.isInteger(message.lastLoginTimestamp) && !(message.lastLoginTimestamp && $util.isInteger(message.lastLoginTimestamp.low) && $util.isInteger(message.lastLoginTimestamp.high)))
                return "lastLoginTimestamp: integer|Long expected";
        return null;
    };

    /**
     * Creates an ApiProviderLoginProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ApiProviderLoginProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ApiProviderLoginProto} ApiProviderLoginProto
     */
    ApiProviderLoginProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ApiProviderLoginProto)
            return object;
        var message = new $root.ApiProviderLoginProto();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.loginId != null)
            message.loginId = String(object.loginId);
        if (object.roles) {
            if (!Array.isArray(object.roles))
                throw TypeError(".ApiProviderLoginProto.roles: array expected");
            message.roles = [];
            for (var i = 0; i < object.roles.length; ++i)
                message.roles[i] = String(object.roles[i]);
        }
        if (object.lastLoginEmail != null)
            message.lastLoginEmail = String(object.lastLoginEmail);
        if (object.lastLoginTimestamp != null)
            if ($util.Long)
                (message.lastLoginTimestamp = $util.Long.fromValue(object.lastLoginTimestamp)).unsigned = false;
            else if (typeof object.lastLoginTimestamp === "string")
                message.lastLoginTimestamp = parseInt(object.lastLoginTimestamp, 10);
            else if (typeof object.lastLoginTimestamp === "number")
                message.lastLoginTimestamp = object.lastLoginTimestamp;
            else if (typeof object.lastLoginTimestamp === "object")
                message.lastLoginTimestamp = new $util.LongBits(object.lastLoginTimestamp.low >>> 0, object.lastLoginTimestamp.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from an ApiProviderLoginProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ApiProviderLoginProto
     * @static
     * @param {ApiProviderLoginProto} message ApiProviderLoginProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ApiProviderLoginProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.roles = [];
        if (options.defaults) {
            object.providerId = "";
            object.businessId = "";
            object.loginId = "";
            object.lastLoginEmail = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.lastLoginTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastLoginTimestamp = options.longs === String ? "0" : 0;
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            object.loginId = message.loginId;
        if (message.roles && message.roles.length) {
            object.roles = [];
            for (var j = 0; j < message.roles.length; ++j)
                object.roles[j] = message.roles[j];
        }
        if (message.lastLoginEmail != null && message.hasOwnProperty("lastLoginEmail"))
            object.lastLoginEmail = message.lastLoginEmail;
        if (message.lastLoginTimestamp != null && message.hasOwnProperty("lastLoginTimestamp"))
            if (typeof message.lastLoginTimestamp === "number")
                object.lastLoginTimestamp = options.longs === String ? String(message.lastLoginTimestamp) : message.lastLoginTimestamp;
            else
                object.lastLoginTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.lastLoginTimestamp) : options.longs === Number ? new $util.LongBits(message.lastLoginTimestamp.low >>> 0, message.lastLoginTimestamp.high >>> 0).toNumber() : message.lastLoginTimestamp;
        return object;
    };

    /**
     * Converts this ApiProviderLoginProto to JSON.
     * @function toJSON
     * @memberof ApiProviderLoginProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ApiProviderLoginProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ApiProviderLoginProto
     * @function getTypeUrl
     * @memberof ApiProviderLoginProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ApiProviderLoginProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ApiProviderLoginProto";
    };

    return ApiProviderLoginProto;
})();

$root.ApiBusinessProfileProto = (function() {

    /**
     * Properties of an ApiBusinessProfileProto.
     * @exports IApiBusinessProfileProto
     * @interface IApiBusinessProfileProto
     * @property {string|null} [versionId] ApiBusinessProfileProto versionId
     * @property {number|Long|null} [lastModifiedTimestamp] ApiBusinessProfileProto lastModifiedTimestamp
     * @property {number|Long|null} [reviewTimestamp] ApiBusinessProfileProto reviewTimestamp
     * @property {space.kenko.proto.IProfileReviewProto|null} [review] ApiBusinessProfileProto review
     * @property {space.kenko.proto.IBusinessProto|null} [proto] ApiBusinessProfileProto proto
     * @property {Array.<space.kenko.proto.IBusinessLocationProto>|null} [locations] ApiBusinessProfileProto locations
     * @property {Array.<space.kenko.proto.IProviderBioProto>|null} [providers] ApiBusinessProfileProto providers
     * @property {space.kenko.proto.IPracticeDetailsProto|null} [practice] ApiBusinessProfileProto practice
     */

    /**
     * Constructs a new ApiBusinessProfileProto.
     * @exports ApiBusinessProfileProto
     * @classdesc Represents an ApiBusinessProfileProto.
     * @implements IApiBusinessProfileProto
     * @constructor
     * @param {IApiBusinessProfileProto=} [properties] Properties to set
     */
    function ApiBusinessProfileProto(properties) {
        this.locations = [];
        this.providers = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ApiBusinessProfileProto versionId.
     * @member {string} versionId
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.versionId = "";

    /**
     * ApiBusinessProfileProto lastModifiedTimestamp.
     * @member {number|Long} lastModifiedTimestamp
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.lastModifiedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ApiBusinessProfileProto reviewTimestamp.
     * @member {number|Long} reviewTimestamp
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.reviewTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ApiBusinessProfileProto review.
     * @member {space.kenko.proto.IProfileReviewProto|null|undefined} review
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.review = null;

    /**
     * ApiBusinessProfileProto proto.
     * @member {space.kenko.proto.IBusinessProto|null|undefined} proto
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.proto = null;

    /**
     * ApiBusinessProfileProto locations.
     * @member {Array.<space.kenko.proto.IBusinessLocationProto>} locations
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.locations = $util.emptyArray;

    /**
     * ApiBusinessProfileProto providers.
     * @member {Array.<space.kenko.proto.IProviderBioProto>} providers
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.providers = $util.emptyArray;

    /**
     * ApiBusinessProfileProto practice.
     * @member {space.kenko.proto.IPracticeDetailsProto|null|undefined} practice
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.practice = null;

    /**
     * Creates a new ApiBusinessProfileProto instance using the specified properties.
     * @function create
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {IApiBusinessProfileProto=} [properties] Properties to set
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto instance
     */
    ApiBusinessProfileProto.create = function create(properties) {
        return new ApiBusinessProfileProto(properties);
    };

    /**
     * Encodes the specified ApiBusinessProfileProto message. Does not implicitly {@link ApiBusinessProfileProto.verify|verify} messages.
     * @function encode
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {IApiBusinessProfileProto} message ApiBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiBusinessProfileProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.versionId);
        if (message.lastModifiedTimestamp != null && Object.hasOwnProperty.call(message, "lastModifiedTimestamp"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.lastModifiedTimestamp);
        if (message.reviewTimestamp != null && Object.hasOwnProperty.call(message, "reviewTimestamp"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.reviewTimestamp);
        if (message.review != null && Object.hasOwnProperty.call(message, "review"))
            $root.space.kenko.proto.ProfileReviewProto.encode(message.review, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.proto != null && Object.hasOwnProperty.call(message, "proto"))
            $root.space.kenko.proto.BusinessProto.encode(message.proto, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.locations != null && message.locations.length)
            for (var i = 0; i < message.locations.length; ++i)
                $root.space.kenko.proto.BusinessLocationProto.encode(message.locations[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.providers != null && message.providers.length)
            for (var i = 0; i < message.providers.length; ++i)
                $root.space.kenko.proto.ProviderBioProto.encode(message.providers[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.practice != null && Object.hasOwnProperty.call(message, "practice"))
            $root.space.kenko.proto.PracticeDetailsProto.encode(message.practice, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ApiBusinessProfileProto message, length delimited. Does not implicitly {@link ApiBusinessProfileProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {IApiBusinessProfileProto} message ApiBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiBusinessProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ApiBusinessProfileProto message from the specified reader or buffer.
     * @function decode
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiBusinessProfileProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ApiBusinessProfileProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.versionId = reader.string();
                    break;
                }
            case 2: {
                    message.lastModifiedTimestamp = reader.int64();
                    break;
                }
            case 3: {
                    message.reviewTimestamp = reader.int64();
                    break;
                }
            case 4: {
                    message.review = $root.space.kenko.proto.ProfileReviewProto.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.proto = $root.space.kenko.proto.BusinessProto.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    if (!(message.locations && message.locations.length))
                        message.locations = [];
                    message.locations.push($root.space.kenko.proto.BusinessLocationProto.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    if (!(message.providers && message.providers.length))
                        message.providers = [];
                    message.providers.push($root.space.kenko.proto.ProviderBioProto.decode(reader, reader.uint32()));
                    break;
                }
            case 100: {
                    message.practice = $root.space.kenko.proto.PracticeDetailsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ApiBusinessProfileProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiBusinessProfileProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ApiBusinessProfileProto message.
     * @function verify
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ApiBusinessProfileProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            if (!$util.isString(message.versionId))
                return "versionId: string expected";
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (!$util.isInteger(message.lastModifiedTimestamp) && !(message.lastModifiedTimestamp && $util.isInteger(message.lastModifiedTimestamp.low) && $util.isInteger(message.lastModifiedTimestamp.high)))
                return "lastModifiedTimestamp: integer|Long expected";
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (!$util.isInteger(message.reviewTimestamp) && !(message.reviewTimestamp && $util.isInteger(message.reviewTimestamp.low) && $util.isInteger(message.reviewTimestamp.high)))
                return "reviewTimestamp: integer|Long expected";
        if (message.review != null && message.hasOwnProperty("review")) {
            var error = $root.space.kenko.proto.ProfileReviewProto.verify(message.review);
            if (error)
                return "review." + error;
        }
        if (message.proto != null && message.hasOwnProperty("proto")) {
            var error = $root.space.kenko.proto.BusinessProto.verify(message.proto);
            if (error)
                return "proto." + error;
        }
        if (message.locations != null && message.hasOwnProperty("locations")) {
            if (!Array.isArray(message.locations))
                return "locations: array expected";
            for (var i = 0; i < message.locations.length; ++i) {
                var error = $root.space.kenko.proto.BusinessLocationProto.verify(message.locations[i]);
                if (error)
                    return "locations." + error;
            }
        }
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (var i = 0; i < message.providers.length; ++i) {
                var error = $root.space.kenko.proto.ProviderBioProto.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        if (message.practice != null && message.hasOwnProperty("practice")) {
            var error = $root.space.kenko.proto.PracticeDetailsProto.verify(message.practice);
            if (error)
                return "practice." + error;
        }
        return null;
    };

    /**
     * Creates an ApiBusinessProfileProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto
     */
    ApiBusinessProfileProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ApiBusinessProfileProto)
            return object;
        var message = new $root.ApiBusinessProfileProto();
        if (object.versionId != null)
            message.versionId = String(object.versionId);
        if (object.lastModifiedTimestamp != null)
            if ($util.Long)
                (message.lastModifiedTimestamp = $util.Long.fromValue(object.lastModifiedTimestamp)).unsigned = false;
            else if (typeof object.lastModifiedTimestamp === "string")
                message.lastModifiedTimestamp = parseInt(object.lastModifiedTimestamp, 10);
            else if (typeof object.lastModifiedTimestamp === "number")
                message.lastModifiedTimestamp = object.lastModifiedTimestamp;
            else if (typeof object.lastModifiedTimestamp === "object")
                message.lastModifiedTimestamp = new $util.LongBits(object.lastModifiedTimestamp.low >>> 0, object.lastModifiedTimestamp.high >>> 0).toNumber();
        if (object.reviewTimestamp != null)
            if ($util.Long)
                (message.reviewTimestamp = $util.Long.fromValue(object.reviewTimestamp)).unsigned = false;
            else if (typeof object.reviewTimestamp === "string")
                message.reviewTimestamp = parseInt(object.reviewTimestamp, 10);
            else if (typeof object.reviewTimestamp === "number")
                message.reviewTimestamp = object.reviewTimestamp;
            else if (typeof object.reviewTimestamp === "object")
                message.reviewTimestamp = new $util.LongBits(object.reviewTimestamp.low >>> 0, object.reviewTimestamp.high >>> 0).toNumber();
        if (object.review != null) {
            if (typeof object.review !== "object")
                throw TypeError(".ApiBusinessProfileProto.review: object expected");
            message.review = $root.space.kenko.proto.ProfileReviewProto.fromObject(object.review);
        }
        if (object.proto != null) {
            if (typeof object.proto !== "object")
                throw TypeError(".ApiBusinessProfileProto.proto: object expected");
            message.proto = $root.space.kenko.proto.BusinessProto.fromObject(object.proto);
        }
        if (object.locations) {
            if (!Array.isArray(object.locations))
                throw TypeError(".ApiBusinessProfileProto.locations: array expected");
            message.locations = [];
            for (var i = 0; i < object.locations.length; ++i) {
                if (typeof object.locations[i] !== "object")
                    throw TypeError(".ApiBusinessProfileProto.locations: object expected");
                message.locations[i] = $root.space.kenko.proto.BusinessLocationProto.fromObject(object.locations[i]);
            }
        }
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".ApiBusinessProfileProto.providers: array expected");
            message.providers = [];
            for (var i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".ApiBusinessProfileProto.providers: object expected");
                message.providers[i] = $root.space.kenko.proto.ProviderBioProto.fromObject(object.providers[i]);
            }
        }
        if (object.practice != null) {
            if (typeof object.practice !== "object")
                throw TypeError(".ApiBusinessProfileProto.practice: object expected");
            message.practice = $root.space.kenko.proto.PracticeDetailsProto.fromObject(object.practice);
        }
        return message;
    };

    /**
     * Creates a plain object from an ApiBusinessProfileProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {ApiBusinessProfileProto} message ApiBusinessProfileProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ApiBusinessProfileProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.locations = [];
            object.providers = [];
        }
        if (options.defaults) {
            object.versionId = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.lastModifiedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastModifiedTimestamp = options.longs === String ? "0" : 0;
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.reviewTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.reviewTimestamp = options.longs === String ? "0" : 0;
            object.review = null;
            object.proto = null;
            object.practice = null;
        }
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            object.versionId = message.versionId;
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (typeof message.lastModifiedTimestamp === "number")
                object.lastModifiedTimestamp = options.longs === String ? String(message.lastModifiedTimestamp) : message.lastModifiedTimestamp;
            else
                object.lastModifiedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.lastModifiedTimestamp) : options.longs === Number ? new $util.LongBits(message.lastModifiedTimestamp.low >>> 0, message.lastModifiedTimestamp.high >>> 0).toNumber() : message.lastModifiedTimestamp;
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (typeof message.reviewTimestamp === "number")
                object.reviewTimestamp = options.longs === String ? String(message.reviewTimestamp) : message.reviewTimestamp;
            else
                object.reviewTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.reviewTimestamp) : options.longs === Number ? new $util.LongBits(message.reviewTimestamp.low >>> 0, message.reviewTimestamp.high >>> 0).toNumber() : message.reviewTimestamp;
        if (message.review != null && message.hasOwnProperty("review"))
            object.review = $root.space.kenko.proto.ProfileReviewProto.toObject(message.review, options);
        if (message.proto != null && message.hasOwnProperty("proto"))
            object.proto = $root.space.kenko.proto.BusinessProto.toObject(message.proto, options);
        if (message.locations && message.locations.length) {
            object.locations = [];
            for (var j = 0; j < message.locations.length; ++j)
                object.locations[j] = $root.space.kenko.proto.BusinessLocationProto.toObject(message.locations[j], options);
        }
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (var j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.space.kenko.proto.ProviderBioProto.toObject(message.providers[j], options);
        }
        if (message.practice != null && message.hasOwnProperty("practice"))
            object.practice = $root.space.kenko.proto.PracticeDetailsProto.toObject(message.practice, options);
        return object;
    };

    /**
     * Converts this ApiBusinessProfileProto to JSON.
     * @function toJSON
     * @memberof ApiBusinessProfileProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ApiBusinessProfileProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ApiBusinessProfileProto
     * @function getTypeUrl
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ApiBusinessProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ApiBusinessProfileProto";
    };

    return ApiBusinessProfileProto;
})();

$root.UpdateBusinessProfileResponse = (function() {

    /**
     * Properties of an UpdateBusinessProfileResponse.
     * @exports IUpdateBusinessProfileResponse
     * @interface IUpdateBusinessProfileResponse
     * @property {boolean|null} [okay] UpdateBusinessProfileResponse okay
     * @property {IApiBusinessProfileProto|null} [profile] UpdateBusinessProfileResponse profile
     * @property {Array.<IErrorsProto>|null} [profileErrors] UpdateBusinessProfileResponse profileErrors
     * @property {Array.<IErrorsProto>|null} [locationErrors] UpdateBusinessProfileResponse locationErrors
     */

    /**
     * Constructs a new UpdateBusinessProfileResponse.
     * @exports UpdateBusinessProfileResponse
     * @classdesc Represents an UpdateBusinessProfileResponse.
     * @implements IUpdateBusinessProfileResponse
     * @constructor
     * @param {IUpdateBusinessProfileResponse=} [properties] Properties to set
     */
    function UpdateBusinessProfileResponse(properties) {
        this.profileErrors = [];
        this.locationErrors = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateBusinessProfileResponse okay.
     * @member {boolean} okay
     * @memberof UpdateBusinessProfileResponse
     * @instance
     */
    UpdateBusinessProfileResponse.prototype.okay = false;

    /**
     * UpdateBusinessProfileResponse profile.
     * @member {IApiBusinessProfileProto|null|undefined} profile
     * @memberof UpdateBusinessProfileResponse
     * @instance
     */
    UpdateBusinessProfileResponse.prototype.profile = null;

    /**
     * UpdateBusinessProfileResponse profileErrors.
     * @member {Array.<IErrorsProto>} profileErrors
     * @memberof UpdateBusinessProfileResponse
     * @instance
     */
    UpdateBusinessProfileResponse.prototype.profileErrors = $util.emptyArray;

    /**
     * UpdateBusinessProfileResponse locationErrors.
     * @member {Array.<IErrorsProto>} locationErrors
     * @memberof UpdateBusinessProfileResponse
     * @instance
     */
    UpdateBusinessProfileResponse.prototype.locationErrors = $util.emptyArray;

    /**
     * Creates a new UpdateBusinessProfileResponse instance using the specified properties.
     * @function create
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {IUpdateBusinessProfileResponse=} [properties] Properties to set
     * @returns {UpdateBusinessProfileResponse} UpdateBusinessProfileResponse instance
     */
    UpdateBusinessProfileResponse.create = function create(properties) {
        return new UpdateBusinessProfileResponse(properties);
    };

    /**
     * Encodes the specified UpdateBusinessProfileResponse message. Does not implicitly {@link UpdateBusinessProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {IUpdateBusinessProfileResponse} message UpdateBusinessProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateBusinessProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.ApiBusinessProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.profileErrors != null && message.profileErrors.length)
            for (var i = 0; i < message.profileErrors.length; ++i)
                $root.ErrorsProto.encode(message.profileErrors[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.locationErrors != null && message.locationErrors.length)
            for (var i = 0; i < message.locationErrors.length; ++i)
                $root.ErrorsProto.encode(message.locationErrors[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateBusinessProfileResponse message, length delimited. Does not implicitly {@link UpdateBusinessProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {IUpdateBusinessProfileResponse} message UpdateBusinessProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateBusinessProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateBusinessProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateBusinessProfileResponse} UpdateBusinessProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateBusinessProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateBusinessProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.profile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    if (!(message.profileErrors && message.profileErrors.length))
                        message.profileErrors = [];
                    message.profileErrors.push($root.ErrorsProto.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    if (!(message.locationErrors && message.locationErrors.length))
                        message.locationErrors = [];
                    message.locationErrors.push($root.ErrorsProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateBusinessProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateBusinessProfileResponse} UpdateBusinessProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateBusinessProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateBusinessProfileResponse message.
     * @function verify
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateBusinessProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        if (message.profileErrors != null && message.hasOwnProperty("profileErrors")) {
            if (!Array.isArray(message.profileErrors))
                return "profileErrors: array expected";
            for (var i = 0; i < message.profileErrors.length; ++i) {
                var error = $root.ErrorsProto.verify(message.profileErrors[i]);
                if (error)
                    return "profileErrors." + error;
            }
        }
        if (message.locationErrors != null && message.hasOwnProperty("locationErrors")) {
            if (!Array.isArray(message.locationErrors))
                return "locationErrors: array expected";
            for (var i = 0; i < message.locationErrors.length; ++i) {
                var error = $root.ErrorsProto.verify(message.locationErrors[i]);
                if (error)
                    return "locationErrors." + error;
            }
        }
        return null;
    };

    /**
     * Creates an UpdateBusinessProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateBusinessProfileResponse} UpdateBusinessProfileResponse
     */
    UpdateBusinessProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateBusinessProfileResponse)
            return object;
        var message = new $root.UpdateBusinessProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".UpdateBusinessProfileResponse.profile: object expected");
            message.profile = $root.ApiBusinessProfileProto.fromObject(object.profile);
        }
        if (object.profileErrors) {
            if (!Array.isArray(object.profileErrors))
                throw TypeError(".UpdateBusinessProfileResponse.profileErrors: array expected");
            message.profileErrors = [];
            for (var i = 0; i < object.profileErrors.length; ++i) {
                if (typeof object.profileErrors[i] !== "object")
                    throw TypeError(".UpdateBusinessProfileResponse.profileErrors: object expected");
                message.profileErrors[i] = $root.ErrorsProto.fromObject(object.profileErrors[i]);
            }
        }
        if (object.locationErrors) {
            if (!Array.isArray(object.locationErrors))
                throw TypeError(".UpdateBusinessProfileResponse.locationErrors: array expected");
            message.locationErrors = [];
            for (var i = 0; i < object.locationErrors.length; ++i) {
                if (typeof object.locationErrors[i] !== "object")
                    throw TypeError(".UpdateBusinessProfileResponse.locationErrors: object expected");
                message.locationErrors[i] = $root.ErrorsProto.fromObject(object.locationErrors[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateBusinessProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {UpdateBusinessProfileResponse} message UpdateBusinessProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateBusinessProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.profileErrors = [];
            object.locationErrors = [];
        }
        if (options.defaults) {
            object.okay = false;
            object.profile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.ApiBusinessProfileProto.toObject(message.profile, options);
        if (message.profileErrors && message.profileErrors.length) {
            object.profileErrors = [];
            for (var j = 0; j < message.profileErrors.length; ++j)
                object.profileErrors[j] = $root.ErrorsProto.toObject(message.profileErrors[j], options);
        }
        if (message.locationErrors && message.locationErrors.length) {
            object.locationErrors = [];
            for (var j = 0; j < message.locationErrors.length; ++j)
                object.locationErrors[j] = $root.ErrorsProto.toObject(message.locationErrors[j], options);
        }
        return object;
    };

    /**
     * Converts this UpdateBusinessProfileResponse to JSON.
     * @function toJSON
     * @memberof UpdateBusinessProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateBusinessProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateBusinessProfileResponse
     * @function getTypeUrl
     * @memberof UpdateBusinessProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateBusinessProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateBusinessProfileResponse";
    };

    return UpdateBusinessProfileResponse;
})();

$root.UpdateProviderProfileRequest = (function() {

    /**
     * Properties of an UpdateProviderProfileRequest.
     * @exports IUpdateProviderProfileRequest
     * @interface IUpdateProviderProfileRequest
     * @property {space.kenko.proto.IProviderBioProto|null} [provider] UpdateProviderProfileRequest provider
     */

    /**
     * Constructs a new UpdateProviderProfileRequest.
     * @exports UpdateProviderProfileRequest
     * @classdesc Represents an UpdateProviderProfileRequest.
     * @implements IUpdateProviderProfileRequest
     * @constructor
     * @param {IUpdateProviderProfileRequest=} [properties] Properties to set
     */
    function UpdateProviderProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateProviderProfileRequest provider.
     * @member {space.kenko.proto.IProviderBioProto|null|undefined} provider
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.provider = null;

    /**
     * Creates a new UpdateProviderProfileRequest instance using the specified properties.
     * @function create
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {IUpdateProviderProfileRequest=} [properties] Properties to set
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest instance
     */
    UpdateProviderProfileRequest.create = function create(properties) {
        return new UpdateProviderProfileRequest(properties);
    };

    /**
     * Encodes the specified UpdateProviderProfileRequest message. Does not implicitly {@link UpdateProviderProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {IUpdateProviderProfileRequest} message UpdateProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.space.kenko.proto.ProviderBioProto.encode(message.provider, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateProviderProfileRequest message, length delimited. Does not implicitly {@link UpdateProviderProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {IUpdateProviderProfileRequest} message UpdateProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateProviderProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateProviderProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.provider = $root.space.kenko.proto.ProviderBioProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateProviderProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateProviderProfileRequest message.
     * @function verify
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateProviderProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.space.kenko.proto.ProviderBioProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateProviderProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest
     */
    UpdateProviderProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateProviderProfileRequest)
            return object;
        var message = new $root.UpdateProviderProfileRequest();
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".UpdateProviderProfileRequest.provider: object expected");
            message.provider = $root.space.kenko.proto.ProviderBioProto.fromObject(object.provider);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateProviderProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {UpdateProviderProfileRequest} message UpdateProviderProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateProviderProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.provider = null;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.space.kenko.proto.ProviderBioProto.toObject(message.provider, options);
        return object;
    };

    /**
     * Converts this UpdateProviderProfileRequest to JSON.
     * @function toJSON
     * @memberof UpdateProviderProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateProviderProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateProviderProfileRequest
     * @function getTypeUrl
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateProviderProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateProviderProfileRequest";
    };

    return UpdateProviderProfileRequest;
})();

$root.UpdateProviderProfileResponse = (function() {

    /**
     * Properties of an UpdateProviderProfileResponse.
     * @exports IUpdateProviderProfileResponse
     * @interface IUpdateProviderProfileResponse
     * @property {boolean|null} [okay] UpdateProviderProfileResponse okay
     * @property {IApiProviderLoginProto|null} [provider] UpdateProviderProfileResponse provider
     * @property {IApiBusinessProfileProto|null} [businessProfile] UpdateProviderProfileResponse businessProfile
     */

    /**
     * Constructs a new UpdateProviderProfileResponse.
     * @exports UpdateProviderProfileResponse
     * @classdesc Represents an UpdateProviderProfileResponse.
     * @implements IUpdateProviderProfileResponse
     * @constructor
     * @param {IUpdateProviderProfileResponse=} [properties] Properties to set
     */
    function UpdateProviderProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateProviderProfileResponse okay.
     * @member {boolean} okay
     * @memberof UpdateProviderProfileResponse
     * @instance
     */
    UpdateProviderProfileResponse.prototype.okay = false;

    /**
     * UpdateProviderProfileResponse provider.
     * @member {IApiProviderLoginProto|null|undefined} provider
     * @memberof UpdateProviderProfileResponse
     * @instance
     */
    UpdateProviderProfileResponse.prototype.provider = null;

    /**
     * UpdateProviderProfileResponse businessProfile.
     * @member {IApiBusinessProfileProto|null|undefined} businessProfile
     * @memberof UpdateProviderProfileResponse
     * @instance
     */
    UpdateProviderProfileResponse.prototype.businessProfile = null;

    /**
     * Creates a new UpdateProviderProfileResponse instance using the specified properties.
     * @function create
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {IUpdateProviderProfileResponse=} [properties] Properties to set
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse instance
     */
    UpdateProviderProfileResponse.create = function create(properties) {
        return new UpdateProviderProfileResponse(properties);
    };

    /**
     * Encodes the specified UpdateProviderProfileResponse message. Does not implicitly {@link UpdateProviderProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {IUpdateProviderProfileResponse} message UpdateProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ApiProviderLoginProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.businessProfile != null && Object.hasOwnProperty.call(message, "businessProfile"))
            $root.ApiBusinessProfileProto.encode(message.businessProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateProviderProfileResponse message, length delimited. Does not implicitly {@link UpdateProviderProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {IUpdateProviderProfileResponse} message UpdateProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateProviderProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateProviderProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.provider = $root.ApiProviderLoginProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.businessProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateProviderProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateProviderProfileResponse message.
     * @function verify
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateProviderProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ApiProviderLoginProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.businessProfile);
            if (error)
                return "businessProfile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateProviderProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse
     */
    UpdateProviderProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateProviderProfileResponse)
            return object;
        var message = new $root.UpdateProviderProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".UpdateProviderProfileResponse.provider: object expected");
            message.provider = $root.ApiProviderLoginProto.fromObject(object.provider);
        }
        if (object.businessProfile != null) {
            if (typeof object.businessProfile !== "object")
                throw TypeError(".UpdateProviderProfileResponse.businessProfile: object expected");
            message.businessProfile = $root.ApiBusinessProfileProto.fromObject(object.businessProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateProviderProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {UpdateProviderProfileResponse} message UpdateProviderProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateProviderProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
            object.businessProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ApiProviderLoginProto.toObject(message.provider, options);
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile"))
            object.businessProfile = $root.ApiBusinessProfileProto.toObject(message.businessProfile, options);
        return object;
    };

    /**
     * Converts this UpdateProviderProfileResponse to JSON.
     * @function toJSON
     * @memberof UpdateProviderProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateProviderProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateProviderProfileResponse
     * @function getTypeUrl
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateProviderProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateProviderProfileResponse";
    };

    return UpdateProviderProfileResponse;
})();

$root.DebugDeleteProviderRequest = (function() {

    /**
     * Properties of a DebugDeleteProviderRequest.
     * @exports IDebugDeleteProviderRequest
     * @interface IDebugDeleteProviderRequest
     * @property {boolean|null} [resetOnly] DebugDeleteProviderRequest resetOnly
     */

    /**
     * Constructs a new DebugDeleteProviderRequest.
     * @exports DebugDeleteProviderRequest
     * @classdesc Represents a DebugDeleteProviderRequest.
     * @implements IDebugDeleteProviderRequest
     * @constructor
     * @param {IDebugDeleteProviderRequest=} [properties] Properties to set
     */
    function DebugDeleteProviderRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DebugDeleteProviderRequest resetOnly.
     * @member {boolean} resetOnly
     * @memberof DebugDeleteProviderRequest
     * @instance
     */
    DebugDeleteProviderRequest.prototype.resetOnly = false;

    /**
     * Creates a new DebugDeleteProviderRequest instance using the specified properties.
     * @function create
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {IDebugDeleteProviderRequest=} [properties] Properties to set
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest instance
     */
    DebugDeleteProviderRequest.create = function create(properties) {
        return new DebugDeleteProviderRequest(properties);
    };

    /**
     * Encodes the specified DebugDeleteProviderRequest message. Does not implicitly {@link DebugDeleteProviderRequest.verify|verify} messages.
     * @function encode
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {IDebugDeleteProviderRequest} message DebugDeleteProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.resetOnly != null && Object.hasOwnProperty.call(message, "resetOnly"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.resetOnly);
        return writer;
    };

    /**
     * Encodes the specified DebugDeleteProviderRequest message, length delimited. Does not implicitly {@link DebugDeleteProviderRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {IDebugDeleteProviderRequest} message DebugDeleteProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DebugDeleteProviderRequest message from the specified reader or buffer.
     * @function decode
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DebugDeleteProviderRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.resetOnly = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DebugDeleteProviderRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DebugDeleteProviderRequest message.
     * @function verify
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DebugDeleteProviderRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.resetOnly != null && message.hasOwnProperty("resetOnly"))
            if (typeof message.resetOnly !== "boolean")
                return "resetOnly: boolean expected";
        return null;
    };

    /**
     * Creates a DebugDeleteProviderRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest
     */
    DebugDeleteProviderRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.DebugDeleteProviderRequest)
            return object;
        var message = new $root.DebugDeleteProviderRequest();
        if (object.resetOnly != null)
            message.resetOnly = Boolean(object.resetOnly);
        return message;
    };

    /**
     * Creates a plain object from a DebugDeleteProviderRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {DebugDeleteProviderRequest} message DebugDeleteProviderRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DebugDeleteProviderRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.resetOnly = false;
        if (message.resetOnly != null && message.hasOwnProperty("resetOnly"))
            object.resetOnly = message.resetOnly;
        return object;
    };

    /**
     * Converts this DebugDeleteProviderRequest to JSON.
     * @function toJSON
     * @memberof DebugDeleteProviderRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DebugDeleteProviderRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DebugDeleteProviderRequest
     * @function getTypeUrl
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DebugDeleteProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DebugDeleteProviderRequest";
    };

    return DebugDeleteProviderRequest;
})();

$root.DebugDeleteProviderResponse = (function() {

    /**
     * Properties of a DebugDeleteProviderResponse.
     * @exports IDebugDeleteProviderResponse
     * @interface IDebugDeleteProviderResponse
     * @property {boolean|null} [okay] DebugDeleteProviderResponse okay
     * @property {IApiProviderLoginProto|null} [provider] DebugDeleteProviderResponse provider
     * @property {IApiBusinessProfileProto|null} [businessProfile] DebugDeleteProviderResponse businessProfile
     */

    /**
     * Constructs a new DebugDeleteProviderResponse.
     * @exports DebugDeleteProviderResponse
     * @classdesc Represents a DebugDeleteProviderResponse.
     * @implements IDebugDeleteProviderResponse
     * @constructor
     * @param {IDebugDeleteProviderResponse=} [properties] Properties to set
     */
    function DebugDeleteProviderResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DebugDeleteProviderResponse okay.
     * @member {boolean} okay
     * @memberof DebugDeleteProviderResponse
     * @instance
     */
    DebugDeleteProviderResponse.prototype.okay = false;

    /**
     * DebugDeleteProviderResponse provider.
     * @member {IApiProviderLoginProto|null|undefined} provider
     * @memberof DebugDeleteProviderResponse
     * @instance
     */
    DebugDeleteProviderResponse.prototype.provider = null;

    /**
     * DebugDeleteProviderResponse businessProfile.
     * @member {IApiBusinessProfileProto|null|undefined} businessProfile
     * @memberof DebugDeleteProviderResponse
     * @instance
     */
    DebugDeleteProviderResponse.prototype.businessProfile = null;

    /**
     * Creates a new DebugDeleteProviderResponse instance using the specified properties.
     * @function create
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {IDebugDeleteProviderResponse=} [properties] Properties to set
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse instance
     */
    DebugDeleteProviderResponse.create = function create(properties) {
        return new DebugDeleteProviderResponse(properties);
    };

    /**
     * Encodes the specified DebugDeleteProviderResponse message. Does not implicitly {@link DebugDeleteProviderResponse.verify|verify} messages.
     * @function encode
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {IDebugDeleteProviderResponse} message DebugDeleteProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ApiProviderLoginProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.businessProfile != null && Object.hasOwnProperty.call(message, "businessProfile"))
            $root.ApiBusinessProfileProto.encode(message.businessProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified DebugDeleteProviderResponse message, length delimited. Does not implicitly {@link DebugDeleteProviderResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {IDebugDeleteProviderResponse} message DebugDeleteProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DebugDeleteProviderResponse message from the specified reader or buffer.
     * @function decode
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DebugDeleteProviderResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.provider = $root.ApiProviderLoginProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.businessProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DebugDeleteProviderResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DebugDeleteProviderResponse message.
     * @function verify
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DebugDeleteProviderResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ApiProviderLoginProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.businessProfile);
            if (error)
                return "businessProfile." + error;
        }
        return null;
    };

    /**
     * Creates a DebugDeleteProviderResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse
     */
    DebugDeleteProviderResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.DebugDeleteProviderResponse)
            return object;
        var message = new $root.DebugDeleteProviderResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".DebugDeleteProviderResponse.provider: object expected");
            message.provider = $root.ApiProviderLoginProto.fromObject(object.provider);
        }
        if (object.businessProfile != null) {
            if (typeof object.businessProfile !== "object")
                throw TypeError(".DebugDeleteProviderResponse.businessProfile: object expected");
            message.businessProfile = $root.ApiBusinessProfileProto.fromObject(object.businessProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a DebugDeleteProviderResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {DebugDeleteProviderResponse} message DebugDeleteProviderResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DebugDeleteProviderResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
            object.businessProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ApiProviderLoginProto.toObject(message.provider, options);
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile"))
            object.businessProfile = $root.ApiBusinessProfileProto.toObject(message.businessProfile, options);
        return object;
    };

    /**
     * Converts this DebugDeleteProviderResponse to JSON.
     * @function toJSON
     * @memberof DebugDeleteProviderResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DebugDeleteProviderResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DebugDeleteProviderResponse
     * @function getTypeUrl
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DebugDeleteProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DebugDeleteProviderResponse";
    };

    return DebugDeleteProviderResponse;
})();

$root.CreateServiceRequest = (function() {

    /**
     * Properties of a CreateServiceRequest.
     * @exports ICreateServiceRequest
     * @interface ICreateServiceRequest
     * @property {space.kenko.proto.IServiceProto|null} [service] CreateServiceRequest service
     * @property {boolean|null} [isUpdate] CreateServiceRequest isUpdate
     */

    /**
     * Constructs a new CreateServiceRequest.
     * @exports CreateServiceRequest
     * @classdesc Represents a CreateServiceRequest.
     * @implements ICreateServiceRequest
     * @constructor
     * @param {ICreateServiceRequest=} [properties] Properties to set
     */
    function CreateServiceRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateServiceRequest service.
     * @member {space.kenko.proto.IServiceProto|null|undefined} service
     * @memberof CreateServiceRequest
     * @instance
     */
    CreateServiceRequest.prototype.service = null;

    /**
     * CreateServiceRequest isUpdate.
     * @member {boolean} isUpdate
     * @memberof CreateServiceRequest
     * @instance
     */
    CreateServiceRequest.prototype.isUpdate = false;

    /**
     * Creates a new CreateServiceRequest instance using the specified properties.
     * @function create
     * @memberof CreateServiceRequest
     * @static
     * @param {ICreateServiceRequest=} [properties] Properties to set
     * @returns {CreateServiceRequest} CreateServiceRequest instance
     */
    CreateServiceRequest.create = function create(properties) {
        return new CreateServiceRequest(properties);
    };

    /**
     * Encodes the specified CreateServiceRequest message. Does not implicitly {@link CreateServiceRequest.verify|verify} messages.
     * @function encode
     * @memberof CreateServiceRequest
     * @static
     * @param {ICreateServiceRequest} message CreateServiceRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.service != null && Object.hasOwnProperty.call(message, "service"))
            $root.space.kenko.proto.ServiceProto.encode(message.service, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.isUpdate != null && Object.hasOwnProperty.call(message, "isUpdate"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isUpdate);
        return writer;
    };

    /**
     * Encodes the specified CreateServiceRequest message, length delimited. Does not implicitly {@link CreateServiceRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateServiceRequest
     * @static
     * @param {ICreateServiceRequest} message CreateServiceRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateServiceRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CreateServiceRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateServiceRequest} CreateServiceRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateServiceRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.service = $root.space.kenko.proto.ServiceProto.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.isUpdate = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateServiceRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateServiceRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateServiceRequest} CreateServiceRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateServiceRequest message.
     * @function verify
     * @memberof CreateServiceRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateServiceRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.service != null && message.hasOwnProperty("service")) {
            var error = $root.space.kenko.proto.ServiceProto.verify(message.service);
            if (error)
                return "service." + error;
        }
        if (message.isUpdate != null && message.hasOwnProperty("isUpdate"))
            if (typeof message.isUpdate !== "boolean")
                return "isUpdate: boolean expected";
        return null;
    };

    /**
     * Creates a CreateServiceRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateServiceRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateServiceRequest} CreateServiceRequest
     */
    CreateServiceRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateServiceRequest)
            return object;
        var message = new $root.CreateServiceRequest();
        if (object.service != null) {
            if (typeof object.service !== "object")
                throw TypeError(".CreateServiceRequest.service: object expected");
            message.service = $root.space.kenko.proto.ServiceProto.fromObject(object.service);
        }
        if (object.isUpdate != null)
            message.isUpdate = Boolean(object.isUpdate);
        return message;
    };

    /**
     * Creates a plain object from a CreateServiceRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateServiceRequest
     * @static
     * @param {CreateServiceRequest} message CreateServiceRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateServiceRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.service = null;
            object.isUpdate = false;
        }
        if (message.service != null && message.hasOwnProperty("service"))
            object.service = $root.space.kenko.proto.ServiceProto.toObject(message.service, options);
        if (message.isUpdate != null && message.hasOwnProperty("isUpdate"))
            object.isUpdate = message.isUpdate;
        return object;
    };

    /**
     * Converts this CreateServiceRequest to JSON.
     * @function toJSON
     * @memberof CreateServiceRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateServiceRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateServiceRequest
     * @function getTypeUrl
     * @memberof CreateServiceRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateServiceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateServiceRequest";
    };

    return CreateServiceRequest;
})();

$root.CreateServiceResponse = (function() {

    /**
     * Properties of a CreateServiceResponse.
     * @exports ICreateServiceResponse
     * @interface ICreateServiceResponse
     * @property {boolean|null} [okay] CreateServiceResponse okay
     * @property {Array.<IErrorsProto>|null} [errors] CreateServiceResponse errors
     * @property {IApiBusinessProfileProto|null} [newProfile] CreateServiceResponse newProfile
     */

    /**
     * Constructs a new CreateServiceResponse.
     * @exports CreateServiceResponse
     * @classdesc Represents a CreateServiceResponse.
     * @implements ICreateServiceResponse
     * @constructor
     * @param {ICreateServiceResponse=} [properties] Properties to set
     */
    function CreateServiceResponse(properties) {
        this.errors = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateServiceResponse okay.
     * @member {boolean} okay
     * @memberof CreateServiceResponse
     * @instance
     */
    CreateServiceResponse.prototype.okay = false;

    /**
     * CreateServiceResponse errors.
     * @member {Array.<IErrorsProto>} errors
     * @memberof CreateServiceResponse
     * @instance
     */
    CreateServiceResponse.prototype.errors = $util.emptyArray;

    /**
     * CreateServiceResponse newProfile.
     * @member {IApiBusinessProfileProto|null|undefined} newProfile
     * @memberof CreateServiceResponse
     * @instance
     */
    CreateServiceResponse.prototype.newProfile = null;

    /**
     * Creates a new CreateServiceResponse instance using the specified properties.
     * @function create
     * @memberof CreateServiceResponse
     * @static
     * @param {ICreateServiceResponse=} [properties] Properties to set
     * @returns {CreateServiceResponse} CreateServiceResponse instance
     */
    CreateServiceResponse.create = function create(properties) {
        return new CreateServiceResponse(properties);
    };

    /**
     * Encodes the specified CreateServiceResponse message. Does not implicitly {@link CreateServiceResponse.verify|verify} messages.
     * @function encode
     * @memberof CreateServiceResponse
     * @static
     * @param {ICreateServiceResponse} message CreateServiceResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.errors != null && message.errors.length)
            for (var i = 0; i < message.errors.length; ++i)
                $root.ErrorsProto.encode(message.errors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ApiBusinessProfileProto.encode(message.newProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CreateServiceResponse message, length delimited. Does not implicitly {@link CreateServiceResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateServiceResponse
     * @static
     * @param {ICreateServiceResponse} message CreateServiceResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateServiceResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CreateServiceResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateServiceResponse} CreateServiceResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateServiceResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.errors && message.errors.length))
                        message.errors = [];
                    message.errors.push($root.ErrorsProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.newProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateServiceResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateServiceResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateServiceResponse} CreateServiceResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateServiceResponse message.
     * @function verify
     * @memberof CreateServiceResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateServiceResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.errors != null && message.hasOwnProperty("errors")) {
            if (!Array.isArray(message.errors))
                return "errors: array expected";
            for (var i = 0; i < message.errors.length; ++i) {
                var error = $root.ErrorsProto.verify(message.errors[i]);
                if (error)
                    return "errors." + error;
            }
        }
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        return null;
    };

    /**
     * Creates a CreateServiceResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateServiceResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateServiceResponse} CreateServiceResponse
     */
    CreateServiceResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateServiceResponse)
            return object;
        var message = new $root.CreateServiceResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.errors) {
            if (!Array.isArray(object.errors))
                throw TypeError(".CreateServiceResponse.errors: array expected");
            message.errors = [];
            for (var i = 0; i < object.errors.length; ++i) {
                if (typeof object.errors[i] !== "object")
                    throw TypeError(".CreateServiceResponse.errors: object expected");
                message.errors[i] = $root.ErrorsProto.fromObject(object.errors[i]);
            }
        }
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".CreateServiceResponse.newProfile: object expected");
            message.newProfile = $root.ApiBusinessProfileProto.fromObject(object.newProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a CreateServiceResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateServiceResponse
     * @static
     * @param {CreateServiceResponse} message CreateServiceResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateServiceResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.errors = [];
        if (options.defaults) {
            object.okay = false;
            object.newProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.errors && message.errors.length) {
            object.errors = [];
            for (var j = 0; j < message.errors.length; ++j)
                object.errors[j] = $root.ErrorsProto.toObject(message.errors[j], options);
        }
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ApiBusinessProfileProto.toObject(message.newProfile, options);
        return object;
    };

    /**
     * Converts this CreateServiceResponse to JSON.
     * @function toJSON
     * @memberof CreateServiceResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateServiceResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateServiceResponse
     * @function getTypeUrl
     * @memberof CreateServiceResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateServiceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateServiceResponse";
    };

    return CreateServiceResponse;
})();

$root.CreateOrUpdateTeamMemberRequest = (function() {

    /**
     * Properties of a CreateOrUpdateTeamMemberRequest.
     * @exports ICreateOrUpdateTeamMemberRequest
     * @interface ICreateOrUpdateTeamMemberRequest
     * @property {space.kenko.proto.IProviderBioProto|null} [bio] CreateOrUpdateTeamMemberRequest bio
     * @property {boolean|null} [isUpdate] CreateOrUpdateTeamMemberRequest isUpdate
     */

    /**
     * Constructs a new CreateOrUpdateTeamMemberRequest.
     * @exports CreateOrUpdateTeamMemberRequest
     * @classdesc Represents a CreateOrUpdateTeamMemberRequest.
     * @implements ICreateOrUpdateTeamMemberRequest
     * @constructor
     * @param {ICreateOrUpdateTeamMemberRequest=} [properties] Properties to set
     */
    function CreateOrUpdateTeamMemberRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateOrUpdateTeamMemberRequest bio.
     * @member {space.kenko.proto.IProviderBioProto|null|undefined} bio
     * @memberof CreateOrUpdateTeamMemberRequest
     * @instance
     */
    CreateOrUpdateTeamMemberRequest.prototype.bio = null;

    /**
     * CreateOrUpdateTeamMemberRequest isUpdate.
     * @member {boolean} isUpdate
     * @memberof CreateOrUpdateTeamMemberRequest
     * @instance
     */
    CreateOrUpdateTeamMemberRequest.prototype.isUpdate = false;

    /**
     * Creates a new CreateOrUpdateTeamMemberRequest instance using the specified properties.
     * @function create
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {ICreateOrUpdateTeamMemberRequest=} [properties] Properties to set
     * @returns {CreateOrUpdateTeamMemberRequest} CreateOrUpdateTeamMemberRequest instance
     */
    CreateOrUpdateTeamMemberRequest.create = function create(properties) {
        return new CreateOrUpdateTeamMemberRequest(properties);
    };

    /**
     * Encodes the specified CreateOrUpdateTeamMemberRequest message. Does not implicitly {@link CreateOrUpdateTeamMemberRequest.verify|verify} messages.
     * @function encode
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {ICreateOrUpdateTeamMemberRequest} message CreateOrUpdateTeamMemberRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateOrUpdateTeamMemberRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.bio != null && Object.hasOwnProperty.call(message, "bio"))
            $root.space.kenko.proto.ProviderBioProto.encode(message.bio, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.isUpdate != null && Object.hasOwnProperty.call(message, "isUpdate"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isUpdate);
        return writer;
    };

    /**
     * Encodes the specified CreateOrUpdateTeamMemberRequest message, length delimited. Does not implicitly {@link CreateOrUpdateTeamMemberRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {ICreateOrUpdateTeamMemberRequest} message CreateOrUpdateTeamMemberRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateOrUpdateTeamMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateOrUpdateTeamMemberRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateOrUpdateTeamMemberRequest} CreateOrUpdateTeamMemberRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateOrUpdateTeamMemberRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateOrUpdateTeamMemberRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.bio = $root.space.kenko.proto.ProviderBioProto.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.isUpdate = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateOrUpdateTeamMemberRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateOrUpdateTeamMemberRequest} CreateOrUpdateTeamMemberRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateOrUpdateTeamMemberRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateOrUpdateTeamMemberRequest message.
     * @function verify
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateOrUpdateTeamMemberRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.bio != null && message.hasOwnProperty("bio")) {
            var error = $root.space.kenko.proto.ProviderBioProto.verify(message.bio);
            if (error)
                return "bio." + error;
        }
        if (message.isUpdate != null && message.hasOwnProperty("isUpdate"))
            if (typeof message.isUpdate !== "boolean")
                return "isUpdate: boolean expected";
        return null;
    };

    /**
     * Creates a CreateOrUpdateTeamMemberRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateOrUpdateTeamMemberRequest} CreateOrUpdateTeamMemberRequest
     */
    CreateOrUpdateTeamMemberRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateOrUpdateTeamMemberRequest)
            return object;
        var message = new $root.CreateOrUpdateTeamMemberRequest();
        if (object.bio != null) {
            if (typeof object.bio !== "object")
                throw TypeError(".CreateOrUpdateTeamMemberRequest.bio: object expected");
            message.bio = $root.space.kenko.proto.ProviderBioProto.fromObject(object.bio);
        }
        if (object.isUpdate != null)
            message.isUpdate = Boolean(object.isUpdate);
        return message;
    };

    /**
     * Creates a plain object from a CreateOrUpdateTeamMemberRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {CreateOrUpdateTeamMemberRequest} message CreateOrUpdateTeamMemberRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateOrUpdateTeamMemberRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.bio = null;
            object.isUpdate = false;
        }
        if (message.bio != null && message.hasOwnProperty("bio"))
            object.bio = $root.space.kenko.proto.ProviderBioProto.toObject(message.bio, options);
        if (message.isUpdate != null && message.hasOwnProperty("isUpdate"))
            object.isUpdate = message.isUpdate;
        return object;
    };

    /**
     * Converts this CreateOrUpdateTeamMemberRequest to JSON.
     * @function toJSON
     * @memberof CreateOrUpdateTeamMemberRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateOrUpdateTeamMemberRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateOrUpdateTeamMemberRequest
     * @function getTypeUrl
     * @memberof CreateOrUpdateTeamMemberRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateOrUpdateTeamMemberRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateOrUpdateTeamMemberRequest";
    };

    return CreateOrUpdateTeamMemberRequest;
})();

$root.CreateOrUpdateTeamMemberResponse = (function() {

    /**
     * Properties of a CreateOrUpdateTeamMemberResponse.
     * @exports ICreateOrUpdateTeamMemberResponse
     * @interface ICreateOrUpdateTeamMemberResponse
     * @property {boolean|null} [okay] CreateOrUpdateTeamMemberResponse okay
     * @property {Array.<IErrorsProto>|null} [errors] CreateOrUpdateTeamMemberResponse errors
     * @property {IApiBusinessProfileProto|null} [newProfile] CreateOrUpdateTeamMemberResponse newProfile
     */

    /**
     * Constructs a new CreateOrUpdateTeamMemberResponse.
     * @exports CreateOrUpdateTeamMemberResponse
     * @classdesc Represents a CreateOrUpdateTeamMemberResponse.
     * @implements ICreateOrUpdateTeamMemberResponse
     * @constructor
     * @param {ICreateOrUpdateTeamMemberResponse=} [properties] Properties to set
     */
    function CreateOrUpdateTeamMemberResponse(properties) {
        this.errors = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateOrUpdateTeamMemberResponse okay.
     * @member {boolean} okay
     * @memberof CreateOrUpdateTeamMemberResponse
     * @instance
     */
    CreateOrUpdateTeamMemberResponse.prototype.okay = false;

    /**
     * CreateOrUpdateTeamMemberResponse errors.
     * @member {Array.<IErrorsProto>} errors
     * @memberof CreateOrUpdateTeamMemberResponse
     * @instance
     */
    CreateOrUpdateTeamMemberResponse.prototype.errors = $util.emptyArray;

    /**
     * CreateOrUpdateTeamMemberResponse newProfile.
     * @member {IApiBusinessProfileProto|null|undefined} newProfile
     * @memberof CreateOrUpdateTeamMemberResponse
     * @instance
     */
    CreateOrUpdateTeamMemberResponse.prototype.newProfile = null;

    /**
     * Creates a new CreateOrUpdateTeamMemberResponse instance using the specified properties.
     * @function create
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {ICreateOrUpdateTeamMemberResponse=} [properties] Properties to set
     * @returns {CreateOrUpdateTeamMemberResponse} CreateOrUpdateTeamMemberResponse instance
     */
    CreateOrUpdateTeamMemberResponse.create = function create(properties) {
        return new CreateOrUpdateTeamMemberResponse(properties);
    };

    /**
     * Encodes the specified CreateOrUpdateTeamMemberResponse message. Does not implicitly {@link CreateOrUpdateTeamMemberResponse.verify|verify} messages.
     * @function encode
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {ICreateOrUpdateTeamMemberResponse} message CreateOrUpdateTeamMemberResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateOrUpdateTeamMemberResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.errors != null && message.errors.length)
            for (var i = 0; i < message.errors.length; ++i)
                $root.ErrorsProto.encode(message.errors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ApiBusinessProfileProto.encode(message.newProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CreateOrUpdateTeamMemberResponse message, length delimited. Does not implicitly {@link CreateOrUpdateTeamMemberResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {ICreateOrUpdateTeamMemberResponse} message CreateOrUpdateTeamMemberResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateOrUpdateTeamMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateOrUpdateTeamMemberResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateOrUpdateTeamMemberResponse} CreateOrUpdateTeamMemberResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateOrUpdateTeamMemberResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateOrUpdateTeamMemberResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.errors && message.errors.length))
                        message.errors = [];
                    message.errors.push($root.ErrorsProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.newProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateOrUpdateTeamMemberResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateOrUpdateTeamMemberResponse} CreateOrUpdateTeamMemberResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateOrUpdateTeamMemberResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateOrUpdateTeamMemberResponse message.
     * @function verify
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateOrUpdateTeamMemberResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.errors != null && message.hasOwnProperty("errors")) {
            if (!Array.isArray(message.errors))
                return "errors: array expected";
            for (var i = 0; i < message.errors.length; ++i) {
                var error = $root.ErrorsProto.verify(message.errors[i]);
                if (error)
                    return "errors." + error;
            }
        }
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        return null;
    };

    /**
     * Creates a CreateOrUpdateTeamMemberResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateOrUpdateTeamMemberResponse} CreateOrUpdateTeamMemberResponse
     */
    CreateOrUpdateTeamMemberResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateOrUpdateTeamMemberResponse)
            return object;
        var message = new $root.CreateOrUpdateTeamMemberResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.errors) {
            if (!Array.isArray(object.errors))
                throw TypeError(".CreateOrUpdateTeamMemberResponse.errors: array expected");
            message.errors = [];
            for (var i = 0; i < object.errors.length; ++i) {
                if (typeof object.errors[i] !== "object")
                    throw TypeError(".CreateOrUpdateTeamMemberResponse.errors: object expected");
                message.errors[i] = $root.ErrorsProto.fromObject(object.errors[i]);
            }
        }
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".CreateOrUpdateTeamMemberResponse.newProfile: object expected");
            message.newProfile = $root.ApiBusinessProfileProto.fromObject(object.newProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a CreateOrUpdateTeamMemberResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {CreateOrUpdateTeamMemberResponse} message CreateOrUpdateTeamMemberResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateOrUpdateTeamMemberResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.errors = [];
        if (options.defaults) {
            object.okay = false;
            object.newProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.errors && message.errors.length) {
            object.errors = [];
            for (var j = 0; j < message.errors.length; ++j)
                object.errors[j] = $root.ErrorsProto.toObject(message.errors[j], options);
        }
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ApiBusinessProfileProto.toObject(message.newProfile, options);
        return object;
    };

    /**
     * Converts this CreateOrUpdateTeamMemberResponse to JSON.
     * @function toJSON
     * @memberof CreateOrUpdateTeamMemberResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateOrUpdateTeamMemberResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateOrUpdateTeamMemberResponse
     * @function getTypeUrl
     * @memberof CreateOrUpdateTeamMemberResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateOrUpdateTeamMemberResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateOrUpdateTeamMemberResponse";
    };

    return CreateOrUpdateTeamMemberResponse;
})();

$root.ErrorsProto = (function() {

    /**
     * Properties of an ErrorsProto.
     * @exports IErrorsProto
     * @interface IErrorsProto
     * @property {string|null} [fieldName] ErrorsProto fieldName
     * @property {string|null} [error] ErrorsProto error
     * @property {number|null} [index] ErrorsProto index
     */

    /**
     * Constructs a new ErrorsProto.
     * @exports ErrorsProto
     * @classdesc Represents an ErrorsProto.
     * @implements IErrorsProto
     * @constructor
     * @param {IErrorsProto=} [properties] Properties to set
     */
    function ErrorsProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ErrorsProto fieldName.
     * @member {string} fieldName
     * @memberof ErrorsProto
     * @instance
     */
    ErrorsProto.prototype.fieldName = "";

    /**
     * ErrorsProto error.
     * @member {string} error
     * @memberof ErrorsProto
     * @instance
     */
    ErrorsProto.prototype.error = "";

    /**
     * ErrorsProto index.
     * @member {number} index
     * @memberof ErrorsProto
     * @instance
     */
    ErrorsProto.prototype.index = 0;

    /**
     * Creates a new ErrorsProto instance using the specified properties.
     * @function create
     * @memberof ErrorsProto
     * @static
     * @param {IErrorsProto=} [properties] Properties to set
     * @returns {ErrorsProto} ErrorsProto instance
     */
    ErrorsProto.create = function create(properties) {
        return new ErrorsProto(properties);
    };

    /**
     * Encodes the specified ErrorsProto message. Does not implicitly {@link ErrorsProto.verify|verify} messages.
     * @function encode
     * @memberof ErrorsProto
     * @static
     * @param {IErrorsProto} message ErrorsProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ErrorsProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.fieldName != null && Object.hasOwnProperty.call(message, "fieldName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.fieldName);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
        if (message.index != null && Object.hasOwnProperty.call(message, "index"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.index);
        return writer;
    };

    /**
     * Encodes the specified ErrorsProto message, length delimited. Does not implicitly {@link ErrorsProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ErrorsProto
     * @static
     * @param {IErrorsProto} message ErrorsProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ErrorsProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ErrorsProto message from the specified reader or buffer.
     * @function decode
     * @memberof ErrorsProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ErrorsProto} ErrorsProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ErrorsProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ErrorsProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.fieldName = reader.string();
                    break;
                }
            case 2: {
                    message.error = reader.string();
                    break;
                }
            case 3: {
                    message.index = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ErrorsProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ErrorsProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ErrorsProto} ErrorsProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ErrorsProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ErrorsProto message.
     * @function verify
     * @memberof ErrorsProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ErrorsProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.fieldName != null && message.hasOwnProperty("fieldName"))
            if (!$util.isString(message.fieldName))
                return "fieldName: string expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.index != null && message.hasOwnProperty("index"))
            if (!$util.isInteger(message.index))
                return "index: integer expected";
        return null;
    };

    /**
     * Creates an ErrorsProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ErrorsProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ErrorsProto} ErrorsProto
     */
    ErrorsProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ErrorsProto)
            return object;
        var message = new $root.ErrorsProto();
        if (object.fieldName != null)
            message.fieldName = String(object.fieldName);
        if (object.error != null)
            message.error = String(object.error);
        if (object.index != null)
            message.index = object.index | 0;
        return message;
    };

    /**
     * Creates a plain object from an ErrorsProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ErrorsProto
     * @static
     * @param {ErrorsProto} message ErrorsProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ErrorsProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.fieldName = "";
            object.error = "";
            object.index = 0;
        }
        if (message.fieldName != null && message.hasOwnProperty("fieldName"))
            object.fieldName = message.fieldName;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.index != null && message.hasOwnProperty("index"))
            object.index = message.index;
        return object;
    };

    /**
     * Converts this ErrorsProto to JSON.
     * @function toJSON
     * @memberof ErrorsProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ErrorsProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ErrorsProto
     * @function getTypeUrl
     * @memberof ErrorsProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ErrorsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ErrorsProto";
    };

    return ErrorsProto;
})();

$root.AcceptProviderInviteRequest = (function() {

    /**
     * Properties of an AcceptProviderInviteRequest.
     * @exports IAcceptProviderInviteRequest
     * @interface IAcceptProviderInviteRequest
     * @property {string|null} [inviteCode] AcceptProviderInviteRequest inviteCode
     * @property {boolean|null} [accepted] AcceptProviderInviteRequest accepted
     */

    /**
     * Constructs a new AcceptProviderInviteRequest.
     * @exports AcceptProviderInviteRequest
     * @classdesc Represents an AcceptProviderInviteRequest.
     * @implements IAcceptProviderInviteRequest
     * @constructor
     * @param {IAcceptProviderInviteRequest=} [properties] Properties to set
     */
    function AcceptProviderInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptProviderInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof AcceptProviderInviteRequest
     * @instance
     */
    AcceptProviderInviteRequest.prototype.inviteCode = "";

    /**
     * AcceptProviderInviteRequest accepted.
     * @member {boolean} accepted
     * @memberof AcceptProviderInviteRequest
     * @instance
     */
    AcceptProviderInviteRequest.prototype.accepted = false;

    /**
     * Creates a new AcceptProviderInviteRequest instance using the specified properties.
     * @function create
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {IAcceptProviderInviteRequest=} [properties] Properties to set
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest instance
     */
    AcceptProviderInviteRequest.create = function create(properties) {
        return new AcceptProviderInviteRequest(properties);
    };

    /**
     * Encodes the specified AcceptProviderInviteRequest message. Does not implicitly {@link AcceptProviderInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {IAcceptProviderInviteRequest} message AcceptProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
        if (message.accepted != null && Object.hasOwnProperty.call(message, "accepted"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.accepted);
        return writer;
    };

    /**
     * Encodes the specified AcceptProviderInviteRequest message, length delimited. Does not implicitly {@link AcceptProviderInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {IAcceptProviderInviteRequest} message AcceptProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptProviderInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptProviderInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.inviteCode = reader.string();
                    break;
                }
            case 2: {
                    message.accepted = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptProviderInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptProviderInviteRequest message.
     * @function verify
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptProviderInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            if (typeof message.accepted !== "boolean")
                return "accepted: boolean expected";
        return null;
    };

    /**
     * Creates an AcceptProviderInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest
     */
    AcceptProviderInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptProviderInviteRequest)
            return object;
        var message = new $root.AcceptProviderInviteRequest();
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        if (object.accepted != null)
            message.accepted = Boolean(object.accepted);
        return message;
    };

    /**
     * Creates a plain object from an AcceptProviderInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {AcceptProviderInviteRequest} message AcceptProviderInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptProviderInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.inviteCode = "";
            object.accepted = false;
        }
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            object.accepted = message.accepted;
        return object;
    };

    /**
     * Converts this AcceptProviderInviteRequest to JSON.
     * @function toJSON
     * @memberof AcceptProviderInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptProviderInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptProviderInviteRequest
     * @function getTypeUrl
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptProviderInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptProviderInviteRequest";
    };

    return AcceptProviderInviteRequest;
})();

$root.AcceptProviderInviteResponse = (function() {

    /**
     * Properties of an AcceptProviderInviteResponse.
     * @exports IAcceptProviderInviteResponse
     * @interface IAcceptProviderInviteResponse
     * @property {boolean|null} [okay] AcceptProviderInviteResponse okay
     * @property {IApiBusinessProfileProto|null} [businessProfile] AcceptProviderInviteResponse businessProfile
     * @property {IApiProviderLoginProto|null} [provider] AcceptProviderInviteResponse provider
     */

    /**
     * Constructs a new AcceptProviderInviteResponse.
     * @exports AcceptProviderInviteResponse
     * @classdesc Represents an AcceptProviderInviteResponse.
     * @implements IAcceptProviderInviteResponse
     * @constructor
     * @param {IAcceptProviderInviteResponse=} [properties] Properties to set
     */
    function AcceptProviderInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptProviderInviteResponse okay.
     * @member {boolean} okay
     * @memberof AcceptProviderInviteResponse
     * @instance
     */
    AcceptProviderInviteResponse.prototype.okay = false;

    /**
     * AcceptProviderInviteResponse businessProfile.
     * @member {IApiBusinessProfileProto|null|undefined} businessProfile
     * @memberof AcceptProviderInviteResponse
     * @instance
     */
    AcceptProviderInviteResponse.prototype.businessProfile = null;

    /**
     * AcceptProviderInviteResponse provider.
     * @member {IApiProviderLoginProto|null|undefined} provider
     * @memberof AcceptProviderInviteResponse
     * @instance
     */
    AcceptProviderInviteResponse.prototype.provider = null;

    /**
     * Creates a new AcceptProviderInviteResponse instance using the specified properties.
     * @function create
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {IAcceptProviderInviteResponse=} [properties] Properties to set
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse instance
     */
    AcceptProviderInviteResponse.create = function create(properties) {
        return new AcceptProviderInviteResponse(properties);
    };

    /**
     * Encodes the specified AcceptProviderInviteResponse message. Does not implicitly {@link AcceptProviderInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {IAcceptProviderInviteResponse} message AcceptProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.businessProfile != null && Object.hasOwnProperty.call(message, "businessProfile"))
            $root.ApiBusinessProfileProto.encode(message.businessProfile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ApiProviderLoginProto.encode(message.provider, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AcceptProviderInviteResponse message, length delimited. Does not implicitly {@link AcceptProviderInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {IAcceptProviderInviteResponse} message AcceptProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptProviderInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptProviderInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.businessProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.provider = $root.ApiProviderLoginProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptProviderInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptProviderInviteResponse message.
     * @function verify
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptProviderInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.businessProfile);
            if (error)
                return "businessProfile." + error;
        }
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ApiProviderLoginProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        return null;
    };

    /**
     * Creates an AcceptProviderInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse
     */
    AcceptProviderInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptProviderInviteResponse)
            return object;
        var message = new $root.AcceptProviderInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.businessProfile != null) {
            if (typeof object.businessProfile !== "object")
                throw TypeError(".AcceptProviderInviteResponse.businessProfile: object expected");
            message.businessProfile = $root.ApiBusinessProfileProto.fromObject(object.businessProfile);
        }
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".AcceptProviderInviteResponse.provider: object expected");
            message.provider = $root.ApiProviderLoginProto.fromObject(object.provider);
        }
        return message;
    };

    /**
     * Creates a plain object from an AcceptProviderInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {AcceptProviderInviteResponse} message AcceptProviderInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptProviderInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.businessProfile = null;
            object.provider = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile"))
            object.businessProfile = $root.ApiBusinessProfileProto.toObject(message.businessProfile, options);
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ApiProviderLoginProto.toObject(message.provider, options);
        return object;
    };

    /**
     * Converts this AcceptProviderInviteResponse to JSON.
     * @function toJSON
     * @memberof AcceptProviderInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptProviderInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptProviderInviteResponse
     * @function getTypeUrl
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptProviderInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptProviderInviteResponse";
    };

    return AcceptProviderInviteResponse;
})();

$root.CheckProviderInviteRequest = (function() {

    /**
     * Properties of a CheckProviderInviteRequest.
     * @exports ICheckProviderInviteRequest
     * @interface ICheckProviderInviteRequest
     * @property {string|null} [networkId] CheckProviderInviteRequest networkId
     * @property {string|null} [inviteCode] CheckProviderInviteRequest inviteCode
     */

    /**
     * Constructs a new CheckProviderInviteRequest.
     * @exports CheckProviderInviteRequest
     * @classdesc Represents a CheckProviderInviteRequest.
     * @implements ICheckProviderInviteRequest
     * @constructor
     * @param {ICheckProviderInviteRequest=} [properties] Properties to set
     */
    function CheckProviderInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckProviderInviteRequest networkId.
     * @member {string} networkId
     * @memberof CheckProviderInviteRequest
     * @instance
     */
    CheckProviderInviteRequest.prototype.networkId = "";

    /**
     * CheckProviderInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof CheckProviderInviteRequest
     * @instance
     */
    CheckProviderInviteRequest.prototype.inviteCode = "";

    /**
     * Creates a new CheckProviderInviteRequest instance using the specified properties.
     * @function create
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {ICheckProviderInviteRequest=} [properties] Properties to set
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest instance
     */
    CheckProviderInviteRequest.create = function create(properties) {
        return new CheckProviderInviteRequest(properties);
    };

    /**
     * Encodes the specified CheckProviderInviteRequest message. Does not implicitly {@link CheckProviderInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {ICheckProviderInviteRequest} message CheckProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.networkId != null && Object.hasOwnProperty.call(message, "networkId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.networkId);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified CheckProviderInviteRequest message, length delimited. Does not implicitly {@link CheckProviderInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {ICheckProviderInviteRequest} message CheckProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckProviderInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckProviderInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.networkId = reader.string();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckProviderInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckProviderInviteRequest message.
     * @function verify
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckProviderInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            if (!$util.isString(message.networkId))
                return "networkId: string expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates a CheckProviderInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest
     */
    CheckProviderInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckProviderInviteRequest)
            return object;
        var message = new $root.CheckProviderInviteRequest();
        if (object.networkId != null)
            message.networkId = String(object.networkId);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from a CheckProviderInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {CheckProviderInviteRequest} message CheckProviderInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckProviderInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.networkId = "";
            object.inviteCode = "";
        }
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            object.networkId = message.networkId;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this CheckProviderInviteRequest to JSON.
     * @function toJSON
     * @memberof CheckProviderInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckProviderInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckProviderInviteRequest
     * @function getTypeUrl
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckProviderInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckProviderInviteRequest";
    };

    return CheckProviderInviteRequest;
})();

$root.CheckProviderInviteResponse = (function() {

    /**
     * Properties of a CheckProviderInviteResponse.
     * @exports ICheckProviderInviteResponse
     * @interface ICheckProviderInviteResponse
     * @property {boolean|null} [okay] CheckProviderInviteResponse okay
     */

    /**
     * Constructs a new CheckProviderInviteResponse.
     * @exports CheckProviderInviteResponse
     * @classdesc Represents a CheckProviderInviteResponse.
     * @implements ICheckProviderInviteResponse
     * @constructor
     * @param {ICheckProviderInviteResponse=} [properties] Properties to set
     */
    function CheckProviderInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckProviderInviteResponse okay.
     * @member {boolean} okay
     * @memberof CheckProviderInviteResponse
     * @instance
     */
    CheckProviderInviteResponse.prototype.okay = false;

    /**
     * Creates a new CheckProviderInviteResponse instance using the specified properties.
     * @function create
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {ICheckProviderInviteResponse=} [properties] Properties to set
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse instance
     */
    CheckProviderInviteResponse.create = function create(properties) {
        return new CheckProviderInviteResponse(properties);
    };

    /**
     * Encodes the specified CheckProviderInviteResponse message. Does not implicitly {@link CheckProviderInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {ICheckProviderInviteResponse} message CheckProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        return writer;
    };

    /**
     * Encodes the specified CheckProviderInviteResponse message, length delimited. Does not implicitly {@link CheckProviderInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {ICheckProviderInviteResponse} message CheckProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckProviderInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckProviderInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckProviderInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckProviderInviteResponse message.
     * @function verify
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckProviderInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        return null;
    };

    /**
     * Creates a CheckProviderInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse
     */
    CheckProviderInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckProviderInviteResponse)
            return object;
        var message = new $root.CheckProviderInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        return message;
    };

    /**
     * Creates a plain object from a CheckProviderInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {CheckProviderInviteResponse} message CheckProviderInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckProviderInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        return object;
    };

    /**
     * Converts this CheckProviderInviteResponse to JSON.
     * @function toJSON
     * @memberof CheckProviderInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckProviderInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckProviderInviteResponse
     * @function getTypeUrl
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckProviderInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckProviderInviteResponse";
    };

    return CheckProviderInviteResponse;
})();

$root.UpdateAvailabilityRequest = (function() {

    /**
     * Properties of an UpdateAvailabilityRequest.
     * @exports IUpdateAvailabilityRequest
     * @interface IUpdateAvailabilityRequest
     * @property {number|null} [dayOfWeek] UpdateAvailabilityRequest dayOfWeek
     * @property {space.kenko.proto.ILocalTimeProto|null} [startTime] UpdateAvailabilityRequest startTime
     * @property {space.kenko.proto.ILocalTimeProto|null} [endTime] UpdateAvailabilityRequest endTime
     * @property {boolean|null} [removing] UpdateAvailabilityRequest removing
     */

    /**
     * Constructs a new UpdateAvailabilityRequest.
     * @exports UpdateAvailabilityRequest
     * @classdesc Represents an UpdateAvailabilityRequest.
     * @implements IUpdateAvailabilityRequest
     * @constructor
     * @param {IUpdateAvailabilityRequest=} [properties] Properties to set
     */
    function UpdateAvailabilityRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateAvailabilityRequest dayOfWeek.
     * @member {number} dayOfWeek
     * @memberof UpdateAvailabilityRequest
     * @instance
     */
    UpdateAvailabilityRequest.prototype.dayOfWeek = 0;

    /**
     * UpdateAvailabilityRequest startTime.
     * @member {space.kenko.proto.ILocalTimeProto|null|undefined} startTime
     * @memberof UpdateAvailabilityRequest
     * @instance
     */
    UpdateAvailabilityRequest.prototype.startTime = null;

    /**
     * UpdateAvailabilityRequest endTime.
     * @member {space.kenko.proto.ILocalTimeProto|null|undefined} endTime
     * @memberof UpdateAvailabilityRequest
     * @instance
     */
    UpdateAvailabilityRequest.prototype.endTime = null;

    /**
     * UpdateAvailabilityRequest removing.
     * @member {boolean} removing
     * @memberof UpdateAvailabilityRequest
     * @instance
     */
    UpdateAvailabilityRequest.prototype.removing = false;

    /**
     * Creates a new UpdateAvailabilityRequest instance using the specified properties.
     * @function create
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {IUpdateAvailabilityRequest=} [properties] Properties to set
     * @returns {UpdateAvailabilityRequest} UpdateAvailabilityRequest instance
     */
    UpdateAvailabilityRequest.create = function create(properties) {
        return new UpdateAvailabilityRequest(properties);
    };

    /**
     * Encodes the specified UpdateAvailabilityRequest message. Does not implicitly {@link UpdateAvailabilityRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {IUpdateAvailabilityRequest} message UpdateAvailabilityRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAvailabilityRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dayOfWeek);
        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
            $root.space.kenko.proto.LocalTimeProto.encode(message.startTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
            $root.space.kenko.proto.LocalTimeProto.encode(message.endTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.removing != null && Object.hasOwnProperty.call(message, "removing"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.removing);
        return writer;
    };

    /**
     * Encodes the specified UpdateAvailabilityRequest message, length delimited. Does not implicitly {@link UpdateAvailabilityRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {IUpdateAvailabilityRequest} message UpdateAvailabilityRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAvailabilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateAvailabilityRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateAvailabilityRequest} UpdateAvailabilityRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAvailabilityRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateAvailabilityRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.dayOfWeek = reader.int32();
                    break;
                }
            case 2: {
                    message.startTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.endTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.removing = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateAvailabilityRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateAvailabilityRequest} UpdateAvailabilityRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAvailabilityRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateAvailabilityRequest message.
     * @function verify
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateAvailabilityRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
            if (!$util.isInteger(message.dayOfWeek))
                return "dayOfWeek: integer expected";
        if (message.startTime != null && message.hasOwnProperty("startTime")) {
            var error = $root.space.kenko.proto.LocalTimeProto.verify(message.startTime);
            if (error)
                return "startTime." + error;
        }
        if (message.endTime != null && message.hasOwnProperty("endTime")) {
            var error = $root.space.kenko.proto.LocalTimeProto.verify(message.endTime);
            if (error)
                return "endTime." + error;
        }
        if (message.removing != null && message.hasOwnProperty("removing"))
            if (typeof message.removing !== "boolean")
                return "removing: boolean expected";
        return null;
    };

    /**
     * Creates an UpdateAvailabilityRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateAvailabilityRequest} UpdateAvailabilityRequest
     */
    UpdateAvailabilityRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateAvailabilityRequest)
            return object;
        var message = new $root.UpdateAvailabilityRequest();
        if (object.dayOfWeek != null)
            message.dayOfWeek = object.dayOfWeek | 0;
        if (object.startTime != null) {
            if (typeof object.startTime !== "object")
                throw TypeError(".UpdateAvailabilityRequest.startTime: object expected");
            message.startTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.startTime);
        }
        if (object.endTime != null) {
            if (typeof object.endTime !== "object")
                throw TypeError(".UpdateAvailabilityRequest.endTime: object expected");
            message.endTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.endTime);
        }
        if (object.removing != null)
            message.removing = Boolean(object.removing);
        return message;
    };

    /**
     * Creates a plain object from an UpdateAvailabilityRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {UpdateAvailabilityRequest} message UpdateAvailabilityRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateAvailabilityRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.dayOfWeek = 0;
            object.startTime = null;
            object.endTime = null;
            object.removing = false;
        }
        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
            object.dayOfWeek = message.dayOfWeek;
        if (message.startTime != null && message.hasOwnProperty("startTime"))
            object.startTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.startTime, options);
        if (message.endTime != null && message.hasOwnProperty("endTime"))
            object.endTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.endTime, options);
        if (message.removing != null && message.hasOwnProperty("removing"))
            object.removing = message.removing;
        return object;
    };

    /**
     * Converts this UpdateAvailabilityRequest to JSON.
     * @function toJSON
     * @memberof UpdateAvailabilityRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateAvailabilityRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateAvailabilityRequest
     * @function getTypeUrl
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateAvailabilityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateAvailabilityRequest";
    };

    return UpdateAvailabilityRequest;
})();

$root.UpdateAvailabilityResponse = (function() {

    /**
     * Properties of an UpdateAvailabilityResponse.
     * @exports IUpdateAvailabilityResponse
     * @interface IUpdateAvailabilityResponse
     * @property {boolean|null} [okay] UpdateAvailabilityResponse okay
     * @property {string|null} [error] UpdateAvailabilityResponse error
     * @property {IApiBusinessProfileProto|null} [newProfile] UpdateAvailabilityResponse newProfile
     */

    /**
     * Constructs a new UpdateAvailabilityResponse.
     * @exports UpdateAvailabilityResponse
     * @classdesc Represents an UpdateAvailabilityResponse.
     * @implements IUpdateAvailabilityResponse
     * @constructor
     * @param {IUpdateAvailabilityResponse=} [properties] Properties to set
     */
    function UpdateAvailabilityResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateAvailabilityResponse okay.
     * @member {boolean} okay
     * @memberof UpdateAvailabilityResponse
     * @instance
     */
    UpdateAvailabilityResponse.prototype.okay = false;

    /**
     * UpdateAvailabilityResponse error.
     * @member {string} error
     * @memberof UpdateAvailabilityResponse
     * @instance
     */
    UpdateAvailabilityResponse.prototype.error = "";

    /**
     * UpdateAvailabilityResponse newProfile.
     * @member {IApiBusinessProfileProto|null|undefined} newProfile
     * @memberof UpdateAvailabilityResponse
     * @instance
     */
    UpdateAvailabilityResponse.prototype.newProfile = null;

    /**
     * Creates a new UpdateAvailabilityResponse instance using the specified properties.
     * @function create
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {IUpdateAvailabilityResponse=} [properties] Properties to set
     * @returns {UpdateAvailabilityResponse} UpdateAvailabilityResponse instance
     */
    UpdateAvailabilityResponse.create = function create(properties) {
        return new UpdateAvailabilityResponse(properties);
    };

    /**
     * Encodes the specified UpdateAvailabilityResponse message. Does not implicitly {@link UpdateAvailabilityResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {IUpdateAvailabilityResponse} message UpdateAvailabilityResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAvailabilityResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ApiBusinessProfileProto.encode(message.newProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateAvailabilityResponse message, length delimited. Does not implicitly {@link UpdateAvailabilityResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {IUpdateAvailabilityResponse} message UpdateAvailabilityResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAvailabilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateAvailabilityResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateAvailabilityResponse} UpdateAvailabilityResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAvailabilityResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateAvailabilityResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.error = reader.string();
                    break;
                }
            case 3: {
                    message.newProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateAvailabilityResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateAvailabilityResponse} UpdateAvailabilityResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAvailabilityResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateAvailabilityResponse message.
     * @function verify
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateAvailabilityResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateAvailabilityResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateAvailabilityResponse} UpdateAvailabilityResponse
     */
    UpdateAvailabilityResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateAvailabilityResponse)
            return object;
        var message = new $root.UpdateAvailabilityResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.error != null)
            message.error = String(object.error);
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".UpdateAvailabilityResponse.newProfile: object expected");
            message.newProfile = $root.ApiBusinessProfileProto.fromObject(object.newProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateAvailabilityResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {UpdateAvailabilityResponse} message UpdateAvailabilityResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateAvailabilityResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.error = "";
            object.newProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ApiBusinessProfileProto.toObject(message.newProfile, options);
        return object;
    };

    /**
     * Converts this UpdateAvailabilityResponse to JSON.
     * @function toJSON
     * @memberof UpdateAvailabilityResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateAvailabilityResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateAvailabilityResponse
     * @function getTypeUrl
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateAvailabilityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateAvailabilityResponse";
    };

    return UpdateAvailabilityResponse;
})();

$root.GetWebsiteVersionsRequest = (function() {

    /**
     * Properties of a GetWebsiteVersionsRequest.
     * @exports IGetWebsiteVersionsRequest
     * @interface IGetWebsiteVersionsRequest
     */

    /**
     * Constructs a new GetWebsiteVersionsRequest.
     * @exports GetWebsiteVersionsRequest
     * @classdesc Represents a GetWebsiteVersionsRequest.
     * @implements IGetWebsiteVersionsRequest
     * @constructor
     * @param {IGetWebsiteVersionsRequest=} [properties] Properties to set
     */
    function GetWebsiteVersionsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetWebsiteVersionsRequest instance using the specified properties.
     * @function create
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {IGetWebsiteVersionsRequest=} [properties] Properties to set
     * @returns {GetWebsiteVersionsRequest} GetWebsiteVersionsRequest instance
     */
    GetWebsiteVersionsRequest.create = function create(properties) {
        return new GetWebsiteVersionsRequest(properties);
    };

    /**
     * Encodes the specified GetWebsiteVersionsRequest message. Does not implicitly {@link GetWebsiteVersionsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {IGetWebsiteVersionsRequest} message GetWebsiteVersionsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWebsiteVersionsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetWebsiteVersionsRequest message, length delimited. Does not implicitly {@link GetWebsiteVersionsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {IGetWebsiteVersionsRequest} message GetWebsiteVersionsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWebsiteVersionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWebsiteVersionsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetWebsiteVersionsRequest} GetWebsiteVersionsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWebsiteVersionsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetWebsiteVersionsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetWebsiteVersionsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetWebsiteVersionsRequest} GetWebsiteVersionsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWebsiteVersionsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWebsiteVersionsRequest message.
     * @function verify
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWebsiteVersionsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetWebsiteVersionsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetWebsiteVersionsRequest} GetWebsiteVersionsRequest
     */
    GetWebsiteVersionsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetWebsiteVersionsRequest)
            return object;
        return new $root.GetWebsiteVersionsRequest();
    };

    /**
     * Creates a plain object from a GetWebsiteVersionsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {GetWebsiteVersionsRequest} message GetWebsiteVersionsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWebsiteVersionsRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetWebsiteVersionsRequest to JSON.
     * @function toJSON
     * @memberof GetWebsiteVersionsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWebsiteVersionsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWebsiteVersionsRequest
     * @function getTypeUrl
     * @memberof GetWebsiteVersionsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWebsiteVersionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetWebsiteVersionsRequest";
    };

    return GetWebsiteVersionsRequest;
})();

$root.GetWebsiteVersionsResponse = (function() {

    /**
     * Properties of a GetWebsiteVersionsResponse.
     * @exports IGetWebsiteVersionsResponse
     * @interface IGetWebsiteVersionsResponse
     * @property {boolean|null} [okay] GetWebsiteVersionsResponse okay
     * @property {Array.<IApiBusinessProfileProto>|null} [profiles] GetWebsiteVersionsResponse profiles
     * @property {string|null} [liveVersion] GetWebsiteVersionsResponse liveVersion
     */

    /**
     * Constructs a new GetWebsiteVersionsResponse.
     * @exports GetWebsiteVersionsResponse
     * @classdesc Represents a GetWebsiteVersionsResponse.
     * @implements IGetWebsiteVersionsResponse
     * @constructor
     * @param {IGetWebsiteVersionsResponse=} [properties] Properties to set
     */
    function GetWebsiteVersionsResponse(properties) {
        this.profiles = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetWebsiteVersionsResponse okay.
     * @member {boolean} okay
     * @memberof GetWebsiteVersionsResponse
     * @instance
     */
    GetWebsiteVersionsResponse.prototype.okay = false;

    /**
     * GetWebsiteVersionsResponse profiles.
     * @member {Array.<IApiBusinessProfileProto>} profiles
     * @memberof GetWebsiteVersionsResponse
     * @instance
     */
    GetWebsiteVersionsResponse.prototype.profiles = $util.emptyArray;

    /**
     * GetWebsiteVersionsResponse liveVersion.
     * @member {string} liveVersion
     * @memberof GetWebsiteVersionsResponse
     * @instance
     */
    GetWebsiteVersionsResponse.prototype.liveVersion = "";

    /**
     * Creates a new GetWebsiteVersionsResponse instance using the specified properties.
     * @function create
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {IGetWebsiteVersionsResponse=} [properties] Properties to set
     * @returns {GetWebsiteVersionsResponse} GetWebsiteVersionsResponse instance
     */
    GetWebsiteVersionsResponse.create = function create(properties) {
        return new GetWebsiteVersionsResponse(properties);
    };

    /**
     * Encodes the specified GetWebsiteVersionsResponse message. Does not implicitly {@link GetWebsiteVersionsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {IGetWebsiteVersionsResponse} message GetWebsiteVersionsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWebsiteVersionsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profiles != null && message.profiles.length)
            for (var i = 0; i < message.profiles.length; ++i)
                $root.ApiBusinessProfileProto.encode(message.profiles[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.liveVersion != null && Object.hasOwnProperty.call(message, "liveVersion"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.liveVersion);
        return writer;
    };

    /**
     * Encodes the specified GetWebsiteVersionsResponse message, length delimited. Does not implicitly {@link GetWebsiteVersionsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {IGetWebsiteVersionsResponse} message GetWebsiteVersionsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWebsiteVersionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWebsiteVersionsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetWebsiteVersionsResponse} GetWebsiteVersionsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWebsiteVersionsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetWebsiteVersionsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.profiles && message.profiles.length))
                        message.profiles = [];
                    message.profiles.push($root.ApiBusinessProfileProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.liveVersion = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetWebsiteVersionsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetWebsiteVersionsResponse} GetWebsiteVersionsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWebsiteVersionsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWebsiteVersionsResponse message.
     * @function verify
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWebsiteVersionsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profiles != null && message.hasOwnProperty("profiles")) {
            if (!Array.isArray(message.profiles))
                return "profiles: array expected";
            for (var i = 0; i < message.profiles.length; ++i) {
                var error = $root.ApiBusinessProfileProto.verify(message.profiles[i]);
                if (error)
                    return "profiles." + error;
            }
        }
        if (message.liveVersion != null && message.hasOwnProperty("liveVersion"))
            if (!$util.isString(message.liveVersion))
                return "liveVersion: string expected";
        return null;
    };

    /**
     * Creates a GetWebsiteVersionsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetWebsiteVersionsResponse} GetWebsiteVersionsResponse
     */
    GetWebsiteVersionsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetWebsiteVersionsResponse)
            return object;
        var message = new $root.GetWebsiteVersionsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profiles) {
            if (!Array.isArray(object.profiles))
                throw TypeError(".GetWebsiteVersionsResponse.profiles: array expected");
            message.profiles = [];
            for (var i = 0; i < object.profiles.length; ++i) {
                if (typeof object.profiles[i] !== "object")
                    throw TypeError(".GetWebsiteVersionsResponse.profiles: object expected");
                message.profiles[i] = $root.ApiBusinessProfileProto.fromObject(object.profiles[i]);
            }
        }
        if (object.liveVersion != null)
            message.liveVersion = String(object.liveVersion);
        return message;
    };

    /**
     * Creates a plain object from a GetWebsiteVersionsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {GetWebsiteVersionsResponse} message GetWebsiteVersionsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWebsiteVersionsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.profiles = [];
        if (options.defaults) {
            object.okay = false;
            object.liveVersion = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profiles && message.profiles.length) {
            object.profiles = [];
            for (var j = 0; j < message.profiles.length; ++j)
                object.profiles[j] = $root.ApiBusinessProfileProto.toObject(message.profiles[j], options);
        }
        if (message.liveVersion != null && message.hasOwnProperty("liveVersion"))
            object.liveVersion = message.liveVersion;
        return object;
    };

    /**
     * Converts this GetWebsiteVersionsResponse to JSON.
     * @function toJSON
     * @memberof GetWebsiteVersionsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWebsiteVersionsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWebsiteVersionsResponse
     * @function getTypeUrl
     * @memberof GetWebsiteVersionsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWebsiteVersionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetWebsiteVersionsResponse";
    };

    return GetWebsiteVersionsResponse;
})();

$root.SubmitProfileForReviewRequest = (function() {

    /**
     * Properties of a SubmitProfileForReviewRequest.
     * @exports ISubmitProfileForReviewRequest
     * @interface ISubmitProfileForReviewRequest
     */

    /**
     * Constructs a new SubmitProfileForReviewRequest.
     * @exports SubmitProfileForReviewRequest
     * @classdesc Represents a SubmitProfileForReviewRequest.
     * @implements ISubmitProfileForReviewRequest
     * @constructor
     * @param {ISubmitProfileForReviewRequest=} [properties] Properties to set
     */
    function SubmitProfileForReviewRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new SubmitProfileForReviewRequest instance using the specified properties.
     * @function create
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {ISubmitProfileForReviewRequest=} [properties] Properties to set
     * @returns {SubmitProfileForReviewRequest} SubmitProfileForReviewRequest instance
     */
    SubmitProfileForReviewRequest.create = function create(properties) {
        return new SubmitProfileForReviewRequest(properties);
    };

    /**
     * Encodes the specified SubmitProfileForReviewRequest message. Does not implicitly {@link SubmitProfileForReviewRequest.verify|verify} messages.
     * @function encode
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {ISubmitProfileForReviewRequest} message SubmitProfileForReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubmitProfileForReviewRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified SubmitProfileForReviewRequest message, length delimited. Does not implicitly {@link SubmitProfileForReviewRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {ISubmitProfileForReviewRequest} message SubmitProfileForReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubmitProfileForReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubmitProfileForReviewRequest message from the specified reader or buffer.
     * @function decode
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SubmitProfileForReviewRequest} SubmitProfileForReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubmitProfileForReviewRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubmitProfileForReviewRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SubmitProfileForReviewRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SubmitProfileForReviewRequest} SubmitProfileForReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubmitProfileForReviewRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubmitProfileForReviewRequest message.
     * @function verify
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubmitProfileForReviewRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a SubmitProfileForReviewRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SubmitProfileForReviewRequest} SubmitProfileForReviewRequest
     */
    SubmitProfileForReviewRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SubmitProfileForReviewRequest)
            return object;
        return new $root.SubmitProfileForReviewRequest();
    };

    /**
     * Creates a plain object from a SubmitProfileForReviewRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {SubmitProfileForReviewRequest} message SubmitProfileForReviewRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubmitProfileForReviewRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this SubmitProfileForReviewRequest to JSON.
     * @function toJSON
     * @memberof SubmitProfileForReviewRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubmitProfileForReviewRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubmitProfileForReviewRequest
     * @function getTypeUrl
     * @memberof SubmitProfileForReviewRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubmitProfileForReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubmitProfileForReviewRequest";
    };

    return SubmitProfileForReviewRequest;
})();

$root.SubmitProfileForReviewResponse = (function() {

    /**
     * Properties of a SubmitProfileForReviewResponse.
     * @exports ISubmitProfileForReviewResponse
     * @interface ISubmitProfileForReviewResponse
     * @property {boolean|null} [okay] SubmitProfileForReviewResponse okay
     * @property {string|null} [errors] SubmitProfileForReviewResponse errors
     * @property {IApiBusinessProfileProto|null} [newProfile] SubmitProfileForReviewResponse newProfile
     */

    /**
     * Constructs a new SubmitProfileForReviewResponse.
     * @exports SubmitProfileForReviewResponse
     * @classdesc Represents a SubmitProfileForReviewResponse.
     * @implements ISubmitProfileForReviewResponse
     * @constructor
     * @param {ISubmitProfileForReviewResponse=} [properties] Properties to set
     */
    function SubmitProfileForReviewResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SubmitProfileForReviewResponse okay.
     * @member {boolean} okay
     * @memberof SubmitProfileForReviewResponse
     * @instance
     */
    SubmitProfileForReviewResponse.prototype.okay = false;

    /**
     * SubmitProfileForReviewResponse errors.
     * @member {string} errors
     * @memberof SubmitProfileForReviewResponse
     * @instance
     */
    SubmitProfileForReviewResponse.prototype.errors = "";

    /**
     * SubmitProfileForReviewResponse newProfile.
     * @member {IApiBusinessProfileProto|null|undefined} newProfile
     * @memberof SubmitProfileForReviewResponse
     * @instance
     */
    SubmitProfileForReviewResponse.prototype.newProfile = null;

    /**
     * Creates a new SubmitProfileForReviewResponse instance using the specified properties.
     * @function create
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {ISubmitProfileForReviewResponse=} [properties] Properties to set
     * @returns {SubmitProfileForReviewResponse} SubmitProfileForReviewResponse instance
     */
    SubmitProfileForReviewResponse.create = function create(properties) {
        return new SubmitProfileForReviewResponse(properties);
    };

    /**
     * Encodes the specified SubmitProfileForReviewResponse message. Does not implicitly {@link SubmitProfileForReviewResponse.verify|verify} messages.
     * @function encode
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {ISubmitProfileForReviewResponse} message SubmitProfileForReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubmitProfileForReviewResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.errors != null && Object.hasOwnProperty.call(message, "errors"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.errors);
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ApiBusinessProfileProto.encode(message.newProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified SubmitProfileForReviewResponse message, length delimited. Does not implicitly {@link SubmitProfileForReviewResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {ISubmitProfileForReviewResponse} message SubmitProfileForReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubmitProfileForReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubmitProfileForReviewResponse message from the specified reader or buffer.
     * @function decode
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SubmitProfileForReviewResponse} SubmitProfileForReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubmitProfileForReviewResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubmitProfileForReviewResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.errors = reader.string();
                    break;
                }
            case 3: {
                    message.newProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SubmitProfileForReviewResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SubmitProfileForReviewResponse} SubmitProfileForReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubmitProfileForReviewResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubmitProfileForReviewResponse message.
     * @function verify
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubmitProfileForReviewResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.errors != null && message.hasOwnProperty("errors"))
            if (!$util.isString(message.errors))
                return "errors: string expected";
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        return null;
    };

    /**
     * Creates a SubmitProfileForReviewResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SubmitProfileForReviewResponse} SubmitProfileForReviewResponse
     */
    SubmitProfileForReviewResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.SubmitProfileForReviewResponse)
            return object;
        var message = new $root.SubmitProfileForReviewResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.errors != null)
            message.errors = String(object.errors);
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".SubmitProfileForReviewResponse.newProfile: object expected");
            message.newProfile = $root.ApiBusinessProfileProto.fromObject(object.newProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a SubmitProfileForReviewResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {SubmitProfileForReviewResponse} message SubmitProfileForReviewResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubmitProfileForReviewResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.errors = "";
            object.newProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.errors != null && message.hasOwnProperty("errors"))
            object.errors = message.errors;
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ApiBusinessProfileProto.toObject(message.newProfile, options);
        return object;
    };

    /**
     * Converts this SubmitProfileForReviewResponse to JSON.
     * @function toJSON
     * @memberof SubmitProfileForReviewResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubmitProfileForReviewResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubmitProfileForReviewResponse
     * @function getTypeUrl
     * @memberof SubmitProfileForReviewResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubmitProfileForReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubmitProfileForReviewResponse";
    };

    return SubmitProfileForReviewResponse;
})();

$root.SetLiveVersionRequest = (function() {

    /**
     * Properties of a SetLiveVersionRequest.
     * @exports ISetLiveVersionRequest
     * @interface ISetLiveVersionRequest
     * @property {string|null} [version] SetLiveVersionRequest version
     */

    /**
     * Constructs a new SetLiveVersionRequest.
     * @exports SetLiveVersionRequest
     * @classdesc Represents a SetLiveVersionRequest.
     * @implements ISetLiveVersionRequest
     * @constructor
     * @param {ISetLiveVersionRequest=} [properties] Properties to set
     */
    function SetLiveVersionRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetLiveVersionRequest version.
     * @member {string} version
     * @memberof SetLiveVersionRequest
     * @instance
     */
    SetLiveVersionRequest.prototype.version = "";

    /**
     * Creates a new SetLiveVersionRequest instance using the specified properties.
     * @function create
     * @memberof SetLiveVersionRequest
     * @static
     * @param {ISetLiveVersionRequest=} [properties] Properties to set
     * @returns {SetLiveVersionRequest} SetLiveVersionRequest instance
     */
    SetLiveVersionRequest.create = function create(properties) {
        return new SetLiveVersionRequest(properties);
    };

    /**
     * Encodes the specified SetLiveVersionRequest message. Does not implicitly {@link SetLiveVersionRequest.verify|verify} messages.
     * @function encode
     * @memberof SetLiveVersionRequest
     * @static
     * @param {ISetLiveVersionRequest} message SetLiveVersionRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLiveVersionRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.version);
        return writer;
    };

    /**
     * Encodes the specified SetLiveVersionRequest message, length delimited. Does not implicitly {@link SetLiveVersionRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SetLiveVersionRequest
     * @static
     * @param {ISetLiveVersionRequest} message SetLiveVersionRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLiveVersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetLiveVersionRequest message from the specified reader or buffer.
     * @function decode
     * @memberof SetLiveVersionRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SetLiveVersionRequest} SetLiveVersionRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLiveVersionRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SetLiveVersionRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.version = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SetLiveVersionRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SetLiveVersionRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SetLiveVersionRequest} SetLiveVersionRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLiveVersionRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetLiveVersionRequest message.
     * @function verify
     * @memberof SetLiveVersionRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetLiveVersionRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isString(message.version))
                return "version: string expected";
        return null;
    };

    /**
     * Creates a SetLiveVersionRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SetLiveVersionRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SetLiveVersionRequest} SetLiveVersionRequest
     */
    SetLiveVersionRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SetLiveVersionRequest)
            return object;
        var message = new $root.SetLiveVersionRequest();
        if (object.version != null)
            message.version = String(object.version);
        return message;
    };

    /**
     * Creates a plain object from a SetLiveVersionRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SetLiveVersionRequest
     * @static
     * @param {SetLiveVersionRequest} message SetLiveVersionRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetLiveVersionRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.version = "";
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        return object;
    };

    /**
     * Converts this SetLiveVersionRequest to JSON.
     * @function toJSON
     * @memberof SetLiveVersionRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetLiveVersionRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetLiveVersionRequest
     * @function getTypeUrl
     * @memberof SetLiveVersionRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetLiveVersionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SetLiveVersionRequest";
    };

    return SetLiveVersionRequest;
})();

$root.SetLiveVersionResponse = (function() {

    /**
     * Properties of a SetLiveVersionResponse.
     * @exports ISetLiveVersionResponse
     * @interface ISetLiveVersionResponse
     * @property {boolean|null} [okay] SetLiveVersionResponse okay
     * @property {string|null} [liveVersion] SetLiveVersionResponse liveVersion
     * @property {string|null} [error] SetLiveVersionResponse error
     */

    /**
     * Constructs a new SetLiveVersionResponse.
     * @exports SetLiveVersionResponse
     * @classdesc Represents a SetLiveVersionResponse.
     * @implements ISetLiveVersionResponse
     * @constructor
     * @param {ISetLiveVersionResponse=} [properties] Properties to set
     */
    function SetLiveVersionResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetLiveVersionResponse okay.
     * @member {boolean} okay
     * @memberof SetLiveVersionResponse
     * @instance
     */
    SetLiveVersionResponse.prototype.okay = false;

    /**
     * SetLiveVersionResponse liveVersion.
     * @member {string} liveVersion
     * @memberof SetLiveVersionResponse
     * @instance
     */
    SetLiveVersionResponse.prototype.liveVersion = "";

    /**
     * SetLiveVersionResponse error.
     * @member {string} error
     * @memberof SetLiveVersionResponse
     * @instance
     */
    SetLiveVersionResponse.prototype.error = "";

    /**
     * Creates a new SetLiveVersionResponse instance using the specified properties.
     * @function create
     * @memberof SetLiveVersionResponse
     * @static
     * @param {ISetLiveVersionResponse=} [properties] Properties to set
     * @returns {SetLiveVersionResponse} SetLiveVersionResponse instance
     */
    SetLiveVersionResponse.create = function create(properties) {
        return new SetLiveVersionResponse(properties);
    };

    /**
     * Encodes the specified SetLiveVersionResponse message. Does not implicitly {@link SetLiveVersionResponse.verify|verify} messages.
     * @function encode
     * @memberof SetLiveVersionResponse
     * @static
     * @param {ISetLiveVersionResponse} message SetLiveVersionResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLiveVersionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.liveVersion != null && Object.hasOwnProperty.call(message, "liveVersion"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.liveVersion);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.error);
        return writer;
    };

    /**
     * Encodes the specified SetLiveVersionResponse message, length delimited. Does not implicitly {@link SetLiveVersionResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SetLiveVersionResponse
     * @static
     * @param {ISetLiveVersionResponse} message SetLiveVersionResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLiveVersionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetLiveVersionResponse message from the specified reader or buffer.
     * @function decode
     * @memberof SetLiveVersionResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SetLiveVersionResponse} SetLiveVersionResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLiveVersionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SetLiveVersionResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.liveVersion = reader.string();
                    break;
                }
            case 3: {
                    message.error = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SetLiveVersionResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SetLiveVersionResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SetLiveVersionResponse} SetLiveVersionResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLiveVersionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetLiveVersionResponse message.
     * @function verify
     * @memberof SetLiveVersionResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetLiveVersionResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.liveVersion != null && message.hasOwnProperty("liveVersion"))
            if (!$util.isString(message.liveVersion))
                return "liveVersion: string expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        return null;
    };

    /**
     * Creates a SetLiveVersionResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SetLiveVersionResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SetLiveVersionResponse} SetLiveVersionResponse
     */
    SetLiveVersionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.SetLiveVersionResponse)
            return object;
        var message = new $root.SetLiveVersionResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.liveVersion != null)
            message.liveVersion = String(object.liveVersion);
        if (object.error != null)
            message.error = String(object.error);
        return message;
    };

    /**
     * Creates a plain object from a SetLiveVersionResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SetLiveVersionResponse
     * @static
     * @param {SetLiveVersionResponse} message SetLiveVersionResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetLiveVersionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.liveVersion = "";
            object.error = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.liveVersion != null && message.hasOwnProperty("liveVersion"))
            object.liveVersion = message.liveVersion;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        return object;
    };

    /**
     * Converts this SetLiveVersionResponse to JSON.
     * @function toJSON
     * @memberof SetLiveVersionResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetLiveVersionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetLiveVersionResponse
     * @function getTypeUrl
     * @memberof SetLiveVersionResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetLiveVersionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SetLiveVersionResponse";
    };

    return SetLiveVersionResponse;
})();

$root.GetLoginsForBusinessRequest = (function() {

    /**
     * Properties of a GetLoginsForBusinessRequest.
     * @exports IGetLoginsForBusinessRequest
     * @interface IGetLoginsForBusinessRequest
     * @property {Array.<string>|null} [providerIds] GetLoginsForBusinessRequest providerIds
     */

    /**
     * Constructs a new GetLoginsForBusinessRequest.
     * @exports GetLoginsForBusinessRequest
     * @classdesc Represents a GetLoginsForBusinessRequest.
     * @implements IGetLoginsForBusinessRequest
     * @constructor
     * @param {IGetLoginsForBusinessRequest=} [properties] Properties to set
     */
    function GetLoginsForBusinessRequest(properties) {
        this.providerIds = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetLoginsForBusinessRequest providerIds.
     * @member {Array.<string>} providerIds
     * @memberof GetLoginsForBusinessRequest
     * @instance
     */
    GetLoginsForBusinessRequest.prototype.providerIds = $util.emptyArray;

    /**
     * Creates a new GetLoginsForBusinessRequest instance using the specified properties.
     * @function create
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {IGetLoginsForBusinessRequest=} [properties] Properties to set
     * @returns {GetLoginsForBusinessRequest} GetLoginsForBusinessRequest instance
     */
    GetLoginsForBusinessRequest.create = function create(properties) {
        return new GetLoginsForBusinessRequest(properties);
    };

    /**
     * Encodes the specified GetLoginsForBusinessRequest message. Does not implicitly {@link GetLoginsForBusinessRequest.verify|verify} messages.
     * @function encode
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {IGetLoginsForBusinessRequest} message GetLoginsForBusinessRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetLoginsForBusinessRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerIds != null && message.providerIds.length)
            for (var i = 0; i < message.providerIds.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerIds[i]);
        return writer;
    };

    /**
     * Encodes the specified GetLoginsForBusinessRequest message, length delimited. Does not implicitly {@link GetLoginsForBusinessRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {IGetLoginsForBusinessRequest} message GetLoginsForBusinessRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetLoginsForBusinessRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetLoginsForBusinessRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetLoginsForBusinessRequest} GetLoginsForBusinessRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetLoginsForBusinessRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetLoginsForBusinessRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.providerIds && message.providerIds.length))
                        message.providerIds = [];
                    message.providerIds.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetLoginsForBusinessRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetLoginsForBusinessRequest} GetLoginsForBusinessRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetLoginsForBusinessRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetLoginsForBusinessRequest message.
     * @function verify
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetLoginsForBusinessRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerIds != null && message.hasOwnProperty("providerIds")) {
            if (!Array.isArray(message.providerIds))
                return "providerIds: array expected";
            for (var i = 0; i < message.providerIds.length; ++i)
                if (!$util.isString(message.providerIds[i]))
                    return "providerIds: string[] expected";
        }
        return null;
    };

    /**
     * Creates a GetLoginsForBusinessRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetLoginsForBusinessRequest} GetLoginsForBusinessRequest
     */
    GetLoginsForBusinessRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetLoginsForBusinessRequest)
            return object;
        var message = new $root.GetLoginsForBusinessRequest();
        if (object.providerIds) {
            if (!Array.isArray(object.providerIds))
                throw TypeError(".GetLoginsForBusinessRequest.providerIds: array expected");
            message.providerIds = [];
            for (var i = 0; i < object.providerIds.length; ++i)
                message.providerIds[i] = String(object.providerIds[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetLoginsForBusinessRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {GetLoginsForBusinessRequest} message GetLoginsForBusinessRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetLoginsForBusinessRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.providerIds = [];
        if (message.providerIds && message.providerIds.length) {
            object.providerIds = [];
            for (var j = 0; j < message.providerIds.length; ++j)
                object.providerIds[j] = message.providerIds[j];
        }
        return object;
    };

    /**
     * Converts this GetLoginsForBusinessRequest to JSON.
     * @function toJSON
     * @memberof GetLoginsForBusinessRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetLoginsForBusinessRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetLoginsForBusinessRequest
     * @function getTypeUrl
     * @memberof GetLoginsForBusinessRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetLoginsForBusinessRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetLoginsForBusinessRequest";
    };

    return GetLoginsForBusinessRequest;
})();

$root.GetLoginsForBusinessResponse = (function() {

    /**
     * Properties of a GetLoginsForBusinessResponse.
     * @exports IGetLoginsForBusinessResponse
     * @interface IGetLoginsForBusinessResponse
     * @property {boolean|null} [okay] GetLoginsForBusinessResponse okay
     * @property {Array.<IApiProviderLoginProto>|null} [provider] GetLoginsForBusinessResponse provider
     */

    /**
     * Constructs a new GetLoginsForBusinessResponse.
     * @exports GetLoginsForBusinessResponse
     * @classdesc Represents a GetLoginsForBusinessResponse.
     * @implements IGetLoginsForBusinessResponse
     * @constructor
     * @param {IGetLoginsForBusinessResponse=} [properties] Properties to set
     */
    function GetLoginsForBusinessResponse(properties) {
        this.provider = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetLoginsForBusinessResponse okay.
     * @member {boolean} okay
     * @memberof GetLoginsForBusinessResponse
     * @instance
     */
    GetLoginsForBusinessResponse.prototype.okay = false;

    /**
     * GetLoginsForBusinessResponse provider.
     * @member {Array.<IApiProviderLoginProto>} provider
     * @memberof GetLoginsForBusinessResponse
     * @instance
     */
    GetLoginsForBusinessResponse.prototype.provider = $util.emptyArray;

    /**
     * Creates a new GetLoginsForBusinessResponse instance using the specified properties.
     * @function create
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {IGetLoginsForBusinessResponse=} [properties] Properties to set
     * @returns {GetLoginsForBusinessResponse} GetLoginsForBusinessResponse instance
     */
    GetLoginsForBusinessResponse.create = function create(properties) {
        return new GetLoginsForBusinessResponse(properties);
    };

    /**
     * Encodes the specified GetLoginsForBusinessResponse message. Does not implicitly {@link GetLoginsForBusinessResponse.verify|verify} messages.
     * @function encode
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {IGetLoginsForBusinessResponse} message GetLoginsForBusinessResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetLoginsForBusinessResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && message.provider.length)
            for (var i = 0; i < message.provider.length; ++i)
                $root.ApiProviderLoginProto.encode(message.provider[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetLoginsForBusinessResponse message, length delimited. Does not implicitly {@link GetLoginsForBusinessResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {IGetLoginsForBusinessResponse} message GetLoginsForBusinessResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetLoginsForBusinessResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetLoginsForBusinessResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetLoginsForBusinessResponse} GetLoginsForBusinessResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetLoginsForBusinessResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetLoginsForBusinessResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.provider && message.provider.length))
                        message.provider = [];
                    message.provider.push($root.ApiProviderLoginProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetLoginsForBusinessResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetLoginsForBusinessResponse} GetLoginsForBusinessResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetLoginsForBusinessResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetLoginsForBusinessResponse message.
     * @function verify
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetLoginsForBusinessResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            if (!Array.isArray(message.provider))
                return "provider: array expected";
            for (var i = 0; i < message.provider.length; ++i) {
                var error = $root.ApiProviderLoginProto.verify(message.provider[i]);
                if (error)
                    return "provider." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetLoginsForBusinessResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetLoginsForBusinessResponse} GetLoginsForBusinessResponse
     */
    GetLoginsForBusinessResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetLoginsForBusinessResponse)
            return object;
        var message = new $root.GetLoginsForBusinessResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider) {
            if (!Array.isArray(object.provider))
                throw TypeError(".GetLoginsForBusinessResponse.provider: array expected");
            message.provider = [];
            for (var i = 0; i < object.provider.length; ++i) {
                if (typeof object.provider[i] !== "object")
                    throw TypeError(".GetLoginsForBusinessResponse.provider: object expected");
                message.provider[i] = $root.ApiProviderLoginProto.fromObject(object.provider[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetLoginsForBusinessResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {GetLoginsForBusinessResponse} message GetLoginsForBusinessResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetLoginsForBusinessResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.provider = [];
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider && message.provider.length) {
            object.provider = [];
            for (var j = 0; j < message.provider.length; ++j)
                object.provider[j] = $root.ApiProviderLoginProto.toObject(message.provider[j], options);
        }
        return object;
    };

    /**
     * Converts this GetLoginsForBusinessResponse to JSON.
     * @function toJSON
     * @memberof GetLoginsForBusinessResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetLoginsForBusinessResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetLoginsForBusinessResponse
     * @function getTypeUrl
     * @memberof GetLoginsForBusinessResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetLoginsForBusinessResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetLoginsForBusinessResponse";
    };

    return GetLoginsForBusinessResponse;
})();

$root.AcceptLoginInviteRequest = (function() {

    /**
     * Properties of an AcceptLoginInviteRequest.
     * @exports IAcceptLoginInviteRequest
     * @interface IAcceptLoginInviteRequest
     * @property {string|null} [providerId] AcceptLoginInviteRequest providerId
     * @property {string|null} [inviteCode] AcceptLoginInviteRequest inviteCode
     */

    /**
     * Constructs a new AcceptLoginInviteRequest.
     * @exports AcceptLoginInviteRequest
     * @classdesc Represents an AcceptLoginInviteRequest.
     * @implements IAcceptLoginInviteRequest
     * @constructor
     * @param {IAcceptLoginInviteRequest=} [properties] Properties to set
     */
    function AcceptLoginInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptLoginInviteRequest providerId.
     * @member {string} providerId
     * @memberof AcceptLoginInviteRequest
     * @instance
     */
    AcceptLoginInviteRequest.prototype.providerId = "";

    /**
     * AcceptLoginInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof AcceptLoginInviteRequest
     * @instance
     */
    AcceptLoginInviteRequest.prototype.inviteCode = "";

    /**
     * Creates a new AcceptLoginInviteRequest instance using the specified properties.
     * @function create
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {IAcceptLoginInviteRequest=} [properties] Properties to set
     * @returns {AcceptLoginInviteRequest} AcceptLoginInviteRequest instance
     */
    AcceptLoginInviteRequest.create = function create(properties) {
        return new AcceptLoginInviteRequest(properties);
    };

    /**
     * Encodes the specified AcceptLoginInviteRequest message. Does not implicitly {@link AcceptLoginInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {IAcceptLoginInviteRequest} message AcceptLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptLoginInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified AcceptLoginInviteRequest message, length delimited. Does not implicitly {@link AcceptLoginInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {IAcceptLoginInviteRequest} message AcceptLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptLoginInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptLoginInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptLoginInviteRequest} AcceptLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptLoginInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptLoginInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptLoginInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptLoginInviteRequest} AcceptLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptLoginInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptLoginInviteRequest message.
     * @function verify
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptLoginInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates an AcceptLoginInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptLoginInviteRequest} AcceptLoginInviteRequest
     */
    AcceptLoginInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptLoginInviteRequest)
            return object;
        var message = new $root.AcceptLoginInviteRequest();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from an AcceptLoginInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {AcceptLoginInviteRequest} message AcceptLoginInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptLoginInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.providerId = "";
            object.inviteCode = "";
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this AcceptLoginInviteRequest to JSON.
     * @function toJSON
     * @memberof AcceptLoginInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptLoginInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptLoginInviteRequest
     * @function getTypeUrl
     * @memberof AcceptLoginInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptLoginInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptLoginInviteRequest";
    };

    return AcceptLoginInviteRequest;
})();

$root.AcceptLoginInviteResponse = (function() {

    /**
     * Properties of an AcceptLoginInviteResponse.
     * @exports IAcceptLoginInviteResponse
     * @interface IAcceptLoginInviteResponse
     * @property {boolean|null} [okay] AcceptLoginInviteResponse okay
     * @property {IApiProviderLoginProto|null} [provider] AcceptLoginInviteResponse provider
     * @property {IApiBusinessProfileProto|null} [businessProfile] AcceptLoginInviteResponse businessProfile
     */

    /**
     * Constructs a new AcceptLoginInviteResponse.
     * @exports AcceptLoginInviteResponse
     * @classdesc Represents an AcceptLoginInviteResponse.
     * @implements IAcceptLoginInviteResponse
     * @constructor
     * @param {IAcceptLoginInviteResponse=} [properties] Properties to set
     */
    function AcceptLoginInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptLoginInviteResponse okay.
     * @member {boolean} okay
     * @memberof AcceptLoginInviteResponse
     * @instance
     */
    AcceptLoginInviteResponse.prototype.okay = false;

    /**
     * AcceptLoginInviteResponse provider.
     * @member {IApiProviderLoginProto|null|undefined} provider
     * @memberof AcceptLoginInviteResponse
     * @instance
     */
    AcceptLoginInviteResponse.prototype.provider = null;

    /**
     * AcceptLoginInviteResponse businessProfile.
     * @member {IApiBusinessProfileProto|null|undefined} businessProfile
     * @memberof AcceptLoginInviteResponse
     * @instance
     */
    AcceptLoginInviteResponse.prototype.businessProfile = null;

    /**
     * Creates a new AcceptLoginInviteResponse instance using the specified properties.
     * @function create
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {IAcceptLoginInviteResponse=} [properties] Properties to set
     * @returns {AcceptLoginInviteResponse} AcceptLoginInviteResponse instance
     */
    AcceptLoginInviteResponse.create = function create(properties) {
        return new AcceptLoginInviteResponse(properties);
    };

    /**
     * Encodes the specified AcceptLoginInviteResponse message. Does not implicitly {@link AcceptLoginInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {IAcceptLoginInviteResponse} message AcceptLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptLoginInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ApiProviderLoginProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.businessProfile != null && Object.hasOwnProperty.call(message, "businessProfile"))
            $root.ApiBusinessProfileProto.encode(message.businessProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AcceptLoginInviteResponse message, length delimited. Does not implicitly {@link AcceptLoginInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {IAcceptLoginInviteResponse} message AcceptLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptLoginInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptLoginInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptLoginInviteResponse} AcceptLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptLoginInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptLoginInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.provider = $root.ApiProviderLoginProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.businessProfile = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptLoginInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptLoginInviteResponse} AcceptLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptLoginInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptLoginInviteResponse message.
     * @function verify
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptLoginInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ApiProviderLoginProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile")) {
            var error = $root.ApiBusinessProfileProto.verify(message.businessProfile);
            if (error)
                return "businessProfile." + error;
        }
        return null;
    };

    /**
     * Creates an AcceptLoginInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptLoginInviteResponse} AcceptLoginInviteResponse
     */
    AcceptLoginInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptLoginInviteResponse)
            return object;
        var message = new $root.AcceptLoginInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".AcceptLoginInviteResponse.provider: object expected");
            message.provider = $root.ApiProviderLoginProto.fromObject(object.provider);
        }
        if (object.businessProfile != null) {
            if (typeof object.businessProfile !== "object")
                throw TypeError(".AcceptLoginInviteResponse.businessProfile: object expected");
            message.businessProfile = $root.ApiBusinessProfileProto.fromObject(object.businessProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from an AcceptLoginInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {AcceptLoginInviteResponse} message AcceptLoginInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptLoginInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
            object.businessProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ApiProviderLoginProto.toObject(message.provider, options);
        if (message.businessProfile != null && message.hasOwnProperty("businessProfile"))
            object.businessProfile = $root.ApiBusinessProfileProto.toObject(message.businessProfile, options);
        return object;
    };

    /**
     * Converts this AcceptLoginInviteResponse to JSON.
     * @function toJSON
     * @memberof AcceptLoginInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptLoginInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptLoginInviteResponse
     * @function getTypeUrl
     * @memberof AcceptLoginInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptLoginInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptLoginInviteResponse";
    };

    return AcceptLoginInviteResponse;
})();

$root.CheckLoginInviteRequest = (function() {

    /**
     * Properties of a CheckLoginInviteRequest.
     * @exports ICheckLoginInviteRequest
     * @interface ICheckLoginInviteRequest
     * @property {string|null} [providerId] CheckLoginInviteRequest providerId
     * @property {string|null} [inviteCode] CheckLoginInviteRequest inviteCode
     */

    /**
     * Constructs a new CheckLoginInviteRequest.
     * @exports CheckLoginInviteRequest
     * @classdesc Represents a CheckLoginInviteRequest.
     * @implements ICheckLoginInviteRequest
     * @constructor
     * @param {ICheckLoginInviteRequest=} [properties] Properties to set
     */
    function CheckLoginInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckLoginInviteRequest providerId.
     * @member {string} providerId
     * @memberof CheckLoginInviteRequest
     * @instance
     */
    CheckLoginInviteRequest.prototype.providerId = "";

    /**
     * CheckLoginInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof CheckLoginInviteRequest
     * @instance
     */
    CheckLoginInviteRequest.prototype.inviteCode = "";

    /**
     * Creates a new CheckLoginInviteRequest instance using the specified properties.
     * @function create
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {ICheckLoginInviteRequest=} [properties] Properties to set
     * @returns {CheckLoginInviteRequest} CheckLoginInviteRequest instance
     */
    CheckLoginInviteRequest.create = function create(properties) {
        return new CheckLoginInviteRequest(properties);
    };

    /**
     * Encodes the specified CheckLoginInviteRequest message. Does not implicitly {@link CheckLoginInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {ICheckLoginInviteRequest} message CheckLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckLoginInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified CheckLoginInviteRequest message, length delimited. Does not implicitly {@link CheckLoginInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {ICheckLoginInviteRequest} message CheckLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckLoginInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckLoginInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckLoginInviteRequest} CheckLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckLoginInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckLoginInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckLoginInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckLoginInviteRequest} CheckLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckLoginInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckLoginInviteRequest message.
     * @function verify
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckLoginInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates a CheckLoginInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckLoginInviteRequest} CheckLoginInviteRequest
     */
    CheckLoginInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckLoginInviteRequest)
            return object;
        var message = new $root.CheckLoginInviteRequest();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from a CheckLoginInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {CheckLoginInviteRequest} message CheckLoginInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckLoginInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.providerId = "";
            object.inviteCode = "";
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this CheckLoginInviteRequest to JSON.
     * @function toJSON
     * @memberof CheckLoginInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckLoginInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckLoginInviteRequest
     * @function getTypeUrl
     * @memberof CheckLoginInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckLoginInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckLoginInviteRequest";
    };

    return CheckLoginInviteRequest;
})();

$root.CheckLoginInviteResponse = (function() {

    /**
     * Properties of a CheckLoginInviteResponse.
     * @exports ICheckLoginInviteResponse
     * @interface ICheckLoginInviteResponse
     * @property {boolean|null} [okay] CheckLoginInviteResponse okay
     * @property {string|null} [businessName] CheckLoginInviteResponse businessName
     */

    /**
     * Constructs a new CheckLoginInviteResponse.
     * @exports CheckLoginInviteResponse
     * @classdesc Represents a CheckLoginInviteResponse.
     * @implements ICheckLoginInviteResponse
     * @constructor
     * @param {ICheckLoginInviteResponse=} [properties] Properties to set
     */
    function CheckLoginInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckLoginInviteResponse okay.
     * @member {boolean} okay
     * @memberof CheckLoginInviteResponse
     * @instance
     */
    CheckLoginInviteResponse.prototype.okay = false;

    /**
     * CheckLoginInviteResponse businessName.
     * @member {string} businessName
     * @memberof CheckLoginInviteResponse
     * @instance
     */
    CheckLoginInviteResponse.prototype.businessName = "";

    /**
     * Creates a new CheckLoginInviteResponse instance using the specified properties.
     * @function create
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {ICheckLoginInviteResponse=} [properties] Properties to set
     * @returns {CheckLoginInviteResponse} CheckLoginInviteResponse instance
     */
    CheckLoginInviteResponse.create = function create(properties) {
        return new CheckLoginInviteResponse(properties);
    };

    /**
     * Encodes the specified CheckLoginInviteResponse message. Does not implicitly {@link CheckLoginInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {ICheckLoginInviteResponse} message CheckLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckLoginInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessName);
        return writer;
    };

    /**
     * Encodes the specified CheckLoginInviteResponse message, length delimited. Does not implicitly {@link CheckLoginInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {ICheckLoginInviteResponse} message CheckLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckLoginInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckLoginInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckLoginInviteResponse} CheckLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckLoginInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckLoginInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.businessName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckLoginInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckLoginInviteResponse} CheckLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckLoginInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckLoginInviteResponse message.
     * @function verify
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckLoginInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.businessName != null && message.hasOwnProperty("businessName"))
            if (!$util.isString(message.businessName))
                return "businessName: string expected";
        return null;
    };

    /**
     * Creates a CheckLoginInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckLoginInviteResponse} CheckLoginInviteResponse
     */
    CheckLoginInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckLoginInviteResponse)
            return object;
        var message = new $root.CheckLoginInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.businessName != null)
            message.businessName = String(object.businessName);
        return message;
    };

    /**
     * Creates a plain object from a CheckLoginInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {CheckLoginInviteResponse} message CheckLoginInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckLoginInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.businessName = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.businessName != null && message.hasOwnProperty("businessName"))
            object.businessName = message.businessName;
        return object;
    };

    /**
     * Converts this CheckLoginInviteResponse to JSON.
     * @function toJSON
     * @memberof CheckLoginInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckLoginInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckLoginInviteResponse
     * @function getTypeUrl
     * @memberof CheckLoginInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckLoginInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckLoginInviteResponse";
    };

    return CheckLoginInviteResponse;
})();

$root.GenerateLoginInviteRequest = (function() {

    /**
     * Properties of a GenerateLoginInviteRequest.
     * @exports IGenerateLoginInviteRequest
     * @interface IGenerateLoginInviteRequest
     * @property {string|null} [providerId] GenerateLoginInviteRequest providerId
     */

    /**
     * Constructs a new GenerateLoginInviteRequest.
     * @exports GenerateLoginInviteRequest
     * @classdesc Represents a GenerateLoginInviteRequest.
     * @implements IGenerateLoginInviteRequest
     * @constructor
     * @param {IGenerateLoginInviteRequest=} [properties] Properties to set
     */
    function GenerateLoginInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateLoginInviteRequest providerId.
     * @member {string} providerId
     * @memberof GenerateLoginInviteRequest
     * @instance
     */
    GenerateLoginInviteRequest.prototype.providerId = "";

    /**
     * Creates a new GenerateLoginInviteRequest instance using the specified properties.
     * @function create
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {IGenerateLoginInviteRequest=} [properties] Properties to set
     * @returns {GenerateLoginInviteRequest} GenerateLoginInviteRequest instance
     */
    GenerateLoginInviteRequest.create = function create(properties) {
        return new GenerateLoginInviteRequest(properties);
    };

    /**
     * Encodes the specified GenerateLoginInviteRequest message. Does not implicitly {@link GenerateLoginInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {IGenerateLoginInviteRequest} message GenerateLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateLoginInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        return writer;
    };

    /**
     * Encodes the specified GenerateLoginInviteRequest message, length delimited. Does not implicitly {@link GenerateLoginInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {IGenerateLoginInviteRequest} message GenerateLoginInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateLoginInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateLoginInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateLoginInviteRequest} GenerateLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateLoginInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateLoginInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateLoginInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateLoginInviteRequest} GenerateLoginInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateLoginInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateLoginInviteRequest message.
     * @function verify
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateLoginInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        return null;
    };

    /**
     * Creates a GenerateLoginInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateLoginInviteRequest} GenerateLoginInviteRequest
     */
    GenerateLoginInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateLoginInviteRequest)
            return object;
        var message = new $root.GenerateLoginInviteRequest();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        return message;
    };

    /**
     * Creates a plain object from a GenerateLoginInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {GenerateLoginInviteRequest} message GenerateLoginInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateLoginInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.providerId = "";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        return object;
    };

    /**
     * Converts this GenerateLoginInviteRequest to JSON.
     * @function toJSON
     * @memberof GenerateLoginInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateLoginInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateLoginInviteRequest
     * @function getTypeUrl
     * @memberof GenerateLoginInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateLoginInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateLoginInviteRequest";
    };

    return GenerateLoginInviteRequest;
})();

$root.GenerateLoginInviteResponse = (function() {

    /**
     * Properties of a GenerateLoginInviteResponse.
     * @exports IGenerateLoginInviteResponse
     * @interface IGenerateLoginInviteResponse
     * @property {boolean|null} [okay] GenerateLoginInviteResponse okay
     * @property {string|null} [inviteCode] GenerateLoginInviteResponse inviteCode
     */

    /**
     * Constructs a new GenerateLoginInviteResponse.
     * @exports GenerateLoginInviteResponse
     * @classdesc Represents a GenerateLoginInviteResponse.
     * @implements IGenerateLoginInviteResponse
     * @constructor
     * @param {IGenerateLoginInviteResponse=} [properties] Properties to set
     */
    function GenerateLoginInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateLoginInviteResponse okay.
     * @member {boolean} okay
     * @memberof GenerateLoginInviteResponse
     * @instance
     */
    GenerateLoginInviteResponse.prototype.okay = false;

    /**
     * GenerateLoginInviteResponse inviteCode.
     * @member {string} inviteCode
     * @memberof GenerateLoginInviteResponse
     * @instance
     */
    GenerateLoginInviteResponse.prototype.inviteCode = "";

    /**
     * Creates a new GenerateLoginInviteResponse instance using the specified properties.
     * @function create
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {IGenerateLoginInviteResponse=} [properties] Properties to set
     * @returns {GenerateLoginInviteResponse} GenerateLoginInviteResponse instance
     */
    GenerateLoginInviteResponse.create = function create(properties) {
        return new GenerateLoginInviteResponse(properties);
    };

    /**
     * Encodes the specified GenerateLoginInviteResponse message. Does not implicitly {@link GenerateLoginInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {IGenerateLoginInviteResponse} message GenerateLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateLoginInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified GenerateLoginInviteResponse message, length delimited. Does not implicitly {@link GenerateLoginInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {IGenerateLoginInviteResponse} message GenerateLoginInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateLoginInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateLoginInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateLoginInviteResponse} GenerateLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateLoginInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateLoginInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateLoginInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateLoginInviteResponse} GenerateLoginInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateLoginInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateLoginInviteResponse message.
     * @function verify
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateLoginInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates a GenerateLoginInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateLoginInviteResponse} GenerateLoginInviteResponse
     */
    GenerateLoginInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateLoginInviteResponse)
            return object;
        var message = new $root.GenerateLoginInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from a GenerateLoginInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {GenerateLoginInviteResponse} message GenerateLoginInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateLoginInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.inviteCode = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this GenerateLoginInviteResponse to JSON.
     * @function toJSON
     * @memberof GenerateLoginInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateLoginInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateLoginInviteResponse
     * @function getTypeUrl
     * @memberof GenerateLoginInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateLoginInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateLoginInviteResponse";
    };

    return GenerateLoginInviteResponse;
})();

$root.space = (function() {

    /**
     * Namespace space.
     * @exports space
     * @namespace
     */
    var space = {};

    space.kenko = (function() {

        /**
         * Namespace kenko.
         * @memberof space
         * @namespace
         */
        var kenko = {};

        kenko.proto = (function() {

            /**
             * Namespace proto.
             * @memberof space.kenko
             * @namespace
             */
            var proto = {};

            proto.LocalTimeProto = (function() {

                /**
                 * Properties of a LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @interface ILocalTimeProto
                 * @property {number|null} [hour] LocalTimeProto hour
                 * @property {number|null} [minute] LocalTimeProto minute
                 */

                /**
                 * Constructs a new LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LocalTimeProto.
                 * @implements ILocalTimeProto
                 * @constructor
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 */
                function LocalTimeProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalTimeProto hour.
                 * @member {number} hour
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.hour = 0;

                /**
                 * LocalTimeProto minute.
                 * @member {number} minute
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.minute = 0;

                /**
                 * Creates a new LocalTimeProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto instance
                 */
                LocalTimeProto.create = function create(properties) {
                    return new LocalTimeProto(properties);
                };

                /**
                 * Encodes the specified LocalTimeProto message. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hour);
                    if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minute);
                    return writer;
                };

                /**
                 * Encodes the specified LocalTimeProto message, length delimited. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LocalTimeProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hour = reader.int32();
                                break;
                            }
                        case 2: {
                                message.minute = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalTimeProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalTimeProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        if (!$util.isInteger(message.hour))
                            return "hour: integer expected";
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        if (!$util.isInteger(message.minute))
                            return "minute: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalTimeProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 */
                LocalTimeProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LocalTimeProto)
                        return object;
                    var message = new $root.space.kenko.proto.LocalTimeProto();
                    if (object.hour != null)
                        message.hour = object.hour | 0;
                    if (object.minute != null)
                        message.minute = object.minute | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalTimeProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.LocalTimeProto} message LocalTimeProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalTimeProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hour = 0;
                        object.minute = 0;
                    }
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        object.hour = message.hour;
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        object.minute = message.minute;
                    return object;
                };

                /**
                 * Converts this LocalTimeProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalTimeProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalTimeProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalTimeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LocalTimeProto";
                };

                return LocalTimeProto;
            })();

            proto.PriceProto = (function() {

                /**
                 * Properties of a PriceProto.
                 * @memberof space.kenko.proto
                 * @interface IPriceProto
                 * @property {number|null} [price] PriceProto price
                 * @property {string|null} [currencyCode] PriceProto currencyCode
                 */

                /**
                 * Constructs a new PriceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PriceProto.
                 * @implements IPriceProto
                 * @constructor
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 */
                function PriceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PriceProto price.
                 * @member {number} price
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.price = 0;

                /**
                 * PriceProto currencyCode.
                 * @member {string} currencyCode
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.currencyCode = "";

                /**
                 * Creates a new PriceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PriceProto} PriceProto instance
                 */
                PriceProto.create = function create(properties) {
                    return new PriceProto(properties);
                };

                /**
                 * Encodes the specified PriceProto message. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.price);
                    if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyCode);
                    return writer;
                };

                /**
                 * Encodes the specified PriceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PriceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.price = reader.int32();
                                break;
                            }
                        case 2: {
                                message.currencyCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PriceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PriceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.price != null && message.hasOwnProperty("price"))
                        if (!$util.isInteger(message.price))
                            return "price: integer expected";
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        if (!$util.isString(message.currencyCode))
                            return "currencyCode: string expected";
                    return null;
                };

                /**
                 * Creates a PriceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 */
                PriceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PriceProto)
                        return object;
                    var message = new $root.space.kenko.proto.PriceProto();
                    if (object.price != null)
                        message.price = object.price | 0;
                    if (object.currencyCode != null)
                        message.currencyCode = String(object.currencyCode);
                    return message;
                };

                /**
                 * Creates a plain object from a PriceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.PriceProto} message PriceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PriceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.price = 0;
                        object.currencyCode = "";
                    }
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = message.price;
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        object.currencyCode = message.currencyCode;
                    return object;
                };

                /**
                 * Converts this PriceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PriceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PriceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PriceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PriceProto";
                };

                return PriceProto;
            })();

            proto.BusinessProto = (function() {

                /**
                 * Properties of a BusinessProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessProto
                 * @property {string|null} [businessName] BusinessProto businessName
                 * @property {string|null} [businessDescription] BusinessProto businessDescription
                 * @property {string|null} [websiteUrl] BusinessProto websiteUrl
                 * @property {string|null} [phoneNumber] BusinessProto phoneNumber
                 * @property {string|null} [email] BusinessProto email
                 * @property {Array.<string>|null} [imageUrl] BusinessProto imageUrl
                 * @property {string|null} [shortUrl] BusinessProto shortUrl
                 */

                /**
                 * Constructs a new BusinessProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessProto.
                 * @implements IBusinessProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 */
                function BusinessProto(properties) {
                    this.imageUrl = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessProto businessName.
                 * @member {string} businessName
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessName = "";

                /**
                 * BusinessProto businessDescription.
                 * @member {string} businessDescription
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessDescription = "";

                /**
                 * BusinessProto websiteUrl.
                 * @member {string} websiteUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.websiteUrl = "";

                /**
                 * BusinessProto phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.phoneNumber = "";

                /**
                 * BusinessProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.email = "";

                /**
                 * BusinessProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * BusinessProto shortUrl.
                 * @member {string} shortUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.shortUrl = "";

                /**
                 * Creates a new BusinessProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto instance
                 */
                BusinessProto.create = function create(properties) {
                    return new BusinessProto(properties);
                };

                /**
                 * Encodes the specified BusinessProto message. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.businessDescription != null && Object.hasOwnProperty.call(message, "businessDescription"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessDescription);
                    if (message.websiteUrl != null && Object.hasOwnProperty.call(message, "websiteUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.websiteUrl);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phoneNumber);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.imageUrl[i]);
                    if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.shortUrl);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessDescription = reader.string();
                                break;
                            }
                        case 3: {
                                message.websiteUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        case 7: {
                                message.shortUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        if (!$util.isString(message.businessDescription))
                            return "businessDescription: string expected";
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        if (!$util.isString(message.websiteUrl))
                            return "websiteUrl: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        if (!$util.isString(message.shortUrl))
                            return "shortUrl: string expected";
                    return null;
                };

                /**
                 * Creates a BusinessProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 */
                BusinessProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessProto)
                        return object;
                    var message = new $root.space.kenko.proto.BusinessProto();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.businessDescription != null)
                        message.businessDescription = String(object.businessDescription);
                    if (object.websiteUrl != null)
                        message.websiteUrl = String(object.websiteUrl);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (var i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    if (object.shortUrl != null)
                        message.shortUrl = String(object.shortUrl);
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.BusinessProto} message BusinessProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrl = [];
                    if (options.defaults) {
                        object.businessName = "";
                        object.businessDescription = "";
                        object.websiteUrl = "";
                        object.phoneNumber = "";
                        object.email = "";
                        object.shortUrl = "";
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        object.businessDescription = message.businessDescription;
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        object.websiteUrl = message.websiteUrl;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (var j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        object.shortUrl = message.shortUrl;
                    return object;
                };

                /**
                 * Converts this BusinessProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessProto";
                };

                return BusinessProto;
            })();

            /**
             * ApprovalStatus enum.
             * @name space.kenko.proto.ApprovalStatus
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} IN_REVIEW=1 IN_REVIEW value
             * @property {number} APPROVED=2 APPROVED value
             * @property {number} REJECTED=3 REJECTED value
             */
            proto.ApprovalStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "IN_REVIEW"] = 1;
                values[valuesById[2] = "APPROVED"] = 2;
                values[valuesById[3] = "REJECTED"] = 3;
                return values;
            })();

            proto.ProfileReviewProto = (function() {

                /**
                 * Properties of a ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @interface IProfileReviewProto
                 * @property {space.kenko.proto.ApprovalStatus|null} [status] ProfileReviewProto status
                 * @property {Array.<string>|null} [messages] ProfileReviewProto messages
                 */

                /**
                 * Constructs a new ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProfileReviewProto.
                 * @implements IProfileReviewProto
                 * @constructor
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 */
                function ProfileReviewProto(properties) {
                    this.messages = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProfileReviewProto status.
                 * @member {space.kenko.proto.ApprovalStatus} status
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.status = 0;

                /**
                 * ProfileReviewProto messages.
                 * @member {Array.<string>} messages
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.messages = $util.emptyArray;

                /**
                 * Creates a new ProfileReviewProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto instance
                 */
                ProfileReviewProto.create = function create(properties) {
                    return new ProfileReviewProto(properties);
                };

                /**
                 * Encodes the specified ProfileReviewProto message. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.messages != null && message.messages.length)
                        for (var i = 0; i < message.messages.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.messages[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ProfileReviewProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProfileReviewProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.status = reader.int32();
                                break;
                            }
                        case 2: {
                                if (!(message.messages && message.messages.length))
                                    message.messages = [];
                                message.messages.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProfileReviewProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProfileReviewProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.messages != null && message.hasOwnProperty("messages")) {
                        if (!Array.isArray(message.messages))
                            return "messages: array expected";
                        for (var i = 0; i < message.messages.length; ++i)
                            if (!$util.isString(message.messages[i]))
                                return "messages: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ProfileReviewProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 */
                ProfileReviewProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProfileReviewProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProfileReviewProto();
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "IN_REVIEW":
                    case 1:
                        message.status = 1;
                        break;
                    case "APPROVED":
                    case 2:
                        message.status = 2;
                        break;
                    case "REJECTED":
                    case 3:
                        message.status = 3;
                        break;
                    }
                    if (object.messages) {
                        if (!Array.isArray(object.messages))
                            throw TypeError(".space.kenko.proto.ProfileReviewProto.messages: array expected");
                        message.messages = [];
                        for (var i = 0; i < object.messages.length; ++i)
                            message.messages[i] = String(object.messages[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProfileReviewProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.ProfileReviewProto} message ProfileReviewProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProfileReviewProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.messages = [];
                    if (options.defaults)
                        object.status = options.enums === String ? "UNKNOWN" : 0;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.space.kenko.proto.ApprovalStatus[message.status] === undefined ? message.status : $root.space.kenko.proto.ApprovalStatus[message.status] : message.status;
                    if (message.messages && message.messages.length) {
                        object.messages = [];
                        for (var j = 0; j < message.messages.length; ++j)
                            object.messages[j] = message.messages[j];
                    }
                    return object;
                };

                /**
                 * Converts this ProfileReviewProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProfileReviewProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProfileReviewProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProfileReviewProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProfileReviewProto";
                };

                return ProfileReviewProto;
            })();

            proto.BusinessLocationProto = (function() {

                /**
                 * Properties of a BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessLocationProto
                 * @property {string|null} [locationName] BusinessLocationProto locationName
                 * @property {string|null} [address] BusinessLocationProto address
                 * @property {string|null} [phone] BusinessLocationProto phone
                 * @property {string|null} [email] BusinessLocationProto email
                 * @property {number|null} [lat] BusinessLocationProto lat
                 * @property {number|null} [lng] BusinessLocationProto lng
                 * @property {string|null} [timeZone] BusinessLocationProto timeZone
                 * @property {boolean|null} ["private"] BusinessLocationProto private
                 * @property {boolean|null} [disabled] BusinessLocationProto disabled
                 * @property {Array.<string>|null} [imageUrl] BusinessLocationProto imageUrl
                 */

                /**
                 * Constructs a new BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessLocationProto.
                 * @implements IBusinessLocationProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 */
                function BusinessLocationProto(properties) {
                    this.imageUrl = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessLocationProto locationName.
                 * @member {string} locationName
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.locationName = "";

                /**
                 * BusinessLocationProto address.
                 * @member {string} address
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.address = "";

                /**
                 * BusinessLocationProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.phone = "";

                /**
                 * BusinessLocationProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.email = "";

                /**
                 * BusinessLocationProto lat.
                 * @member {number} lat
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lat = 0;

                /**
                 * BusinessLocationProto lng.
                 * @member {number} lng
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lng = 0;

                /**
                 * BusinessLocationProto timeZone.
                 * @member {string} timeZone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.timeZone = "";

                /**
                 * BusinessLocationProto private.
                 * @member {boolean} private
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype["private"] = false;

                /**
                 * BusinessLocationProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.disabled = false;

                /**
                 * BusinessLocationProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * Creates a new BusinessLocationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto instance
                 */
                BusinessLocationProto.create = function create(properties) {
                    return new BusinessLocationProto(properties);
                };

                /**
                 * Encodes the specified BusinessLocationProto message. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationName);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.lat);
                    if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                        writer.uint32(/* id 7, wireType 1 =*/57).double(message.lng);
                    if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.timeZone);
                    if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message["private"]);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.disabled);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.imageUrl[i]);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessLocationProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessLocationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 3: {
                                message.address = reader.string();
                                break;
                            }
                        case 4: {
                                message.phone = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                message.lat = reader.double();
                                break;
                            }
                        case 7: {
                                message.lng = reader.double();
                                break;
                            }
                        case 8: {
                                message.timeZone = reader.string();
                                break;
                            }
                        case 9: {
                                message["private"] = reader.bool();
                                break;
                            }
                        case 10: {
                                message.disabled = reader.bool();
                                break;
                            }
                        case 11: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessLocationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessLocationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (typeof message.lat !== "number")
                            return "lat: number expected";
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        if (typeof message.lng !== "number")
                            return "lng: number expected";
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        if (!$util.isString(message.timeZone))
                            return "timeZone: string expected";
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        if (typeof message["private"] !== "boolean")
                            return "private: boolean expected";
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a BusinessLocationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 */
                BusinessLocationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessLocationProto)
                        return object;
                    var message = new $root.space.kenko.proto.BusinessLocationProto();
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.lat != null)
                        message.lat = Number(object.lat);
                    if (object.lng != null)
                        message.lng = Number(object.lng);
                    if (object.timeZone != null)
                        message.timeZone = String(object.timeZone);
                    if (object["private"] != null)
                        message["private"] = Boolean(object["private"]);
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessLocationProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (var i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessLocationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.BusinessLocationProto} message BusinessLocationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessLocationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrl = [];
                    if (options.defaults) {
                        object.locationName = "";
                        object.address = "";
                        object.phone = "";
                        object.email = "";
                        object.lat = 0;
                        object.lng = 0;
                        object.timeZone = "";
                        object["private"] = false;
                        object.disabled = false;
                    }
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        object.timeZone = message.timeZone;
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        object["private"] = message["private"];
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (var j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    return object;
                };

                /**
                 * Converts this BusinessLocationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessLocationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessLocationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessLocationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessLocationProto";
                };

                return BusinessLocationProto;
            })();

            proto.ProviderBioProto = (function() {

                /**
                 * Properties of a ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderBioProto
                 * @property {string|null} [providerId] ProviderBioProto providerId
                 * @property {string|null} [firstName] ProviderBioProto firstName
                 * @property {string|null} [lastName] ProviderBioProto lastName
                 * @property {string|null} [bio] ProviderBioProto bio
                 * @property {Array.<string>|null} [imageUrls] ProviderBioProto imageUrls
                 * @property {string|null} [email] ProviderBioProto email
                 * @property {string|null} [phone] ProviderBioProto phone
                 * @property {string|null} [jobTitle] ProviderBioProto jobTitle
                 * @property {boolean|null} [disabled] ProviderBioProto disabled
                 */

                /**
                 * Constructs a new ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderBioProto.
                 * @implements IProviderBioProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 */
                function ProviderBioProto(properties) {
                    this.imageUrls = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderBioProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.providerId = "";

                /**
                 * ProviderBioProto firstName.
                 * @member {string} firstName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.firstName = "";

                /**
                 * ProviderBioProto lastName.
                 * @member {string} lastName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.lastName = "";

                /**
                 * ProviderBioProto bio.
                 * @member {string} bio
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.bio = "";

                /**
                 * ProviderBioProto imageUrls.
                 * @member {Array.<string>} imageUrls
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.imageUrls = $util.emptyArray;

                /**
                 * ProviderBioProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.email = "";

                /**
                 * ProviderBioProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.phone = "";

                /**
                 * ProviderBioProto jobTitle.
                 * @member {string} jobTitle
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.jobTitle = "";

                /**
                 * ProviderBioProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.disabled = false;

                /**
                 * Creates a new ProviderBioProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto instance
                 */
                ProviderBioProto.create = function create(properties) {
                    return new ProviderBioProto(properties);
                };

                /**
                 * Encodes the specified ProviderBioProto message. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                    if (message.bio != null && Object.hasOwnProperty.call(message, "bio"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.bio);
                    if (message.imageUrls != null && message.imageUrls.length)
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.imageUrls[i]);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.email);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.phone);
                    if (message.jobTitle != null && Object.hasOwnProperty.call(message, "jobTitle"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.jobTitle);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 100, wireType 0 =*/800).bool(message.disabled);
                    return writer;
                };

                /**
                 * Encodes the specified ProviderBioProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderBioProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 3: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 4: {
                                message.bio = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.imageUrls && message.imageUrls.length))
                                    message.imageUrls = [];
                                message.imageUrls.push(reader.string());
                                break;
                            }
                        case 8: {
                                message.email = reader.string();
                                break;
                            }
                        case 9: {
                                message.phone = reader.string();
                                break;
                            }
                        case 10: {
                                message.jobTitle = reader.string();
                                break;
                            }
                        case 100: {
                                message.disabled = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderBioProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderBioProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        if (!$util.isString(message.bio))
                            return "bio: string expected";
                    if (message.imageUrls != null && message.hasOwnProperty("imageUrls")) {
                        if (!Array.isArray(message.imageUrls))
                            return "imageUrls: array expected";
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            if (!$util.isString(message.imageUrls[i]))
                                return "imageUrls: string[] expected";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        if (!$util.isString(message.jobTitle))
                            return "jobTitle: string expected";
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    return null;
                };

                /**
                 * Creates a ProviderBioProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 */
                ProviderBioProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderBioProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderBioProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.bio != null)
                        message.bio = String(object.bio);
                    if (object.imageUrls) {
                        if (!Array.isArray(object.imageUrls))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.imageUrls: array expected");
                        message.imageUrls = [];
                        for (var i = 0; i < object.imageUrls.length; ++i)
                            message.imageUrls[i] = String(object.imageUrls[i]);
                    }
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.jobTitle != null)
                        message.jobTitle = String(object.jobTitle);
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderBioProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.ProviderBioProto} message ProviderBioProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderBioProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrls = [];
                    if (options.defaults) {
                        object.providerId = "";
                        object.firstName = "";
                        object.lastName = "";
                        object.bio = "";
                        object.email = "";
                        object.phone = "";
                        object.jobTitle = "";
                        object.disabled = false;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        object.bio = message.bio;
                    if (message.imageUrls && message.imageUrls.length) {
                        object.imageUrls = [];
                        for (var j = 0; j < message.imageUrls.length; ++j)
                            object.imageUrls[j] = message.imageUrls[j];
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        object.jobTitle = message.jobTitle;
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    return object;
                };

                /**
                 * Converts this ProviderBioProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderBioProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderBioProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderBioProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderBioProto";
                };

                return ProviderBioProto;
            })();

            proto.PracticeDetailsProto = (function() {

                /**
                 * Properties of a PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @interface IPracticeDetailsProto
                 * @property {Array.<space.kenko.proto.IServiceProto>|null} [services] PracticeDetailsProto services
                 * @property {Array.<space.kenko.proto.IPractitionerProto>|null} [practitioners] PracticeDetailsProto practitioners
                 */

                /**
                 * Constructs a new PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PracticeDetailsProto.
                 * @implements IPracticeDetailsProto
                 * @constructor
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 */
                function PracticeDetailsProto(properties) {
                    this.services = [];
                    this.practitioners = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PracticeDetailsProto services.
                 * @member {Array.<space.kenko.proto.IServiceProto>} services
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 */
                PracticeDetailsProto.prototype.services = $util.emptyArray;

                /**
                 * PracticeDetailsProto practitioners.
                 * @member {Array.<space.kenko.proto.IPractitionerProto>} practitioners
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 */
                PracticeDetailsProto.prototype.practitioners = $util.emptyArray;

                /**
                 * Creates a new PracticeDetailsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto instance
                 */
                PracticeDetailsProto.create = function create(properties) {
                    return new PracticeDetailsProto(properties);
                };

                /**
                 * Encodes the specified PracticeDetailsProto message. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.services != null && message.services.length)
                        for (var i = 0; i < message.services.length; ++i)
                            $root.space.kenko.proto.ServiceProto.encode(message.services[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.practitioners != null && message.practitioners.length)
                        for (var i = 0; i < message.practitioners.length; ++i)
                            $root.space.kenko.proto.PractitionerProto.encode(message.practitioners[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PracticeDetailsProto message, length delimited. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PracticeDetailsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.services && message.services.length))
                                    message.services = [];
                                message.services.push($root.space.kenko.proto.ServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.practitioners && message.practitioners.length))
                                    message.practitioners = [];
                                message.practitioners.push($root.space.kenko.proto.PractitionerProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PracticeDetailsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PracticeDetailsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.services != null && message.hasOwnProperty("services")) {
                        if (!Array.isArray(message.services))
                            return "services: array expected";
                        for (var i = 0; i < message.services.length; ++i) {
                            var error = $root.space.kenko.proto.ServiceProto.verify(message.services[i]);
                            if (error)
                                return "services." + error;
                        }
                    }
                    if (message.practitioners != null && message.hasOwnProperty("practitioners")) {
                        if (!Array.isArray(message.practitioners))
                            return "practitioners: array expected";
                        for (var i = 0; i < message.practitioners.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerProto.verify(message.practitioners[i]);
                            if (error)
                                return "practitioners." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PracticeDetailsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 */
                PracticeDetailsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PracticeDetailsProto)
                        return object;
                    var message = new $root.space.kenko.proto.PracticeDetailsProto();
                    if (object.services) {
                        if (!Array.isArray(object.services))
                            throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: array expected");
                        message.services = [];
                        for (var i = 0; i < object.services.length; ++i) {
                            if (typeof object.services[i] !== "object")
                                throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: object expected");
                            message.services[i] = $root.space.kenko.proto.ServiceProto.fromObject(object.services[i]);
                        }
                    }
                    if (object.practitioners) {
                        if (!Array.isArray(object.practitioners))
                            throw TypeError(".space.kenko.proto.PracticeDetailsProto.practitioners: array expected");
                        message.practitioners = [];
                        for (var i = 0; i < object.practitioners.length; ++i) {
                            if (typeof object.practitioners[i] !== "object")
                                throw TypeError(".space.kenko.proto.PracticeDetailsProto.practitioners: object expected");
                            message.practitioners[i] = $root.space.kenko.proto.PractitionerProto.fromObject(object.practitioners[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PracticeDetailsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.PracticeDetailsProto} message PracticeDetailsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PracticeDetailsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.services = [];
                        object.practitioners = [];
                    }
                    if (message.services && message.services.length) {
                        object.services = [];
                        for (var j = 0; j < message.services.length; ++j)
                            object.services[j] = $root.space.kenko.proto.ServiceProto.toObject(message.services[j], options);
                    }
                    if (message.practitioners && message.practitioners.length) {
                        object.practitioners = [];
                        for (var j = 0; j < message.practitioners.length; ++j)
                            object.practitioners[j] = $root.space.kenko.proto.PractitionerProto.toObject(message.practitioners[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PracticeDetailsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PracticeDetailsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PracticeDetailsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PracticeDetailsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PracticeDetailsProto";
                };

                return PracticeDetailsProto;
            })();

            proto.ServiceProto = (function() {

                /**
                 * Properties of a ServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceProto
                 * @property {string|null} [sku] ServiceProto sku
                 * @property {string|null} [name] ServiceProto name
                 * @property {string|null} [description] ServiceProto description
                 * @property {number|null} [lengthMinutes] ServiceProto lengthMinutes
                 * @property {string|null} [category] ServiceProto category
                 * @property {Array.<number>|null} [availableLocations] ServiceProto availableLocations
                 * @property {boolean|null} [virtualAvailable] ServiceProto virtualAvailable
                 * @property {Array.<space.kenko.proto.IPractitionerServiceProto>|null} [offeredBy] ServiceProto offeredBy
                 */

                /**
                 * Constructs a new ServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceProto.
                 * @implements IServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 */
                function ServiceProto(properties) {
                    this.availableLocations = [];
                    this.offeredBy = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.sku = "";

                /**
                 * ServiceProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.name = "";

                /**
                 * ServiceProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.description = "";

                /**
                 * ServiceProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.category = "";

                /**
                 * ServiceProto availableLocations.
                 * @member {Array.<number>} availableLocations
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.availableLocations = $util.emptyArray;

                /**
                 * ServiceProto virtualAvailable.
                 * @member {boolean} virtualAvailable
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.virtualAvailable = false;

                /**
                 * ServiceProto offeredBy.
                 * @member {Array.<space.kenko.proto.IPractitionerServiceProto>} offeredBy
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.offeredBy = $util.emptyArray;

                /**
                 * Creates a new ServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto instance
                 */
                ServiceProto.create = function create(properties) {
                    return new ServiceProto(properties);
                };

                /**
                 * Encodes the specified ServiceProto message. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.category);
                    if (message.availableLocations != null && message.availableLocations.length) {
                        writer.uint32(/* id 6, wireType 2 =*/50).fork();
                        for (var i = 0; i < message.availableLocations.length; ++i)
                            writer.int32(message.availableLocations[i]);
                        writer.ldelim();
                    }
                    if (message.virtualAvailable != null && Object.hasOwnProperty.call(message, "virtualAvailable"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.virtualAvailable);
                    if (message.offeredBy != null && message.offeredBy.length)
                        for (var i = 0; i < message.offeredBy.length; ++i)
                            $root.space.kenko.proto.PractitionerServiceProto.encode(message.offeredBy[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.category = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.availableLocations && message.availableLocations.length))
                                    message.availableLocations = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.availableLocations.push(reader.int32());
                                } else
                                    message.availableLocations.push(reader.int32());
                                break;
                            }
                        case 7: {
                                message.virtualAvailable = reader.bool();
                                break;
                            }
                        case 8: {
                                if (!(message.offeredBy && message.offeredBy.length))
                                    message.offeredBy = [];
                                message.offeredBy.push($root.space.kenko.proto.PractitionerServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.availableLocations != null && message.hasOwnProperty("availableLocations")) {
                        if (!Array.isArray(message.availableLocations))
                            return "availableLocations: array expected";
                        for (var i = 0; i < message.availableLocations.length; ++i)
                            if (!$util.isInteger(message.availableLocations[i]))
                                return "availableLocations: integer[] expected";
                    }
                    if (message.virtualAvailable != null && message.hasOwnProperty("virtualAvailable"))
                        if (typeof message.virtualAvailable !== "boolean")
                            return "virtualAvailable: boolean expected";
                    if (message.offeredBy != null && message.hasOwnProperty("offeredBy")) {
                        if (!Array.isArray(message.offeredBy))
                            return "offeredBy: array expected";
                        for (var i = 0; i < message.offeredBy.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerServiceProto.verify(message.offeredBy[i]);
                            if (error)
                                return "offeredBy." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 */
                ServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceProto)
                        return object;
                    var message = new $root.space.kenko.proto.ServiceProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.availableLocations) {
                        if (!Array.isArray(object.availableLocations))
                            throw TypeError(".space.kenko.proto.ServiceProto.availableLocations: array expected");
                        message.availableLocations = [];
                        for (var i = 0; i < object.availableLocations.length; ++i)
                            message.availableLocations[i] = object.availableLocations[i] | 0;
                    }
                    if (object.virtualAvailable != null)
                        message.virtualAvailable = Boolean(object.virtualAvailable);
                    if (object.offeredBy) {
                        if (!Array.isArray(object.offeredBy))
                            throw TypeError(".space.kenko.proto.ServiceProto.offeredBy: array expected");
                        message.offeredBy = [];
                        for (var i = 0; i < object.offeredBy.length; ++i) {
                            if (typeof object.offeredBy[i] !== "object")
                                throw TypeError(".space.kenko.proto.ServiceProto.offeredBy: object expected");
                            message.offeredBy[i] = $root.space.kenko.proto.PractitionerServiceProto.fromObject(object.offeredBy[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.ServiceProto} message ServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.availableLocations = [];
                        object.offeredBy = [];
                    }
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.category = "";
                        object.virtualAvailable = false;
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.availableLocations && message.availableLocations.length) {
                        object.availableLocations = [];
                        for (var j = 0; j < message.availableLocations.length; ++j)
                            object.availableLocations[j] = message.availableLocations[j];
                    }
                    if (message.virtualAvailable != null && message.hasOwnProperty("virtualAvailable"))
                        object.virtualAvailable = message.virtualAvailable;
                    if (message.offeredBy && message.offeredBy.length) {
                        object.offeredBy = [];
                        for (var j = 0; j < message.offeredBy.length; ++j)
                            object.offeredBy[j] = $root.space.kenko.proto.PractitionerServiceProto.toObject(message.offeredBy[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceProto";
                };

                return ServiceProto;
            })();

            proto.PractitionerProto = (function() {

                /**
                 * Properties of a PractitionerProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerProto
                 * @property {string|null} [providerId] PractitionerProto providerId
                 * @property {Array.<space.kenko.proto.IPractitionerAppointmentProto>|null} [appointments] PractitionerProto appointments
                 * @property {Array.<space.kenko.proto.IPractitionerAvailabilityBlockProto>|null} [defaultAvailabilityBlocks] PractitionerProto defaultAvailabilityBlocks
                 */

                /**
                 * Constructs a new PractitionerProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerProto.
                 * @implements IPractitionerProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerProto=} [properties] Properties to set
                 */
                function PractitionerProto(properties) {
                    this.appointments = [];
                    this.defaultAvailabilityBlocks = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.PractitionerProto
                 * @instance
                 */
                PractitionerProto.prototype.providerId = "";

                /**
                 * PractitionerProto appointments.
                 * @member {Array.<space.kenko.proto.IPractitionerAppointmentProto>} appointments
                 * @memberof space.kenko.proto.PractitionerProto
                 * @instance
                 */
                PractitionerProto.prototype.appointments = $util.emptyArray;

                /**
                 * PractitionerProto defaultAvailabilityBlocks.
                 * @member {Array.<space.kenko.proto.IPractitionerAvailabilityBlockProto>} defaultAvailabilityBlocks
                 * @memberof space.kenko.proto.PractitionerProto
                 * @instance
                 */
                PractitionerProto.prototype.defaultAvailabilityBlocks = $util.emptyArray;

                /**
                 * Creates a new PractitionerProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerProto} PractitionerProto instance
                 */
                PractitionerProto.create = function create(properties) {
                    return new PractitionerProto(properties);
                };

                /**
                 * Encodes the specified PractitionerProto message. Does not implicitly {@link space.kenko.proto.PractitionerProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerProto} message PractitionerProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.appointments != null && message.appointments.length)
                        for (var i = 0; i < message.appointments.length; ++i)
                            $root.space.kenko.proto.PractitionerAppointmentProto.encode(message.appointments[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.defaultAvailabilityBlocks != null && message.defaultAvailabilityBlocks.length)
                        for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i)
                            $root.space.kenko.proto.PractitionerAvailabilityBlockProto.encode(message.defaultAvailabilityBlocks[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerProto} message PractitionerProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerProto} PractitionerProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.appointments && message.appointments.length))
                                    message.appointments = [];
                                message.appointments.push($root.space.kenko.proto.PractitionerAppointmentProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length))
                                    message.defaultAvailabilityBlocks = [];
                                message.defaultAvailabilityBlocks.push($root.space.kenko.proto.PractitionerAvailabilityBlockProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerProto} PractitionerProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.appointments != null && message.hasOwnProperty("appointments")) {
                        if (!Array.isArray(message.appointments))
                            return "appointments: array expected";
                        for (var i = 0; i < message.appointments.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerAppointmentProto.verify(message.appointments[i]);
                            if (error)
                                return "appointments." + error;
                        }
                    }
                    if (message.defaultAvailabilityBlocks != null && message.hasOwnProperty("defaultAvailabilityBlocks")) {
                        if (!Array.isArray(message.defaultAvailabilityBlocks))
                            return "defaultAvailabilityBlocks: array expected";
                        for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerAvailabilityBlockProto.verify(message.defaultAvailabilityBlocks[i]);
                            if (error)
                                return "defaultAvailabilityBlocks." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PractitionerProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerProto} PractitionerProto
                 */
                PractitionerProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.appointments) {
                        if (!Array.isArray(object.appointments))
                            throw TypeError(".space.kenko.proto.PractitionerProto.appointments: array expected");
                        message.appointments = [];
                        for (var i = 0; i < object.appointments.length; ++i) {
                            if (typeof object.appointments[i] !== "object")
                                throw TypeError(".space.kenko.proto.PractitionerProto.appointments: object expected");
                            message.appointments[i] = $root.space.kenko.proto.PractitionerAppointmentProto.fromObject(object.appointments[i]);
                        }
                    }
                    if (object.defaultAvailabilityBlocks) {
                        if (!Array.isArray(object.defaultAvailabilityBlocks))
                            throw TypeError(".space.kenko.proto.PractitionerProto.defaultAvailabilityBlocks: array expected");
                        message.defaultAvailabilityBlocks = [];
                        for (var i = 0; i < object.defaultAvailabilityBlocks.length; ++i) {
                            if (typeof object.defaultAvailabilityBlocks[i] !== "object")
                                throw TypeError(".space.kenko.proto.PractitionerProto.defaultAvailabilityBlocks: object expected");
                            message.defaultAvailabilityBlocks[i] = $root.space.kenko.proto.PractitionerAvailabilityBlockProto.fromObject(object.defaultAvailabilityBlocks[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {space.kenko.proto.PractitionerProto} message PractitionerProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.appointments = [];
                        object.defaultAvailabilityBlocks = [];
                    }
                    if (options.defaults)
                        object.providerId = "";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.appointments && message.appointments.length) {
                        object.appointments = [];
                        for (var j = 0; j < message.appointments.length; ++j)
                            object.appointments[j] = $root.space.kenko.proto.PractitionerAppointmentProto.toObject(message.appointments[j], options);
                    }
                    if (message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length) {
                        object.defaultAvailabilityBlocks = [];
                        for (var j = 0; j < message.defaultAvailabilityBlocks.length; ++j)
                            object.defaultAvailabilityBlocks[j] = $root.space.kenko.proto.PractitionerAvailabilityBlockProto.toObject(message.defaultAvailabilityBlocks[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PractitionerProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerProto";
                };

                return PractitionerProto;
            })();

            proto.PractitionerServiceProto = (function() {

                /**
                 * Properties of a PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerServiceProto
                 * @property {string|null} [providerId] PractitionerServiceProto providerId
                 * @property {space.kenko.proto.IPriceProto|null} [price] PractitionerServiceProto price
                 */

                /**
                 * Constructs a new PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerServiceProto.
                 * @implements IPractitionerServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 */
                function PractitionerServiceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerServiceProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.providerId = "";

                /**
                 * PractitionerServiceProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.price = null;

                /**
                 * Creates a new PractitionerServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto instance
                 */
                PractitionerServiceProto.create = function create(properties) {
                    return new PractitionerServiceProto(properties);
                };

                /**
                 * Encodes the specified PractitionerServiceProto message. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerServiceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PractitionerServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 */
                PractitionerServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerServiceProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerServiceProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.PractitionerServiceProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.PractitionerServiceProto} message PractitionerServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.providerId = "";
                        object.price = null;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    return object;
                };

                /**
                 * Converts this PractitionerServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerServiceProto";
                };

                return PractitionerServiceProto;
            })();

            proto.PractitionerAppointmentProto = (function() {

                /**
                 * Properties of a PractitionerAppointmentProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerAppointmentProto
                 * @property {string|null} [appointmentId] PractitionerAppointmentProto appointmentId
                 * @property {number|Long|null} [startTimeMs] PractitionerAppointmentProto startTimeMs
                 * @property {number|Long|null} [endTimeMs] PractitionerAppointmentProto endTimeMs
                 * @property {string|null} [sku] PractitionerAppointmentProto sku
                 * @property {string|null} [clientName] PractitionerAppointmentProto clientName
                 */

                /**
                 * Constructs a new PractitionerAppointmentProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerAppointmentProto.
                 * @implements IPractitionerAppointmentProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerAppointmentProto=} [properties] Properties to set
                 */
                function PractitionerAppointmentProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerAppointmentProto appointmentId.
                 * @member {string} appointmentId
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.appointmentId = "";

                /**
                 * PractitionerAppointmentProto startTimeMs.
                 * @member {number|Long} startTimeMs
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PractitionerAppointmentProto endTimeMs.
                 * @member {number|Long} endTimeMs
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PractitionerAppointmentProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.sku = "";

                /**
                 * PractitionerAppointmentProto clientName.
                 * @member {string} clientName
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 */
                PractitionerAppointmentProto.prototype.clientName = "";

                /**
                 * Creates a new PractitionerAppointmentProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAppointmentProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerAppointmentProto} PractitionerAppointmentProto instance
                 */
                PractitionerAppointmentProto.create = function create(properties) {
                    return new PractitionerAppointmentProto(properties);
                };

                /**
                 * Encodes the specified PractitionerAppointmentProto message. Does not implicitly {@link space.kenko.proto.PractitionerAppointmentProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAppointmentProto} message PractitionerAppointmentProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerAppointmentProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
                    if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
                    if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.sku);
                    if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.clientName);
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerAppointmentProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerAppointmentProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAppointmentProto} message PractitionerAppointmentProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerAppointmentProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerAppointmentProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerAppointmentProto} PractitionerAppointmentProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerAppointmentProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerAppointmentProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appointmentId = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.sku = reader.string();
                                break;
                            }
                        case 5: {
                                message.clientName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerAppointmentProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerAppointmentProto} PractitionerAppointmentProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerAppointmentProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerAppointmentProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerAppointmentProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        if (!$util.isString(message.appointmentId))
                            return "appointmentId: string expected";
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                            return "startTimeMs: integer|Long expected";
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                            return "endTimeMs: integer|Long expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        if (!$util.isString(message.clientName))
                            return "clientName: string expected";
                    return null;
                };

                /**
                 * Creates a PractitionerAppointmentProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerAppointmentProto} PractitionerAppointmentProto
                 */
                PractitionerAppointmentProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerAppointmentProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerAppointmentProto();
                    if (object.appointmentId != null)
                        message.appointmentId = String(object.appointmentId);
                    if (object.startTimeMs != null)
                        if ($util.Long)
                            (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                        else if (typeof object.startTimeMs === "string")
                            message.startTimeMs = parseInt(object.startTimeMs, 10);
                        else if (typeof object.startTimeMs === "number")
                            message.startTimeMs = object.startTimeMs;
                        else if (typeof object.startTimeMs === "object")
                            message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                    if (object.endTimeMs != null)
                        if ($util.Long)
                            (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                        else if (typeof object.endTimeMs === "string")
                            message.endTimeMs = parseInt(object.endTimeMs, 10);
                        else if (typeof object.endTimeMs === "number")
                            message.endTimeMs = object.endTimeMs;
                        else if (typeof object.endTimeMs === "object")
                            message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.clientName != null)
                        message.clientName = String(object.clientName);
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerAppointmentProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {space.kenko.proto.PractitionerAppointmentProto} message PractitionerAppointmentProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerAppointmentProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.appointmentId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMs = options.longs === String ? "0" : 0;
                        object.sku = "";
                        object.clientName = "";
                    }
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        object.appointmentId = message.appointmentId;
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (typeof message.startTimeMs === "number")
                            object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                        else
                            object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (typeof message.endTimeMs === "number")
                            object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                        else
                            object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        object.clientName = message.clientName;
                    return object;
                };

                /**
                 * Converts this PractitionerAppointmentProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerAppointmentProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerAppointmentProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerAppointmentProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerAppointmentProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerAppointmentProto";
                };

                return PractitionerAppointmentProto;
            })();

            proto.PractitionerAvailabilityBlockProto = (function() {

                /**
                 * Properties of a PractitionerAvailabilityBlockProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerAvailabilityBlockProto
                 * @property {string|null} [locationId] PractitionerAvailabilityBlockProto locationId
                 * @property {number|null} [dayOfWeek] PractitionerAvailabilityBlockProto dayOfWeek
                 * @property {space.kenko.proto.ILocalTimeProto|null} [start] PractitionerAvailabilityBlockProto start
                 * @property {space.kenko.proto.ILocalTimeProto|null} [end] PractitionerAvailabilityBlockProto end
                 */

                /**
                 * Constructs a new PractitionerAvailabilityBlockProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerAvailabilityBlockProto.
                 * @implements IPractitionerAvailabilityBlockProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerAvailabilityBlockProto=} [properties] Properties to set
                 */
                function PractitionerAvailabilityBlockProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerAvailabilityBlockProto locationId.
                 * @member {string} locationId
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 */
                PractitionerAvailabilityBlockProto.prototype.locationId = "";

                /**
                 * PractitionerAvailabilityBlockProto dayOfWeek.
                 * @member {number} dayOfWeek
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 */
                PractitionerAvailabilityBlockProto.prototype.dayOfWeek = 0;

                /**
                 * PractitionerAvailabilityBlockProto start.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} start
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 */
                PractitionerAvailabilityBlockProto.prototype.start = null;

                /**
                 * PractitionerAvailabilityBlockProto end.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} end
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 */
                PractitionerAvailabilityBlockProto.prototype.end = null;

                /**
                 * Creates a new PractitionerAvailabilityBlockProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAvailabilityBlockProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerAvailabilityBlockProto} PractitionerAvailabilityBlockProto instance
                 */
                PractitionerAvailabilityBlockProto.create = function create(properties) {
                    return new PractitionerAvailabilityBlockProto(properties);
                };

                /**
                 * Encodes the specified PractitionerAvailabilityBlockProto message. Does not implicitly {@link space.kenko.proto.PractitionerAvailabilityBlockProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAvailabilityBlockProto} message PractitionerAvailabilityBlockProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerAvailabilityBlockProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dayOfWeek);
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.start, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.end, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerAvailabilityBlockProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerAvailabilityBlockProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerAvailabilityBlockProto} message PractitionerAvailabilityBlockProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerAvailabilityBlockProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerAvailabilityBlockProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerAvailabilityBlockProto} PractitionerAvailabilityBlockProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerAvailabilityBlockProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerAvailabilityBlockProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.dayOfWeek = reader.int32();
                                break;
                            }
                        case 3: {
                                message.start = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.end = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerAvailabilityBlockProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerAvailabilityBlockProto} PractitionerAvailabilityBlockProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerAvailabilityBlockProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerAvailabilityBlockProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerAvailabilityBlockProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                        if (!$util.isInteger(message.dayOfWeek))
                            return "dayOfWeek: integer expected";
                    if (message.start != null && message.hasOwnProperty("start")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.start);
                        if (error)
                            return "start." + error;
                    }
                    if (message.end != null && message.hasOwnProperty("end")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.end);
                        if (error)
                            return "end." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PractitionerAvailabilityBlockProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerAvailabilityBlockProto} PractitionerAvailabilityBlockProto
                 */
                PractitionerAvailabilityBlockProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerAvailabilityBlockProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerAvailabilityBlockProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.dayOfWeek != null)
                        message.dayOfWeek = object.dayOfWeek | 0;
                    if (object.start != null) {
                        if (typeof object.start !== "object")
                            throw TypeError(".space.kenko.proto.PractitionerAvailabilityBlockProto.start: object expected");
                        message.start = $root.space.kenko.proto.LocalTimeProto.fromObject(object.start);
                    }
                    if (object.end != null) {
                        if (typeof object.end !== "object")
                            throw TypeError(".space.kenko.proto.PractitionerAvailabilityBlockProto.end: object expected");
                        message.end = $root.space.kenko.proto.LocalTimeProto.fromObject(object.end);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerAvailabilityBlockProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.PractitionerAvailabilityBlockProto} message PractitionerAvailabilityBlockProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerAvailabilityBlockProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.dayOfWeek = 0;
                        object.start = null;
                        object.end = null;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                        object.dayOfWeek = message.dayOfWeek;
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = $root.space.kenko.proto.LocalTimeProto.toObject(message.start, options);
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = $root.space.kenko.proto.LocalTimeProto.toObject(message.end, options);
                    return object;
                };

                /**
                 * Converts this PractitionerAvailabilityBlockProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerAvailabilityBlockProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerAvailabilityBlockProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerAvailabilityBlockProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerAvailabilityBlockProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerAvailabilityBlockProto";
                };

                return PractitionerAvailabilityBlockProto;
            })();

            proto.ServiceSchemaProto = (function() {

                /**
                 * Properties of a ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceSchemaProto
                 * @property {string|null} [sku] ServiceSchemaProto sku
                 * @property {string|null} [name] ServiceSchemaProto name
                 * @property {string|null} [description] ServiceSchemaProto description
                 * @property {number|null} [lengthMinutes] ServiceSchemaProto lengthMinutes
                 * @property {number|null} [price] ServiceSchemaProto price
                 * @property {string|null} [currencyCode] ServiceSchemaProto currencyCode
                 * @property {string|null} [category] ServiceSchemaProto category
                 */

                /**
                 * Constructs a new ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceSchemaProto.
                 * @implements IServiceSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 */
                function ServiceSchemaProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceSchemaProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.sku = "";

                /**
                 * ServiceSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.name = "";

                /**
                 * ServiceSchemaProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.description = "";

                /**
                 * ServiceSchemaProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceSchemaProto price.
                 * @member {number} price
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.price = 0;

                /**
                 * ServiceSchemaProto currencyCode.
                 * @member {string} currencyCode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.currencyCode = "";

                /**
                 * ServiceSchemaProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.category = "";

                /**
                 * Creates a new ServiceSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto instance
                 */
                ServiceSchemaProto.create = function create(properties) {
                    return new ServiceSchemaProto(properties);
                };

                /**
                 * Encodes the specified ServiceSchemaProto message. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.price);
                    if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.currencyCode);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.category);
                    return writer;
                };

                /**
                 * Encodes the specified ServiceSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.price = reader.int32();
                                break;
                            }
                        case 6: {
                                message.currencyCode = reader.string();
                                break;
                            }
                        case 7: {
                                message.category = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.price != null && message.hasOwnProperty("price"))
                        if (!$util.isInteger(message.price))
                            return "price: integer expected";
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        if (!$util.isString(message.currencyCode))
                            return "currencyCode: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    return null;
                };

                /**
                 * Creates a ServiceSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 */
                ServiceSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ServiceSchemaProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.price != null)
                        message.price = object.price | 0;
                    if (object.currencyCode != null)
                        message.currencyCode = String(object.currencyCode);
                    if (object.category != null)
                        message.category = String(object.category);
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.ServiceSchemaProto} message ServiceSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.price = 0;
                        object.currencyCode = "";
                        object.category = "";
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = message.price;
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        object.currencyCode = message.currencyCode;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    return object;
                };

                /**
                 * Converts this ServiceSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceSchemaProto";
                };

                return ServiceSchemaProto;
            })();

            proto.ProviderAppointmentProto = (function() {

                /**
                 * Properties of a ProviderAppointmentProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderAppointmentProto
                 * @property {string|null} [appointmentId] ProviderAppointmentProto appointmentId
                 * @property {number|Long|null} [startTimeMs] ProviderAppointmentProto startTimeMs
                 * @property {number|Long|null} [endTimeMs] ProviderAppointmentProto endTimeMs
                 * @property {string|null} [sku] ProviderAppointmentProto sku
                 * @property {string|null} [clientName] ProviderAppointmentProto clientName
                 */

                /**
                 * Constructs a new ProviderAppointmentProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderAppointmentProto.
                 * @implements IProviderAppointmentProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderAppointmentProto=} [properties] Properties to set
                 */
                function ProviderAppointmentProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderAppointmentProto appointmentId.
                 * @member {string} appointmentId
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.appointmentId = "";

                /**
                 * ProviderAppointmentProto startTimeMs.
                 * @member {number|Long} startTimeMs
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ProviderAppointmentProto endTimeMs.
                 * @member {number|Long} endTimeMs
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ProviderAppointmentProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.sku = "";

                /**
                 * ProviderAppointmentProto clientName.
                 * @member {string} clientName
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 */
                ProviderAppointmentProto.prototype.clientName = "";

                /**
                 * Creates a new ProviderAppointmentProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IProviderAppointmentProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderAppointmentProto} ProviderAppointmentProto instance
                 */
                ProviderAppointmentProto.create = function create(properties) {
                    return new ProviderAppointmentProto(properties);
                };

                /**
                 * Encodes the specified ProviderAppointmentProto message. Does not implicitly {@link space.kenko.proto.ProviderAppointmentProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IProviderAppointmentProto} message ProviderAppointmentProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderAppointmentProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
                    if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
                    if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.sku);
                    if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.clientName);
                    return writer;
                };

                /**
                 * Encodes the specified ProviderAppointmentProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderAppointmentProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {space.kenko.proto.IProviderAppointmentProto} message ProviderAppointmentProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderAppointmentProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderAppointmentProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderAppointmentProto} ProviderAppointmentProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderAppointmentProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderAppointmentProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appointmentId = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.sku = reader.string();
                                break;
                            }
                        case 5: {
                                message.clientName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderAppointmentProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderAppointmentProto} ProviderAppointmentProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderAppointmentProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderAppointmentProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderAppointmentProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        if (!$util.isString(message.appointmentId))
                            return "appointmentId: string expected";
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                            return "startTimeMs: integer|Long expected";
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                            return "endTimeMs: integer|Long expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        if (!$util.isString(message.clientName))
                            return "clientName: string expected";
                    return null;
                };

                /**
                 * Creates a ProviderAppointmentProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderAppointmentProto} ProviderAppointmentProto
                 */
                ProviderAppointmentProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderAppointmentProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderAppointmentProto();
                    if (object.appointmentId != null)
                        message.appointmentId = String(object.appointmentId);
                    if (object.startTimeMs != null)
                        if ($util.Long)
                            (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                        else if (typeof object.startTimeMs === "string")
                            message.startTimeMs = parseInt(object.startTimeMs, 10);
                        else if (typeof object.startTimeMs === "number")
                            message.startTimeMs = object.startTimeMs;
                        else if (typeof object.startTimeMs === "object")
                            message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                    if (object.endTimeMs != null)
                        if ($util.Long)
                            (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                        else if (typeof object.endTimeMs === "string")
                            message.endTimeMs = parseInt(object.endTimeMs, 10);
                        else if (typeof object.endTimeMs === "number")
                            message.endTimeMs = object.endTimeMs;
                        else if (typeof object.endTimeMs === "object")
                            message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.clientName != null)
                        message.clientName = String(object.clientName);
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderAppointmentProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {space.kenko.proto.ProviderAppointmentProto} message ProviderAppointmentProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderAppointmentProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.appointmentId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMs = options.longs === String ? "0" : 0;
                        object.sku = "";
                        object.clientName = "";
                    }
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        object.appointmentId = message.appointmentId;
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (typeof message.startTimeMs === "number")
                            object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                        else
                            object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (typeof message.endTimeMs === "number")
                            object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                        else
                            object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.clientName != null && message.hasOwnProperty("clientName"))
                        object.clientName = message.clientName;
                    return object;
                };

                /**
                 * Converts this ProviderAppointmentProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderAppointmentProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderAppointmentProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderAppointmentProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderAppointmentProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderAppointmentProto";
                };

                return ProviderAppointmentProto;
            })();

            proto.ProviderSchemaProto = (function() {

                /**
                 * Properties of a ProviderSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderSchemaProto
                 * @property {Array.<space.kenko.proto.IServiceSchemaProto>|null} [services] ProviderSchemaProto services
                 * @property {Array.<space.kenko.proto.IProviderAppointmentProto>|null} [appointments] ProviderSchemaProto appointments
                 * @property {string|null} [providerTimeZone] ProviderSchemaProto providerTimeZone
                 * @property {Array.<space.kenko.proto.IAvailabilityBlockProto>|null} [defaultAvailabilityBlocks] ProviderSchemaProto defaultAvailabilityBlocks
                 */

                /**
                 * Constructs a new ProviderSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderSchemaProto.
                 * @implements IProviderSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderSchemaProto=} [properties] Properties to set
                 */
                function ProviderSchemaProto(properties) {
                    this.services = [];
                    this.appointments = [];
                    this.defaultAvailabilityBlocks = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderSchemaProto services.
                 * @member {Array.<space.kenko.proto.IServiceSchemaProto>} services
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 */
                ProviderSchemaProto.prototype.services = $util.emptyArray;

                /**
                 * ProviderSchemaProto appointments.
                 * @member {Array.<space.kenko.proto.IProviderAppointmentProto>} appointments
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 */
                ProviderSchemaProto.prototype.appointments = $util.emptyArray;

                /**
                 * ProviderSchemaProto providerTimeZone.
                 * @member {string} providerTimeZone
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 */
                ProviderSchemaProto.prototype.providerTimeZone = "";

                /**
                 * ProviderSchemaProto defaultAvailabilityBlocks.
                 * @member {Array.<space.kenko.proto.IAvailabilityBlockProto>} defaultAvailabilityBlocks
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 */
                ProviderSchemaProto.prototype.defaultAvailabilityBlocks = $util.emptyArray;

                /**
                 * Creates a new ProviderSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {space.kenko.proto.IProviderSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderSchemaProto} ProviderSchemaProto instance
                 */
                ProviderSchemaProto.create = function create(properties) {
                    return new ProviderSchemaProto(properties);
                };

                /**
                 * Encodes the specified ProviderSchemaProto message. Does not implicitly {@link space.kenko.proto.ProviderSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {space.kenko.proto.IProviderSchemaProto} message ProviderSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.services != null && message.services.length)
                        for (var i = 0; i < message.services.length; ++i)
                            $root.space.kenko.proto.ServiceSchemaProto.encode(message.services[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.appointments != null && message.appointments.length)
                        for (var i = 0; i < message.appointments.length; ++i)
                            $root.space.kenko.proto.ProviderAppointmentProto.encode(message.appointments[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.providerTimeZone != null && Object.hasOwnProperty.call(message, "providerTimeZone"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.providerTimeZone);
                    if (message.defaultAvailabilityBlocks != null && message.defaultAvailabilityBlocks.length)
                        for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i)
                            $root.space.kenko.proto.AvailabilityBlockProto.encode(message.defaultAvailabilityBlocks[i], writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ProviderSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {space.kenko.proto.IProviderSchemaProto} message ProviderSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderSchemaProto} ProviderSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.services && message.services.length))
                                    message.services = [];
                                message.services.push($root.space.kenko.proto.ServiceSchemaProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.appointments && message.appointments.length))
                                    message.appointments = [];
                                message.appointments.push($root.space.kenko.proto.ProviderAppointmentProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 10: {
                                message.providerTimeZone = reader.string();
                                break;
                            }
                        case 21: {
                                if (!(message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length))
                                    message.defaultAvailabilityBlocks = [];
                                message.defaultAvailabilityBlocks.push($root.space.kenko.proto.AvailabilityBlockProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderSchemaProto} ProviderSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.services != null && message.hasOwnProperty("services")) {
                        if (!Array.isArray(message.services))
                            return "services: array expected";
                        for (var i = 0; i < message.services.length; ++i) {
                            var error = $root.space.kenko.proto.ServiceSchemaProto.verify(message.services[i]);
                            if (error)
                                return "services." + error;
                        }
                    }
                    if (message.appointments != null && message.hasOwnProperty("appointments")) {
                        if (!Array.isArray(message.appointments))
                            return "appointments: array expected";
                        for (var i = 0; i < message.appointments.length; ++i) {
                            var error = $root.space.kenko.proto.ProviderAppointmentProto.verify(message.appointments[i]);
                            if (error)
                                return "appointments." + error;
                        }
                    }
                    if (message.providerTimeZone != null && message.hasOwnProperty("providerTimeZone"))
                        if (!$util.isString(message.providerTimeZone))
                            return "providerTimeZone: string expected";
                    if (message.defaultAvailabilityBlocks != null && message.hasOwnProperty("defaultAvailabilityBlocks")) {
                        if (!Array.isArray(message.defaultAvailabilityBlocks))
                            return "defaultAvailabilityBlocks: array expected";
                        for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i) {
                            var error = $root.space.kenko.proto.AvailabilityBlockProto.verify(message.defaultAvailabilityBlocks[i]);
                            if (error)
                                return "defaultAvailabilityBlocks." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ProviderSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderSchemaProto} ProviderSchemaProto
                 */
                ProviderSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderSchemaProto();
                    if (object.services) {
                        if (!Array.isArray(object.services))
                            throw TypeError(".space.kenko.proto.ProviderSchemaProto.services: array expected");
                        message.services = [];
                        for (var i = 0; i < object.services.length; ++i) {
                            if (typeof object.services[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSchemaProto.services: object expected");
                            message.services[i] = $root.space.kenko.proto.ServiceSchemaProto.fromObject(object.services[i]);
                        }
                    }
                    if (object.appointments) {
                        if (!Array.isArray(object.appointments))
                            throw TypeError(".space.kenko.proto.ProviderSchemaProto.appointments: array expected");
                        message.appointments = [];
                        for (var i = 0; i < object.appointments.length; ++i) {
                            if (typeof object.appointments[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSchemaProto.appointments: object expected");
                            message.appointments[i] = $root.space.kenko.proto.ProviderAppointmentProto.fromObject(object.appointments[i]);
                        }
                    }
                    if (object.providerTimeZone != null)
                        message.providerTimeZone = String(object.providerTimeZone);
                    if (object.defaultAvailabilityBlocks) {
                        if (!Array.isArray(object.defaultAvailabilityBlocks))
                            throw TypeError(".space.kenko.proto.ProviderSchemaProto.defaultAvailabilityBlocks: array expected");
                        message.defaultAvailabilityBlocks = [];
                        for (var i = 0; i < object.defaultAvailabilityBlocks.length; ++i) {
                            if (typeof object.defaultAvailabilityBlocks[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSchemaProto.defaultAvailabilityBlocks: object expected");
                            message.defaultAvailabilityBlocks[i] = $root.space.kenko.proto.AvailabilityBlockProto.fromObject(object.defaultAvailabilityBlocks[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {space.kenko.proto.ProviderSchemaProto} message ProviderSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.services = [];
                        object.appointments = [];
                        object.defaultAvailabilityBlocks = [];
                    }
                    if (options.defaults)
                        object.providerTimeZone = "";
                    if (message.services && message.services.length) {
                        object.services = [];
                        for (var j = 0; j < message.services.length; ++j)
                            object.services[j] = $root.space.kenko.proto.ServiceSchemaProto.toObject(message.services[j], options);
                    }
                    if (message.appointments && message.appointments.length) {
                        object.appointments = [];
                        for (var j = 0; j < message.appointments.length; ++j)
                            object.appointments[j] = $root.space.kenko.proto.ProviderAppointmentProto.toObject(message.appointments[j], options);
                    }
                    if (message.providerTimeZone != null && message.hasOwnProperty("providerTimeZone"))
                        object.providerTimeZone = message.providerTimeZone;
                    if (message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length) {
                        object.defaultAvailabilityBlocks = [];
                        for (var j = 0; j < message.defaultAvailabilityBlocks.length; ++j)
                            object.defaultAvailabilityBlocks[j] = $root.space.kenko.proto.AvailabilityBlockProto.toObject(message.defaultAvailabilityBlocks[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ProviderSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderSchemaProto";
                };

                return ProviderSchemaProto;
            })();

            proto.AvailabilityBlockProto = (function() {

                /**
                 * Properties of an AvailabilityBlockProto.
                 * @memberof space.kenko.proto
                 * @interface IAvailabilityBlockProto
                 * @property {number|null} [dayOfWeek] AvailabilityBlockProto dayOfWeek
                 * @property {space.kenko.proto.ILocalTimeProto|null} [start] AvailabilityBlockProto start
                 * @property {space.kenko.proto.ILocalTimeProto|null} [end] AvailabilityBlockProto end
                 */

                /**
                 * Constructs a new AvailabilityBlockProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an AvailabilityBlockProto.
                 * @implements IAvailabilityBlockProto
                 * @constructor
                 * @param {space.kenko.proto.IAvailabilityBlockProto=} [properties] Properties to set
                 */
                function AvailabilityBlockProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AvailabilityBlockProto dayOfWeek.
                 * @member {number} dayOfWeek
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @instance
                 */
                AvailabilityBlockProto.prototype.dayOfWeek = 0;

                /**
                 * AvailabilityBlockProto start.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} start
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @instance
                 */
                AvailabilityBlockProto.prototype.start = null;

                /**
                 * AvailabilityBlockProto end.
                 * @member {space.kenko.proto.ILocalTimeProto|null|undefined} end
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @instance
                 */
                AvailabilityBlockProto.prototype.end = null;

                /**
                 * Creates a new AvailabilityBlockProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IAvailabilityBlockProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.AvailabilityBlockProto} AvailabilityBlockProto instance
                 */
                AvailabilityBlockProto.create = function create(properties) {
                    return new AvailabilityBlockProto(properties);
                };

                /**
                 * Encodes the specified AvailabilityBlockProto message. Does not implicitly {@link space.kenko.proto.AvailabilityBlockProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IAvailabilityBlockProto} message AvailabilityBlockProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AvailabilityBlockProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dayOfWeek);
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.start, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        $root.space.kenko.proto.LocalTimeProto.encode(message.end, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AvailabilityBlockProto message, length delimited. Does not implicitly {@link space.kenko.proto.AvailabilityBlockProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.IAvailabilityBlockProto} message AvailabilityBlockProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AvailabilityBlockProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AvailabilityBlockProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.AvailabilityBlockProto} AvailabilityBlockProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AvailabilityBlockProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.AvailabilityBlockProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.dayOfWeek = reader.int32();
                                break;
                            }
                        case 2: {
                                message.start = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.end = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AvailabilityBlockProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.AvailabilityBlockProto} AvailabilityBlockProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AvailabilityBlockProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AvailabilityBlockProto message.
                 * @function verify
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AvailabilityBlockProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                        if (!$util.isInteger(message.dayOfWeek))
                            return "dayOfWeek: integer expected";
                    if (message.start != null && message.hasOwnProperty("start")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.start);
                        if (error)
                            return "start." + error;
                    }
                    if (message.end != null && message.hasOwnProperty("end")) {
                        var error = $root.space.kenko.proto.LocalTimeProto.verify(message.end);
                        if (error)
                            return "end." + error;
                    }
                    return null;
                };

                /**
                 * Creates an AvailabilityBlockProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.AvailabilityBlockProto} AvailabilityBlockProto
                 */
                AvailabilityBlockProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.AvailabilityBlockProto)
                        return object;
                    var message = new $root.space.kenko.proto.AvailabilityBlockProto();
                    if (object.dayOfWeek != null)
                        message.dayOfWeek = object.dayOfWeek | 0;
                    if (object.start != null) {
                        if (typeof object.start !== "object")
                            throw TypeError(".space.kenko.proto.AvailabilityBlockProto.start: object expected");
                        message.start = $root.space.kenko.proto.LocalTimeProto.fromObject(object.start);
                    }
                    if (object.end != null) {
                        if (typeof object.end !== "object")
                            throw TypeError(".space.kenko.proto.AvailabilityBlockProto.end: object expected");
                        message.end = $root.space.kenko.proto.LocalTimeProto.fromObject(object.end);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AvailabilityBlockProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {space.kenko.proto.AvailabilityBlockProto} message AvailabilityBlockProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AvailabilityBlockProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.dayOfWeek = 0;
                        object.start = null;
                        object.end = null;
                    }
                    if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
                        object.dayOfWeek = message.dayOfWeek;
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = $root.space.kenko.proto.LocalTimeProto.toObject(message.start, options);
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = $root.space.kenko.proto.LocalTimeProto.toObject(message.end, options);
                    return object;
                };

                /**
                 * Converts this AvailabilityBlockProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AvailabilityBlockProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AvailabilityBlockProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.AvailabilityBlockProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AvailabilityBlockProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.AvailabilityBlockProto";
                };

                return AvailabilityBlockProto;
            })();

            proto.InvitationProtos = (function() {

                /**
                 * Properties of an InvitationProtos.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProtos
                 * @property {Array.<space.kenko.proto.IInvitationProto>|null} [invitations] InvitationProtos invitations
                 */

                /**
                 * Constructs a new InvitationProtos.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProtos.
                 * @implements IInvitationProtos
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 */
                function InvitationProtos(properties) {
                    this.invitations = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProtos invitations.
                 * @member {Array.<space.kenko.proto.IInvitationProto>} invitations
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 */
                InvitationProtos.prototype.invitations = $util.emptyArray;

                /**
                 * Creates a new InvitationProtos instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos instance
                 */
                InvitationProtos.create = function create(properties) {
                    return new InvitationProtos(properties);
                };

                /**
                 * Encodes the specified InvitationProtos message. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invitations != null && message.invitations.length)
                        for (var i = 0; i < message.invitations.length; ++i)
                            $root.space.kenko.proto.InvitationProto.encode(message.invitations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProtos message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProtos();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.invitations && message.invitations.length))
                                    message.invitations = [];
                                message.invitations.push($root.space.kenko.proto.InvitationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProtos message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProtos.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invitations != null && message.hasOwnProperty("invitations")) {
                        if (!Array.isArray(message.invitations))
                            return "invitations: array expected";
                        for (var i = 0; i < message.invitations.length; ++i) {
                            var error = $root.space.kenko.proto.InvitationProto.verify(message.invitations[i]);
                            if (error)
                                return "invitations." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an InvitationProtos message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 */
                InvitationProtos.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProtos)
                        return object;
                    var message = new $root.space.kenko.proto.InvitationProtos();
                    if (object.invitations) {
                        if (!Array.isArray(object.invitations))
                            throw TypeError(".space.kenko.proto.InvitationProtos.invitations: array expected");
                        message.invitations = [];
                        for (var i = 0; i < object.invitations.length; ++i) {
                            if (typeof object.invitations[i] !== "object")
                                throw TypeError(".space.kenko.proto.InvitationProtos.invitations: object expected");
                            message.invitations[i] = $root.space.kenko.proto.InvitationProto.fromObject(object.invitations[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProtos message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.InvitationProtos} message InvitationProtos
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProtos.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.invitations = [];
                    if (message.invitations && message.invitations.length) {
                        object.invitations = [];
                        for (var j = 0; j < message.invitations.length; ++j)
                            object.invitations[j] = $root.space.kenko.proto.InvitationProto.toObject(message.invitations[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this InvitationProtos to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProtos.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProtos
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProtos.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProtos";
                };

                return InvitationProtos;
            })();

            proto.InvitationProto = (function() {

                /**
                 * Properties of an InvitationProto.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProto
                 * @property {string|null} [inviteCode] InvitationProto inviteCode
                 * @property {number|Long|null} [generatedTimestamp] InvitationProto generatedTimestamp
                 */

                /**
                 * Constructs a new InvitationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProto.
                 * @implements IInvitationProto
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 */
                function InvitationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProto inviteCode.
                 * @member {string} inviteCode
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.inviteCode = "";

                /**
                 * InvitationProto generatedTimestamp.
                 * @member {number|Long} generatedTimestamp
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.generatedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new InvitationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto instance
                 */
                InvitationProto.create = function create(properties) {
                    return new InvitationProto(properties);
                };

                /**
                 * Encodes the specified InvitationProto message. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
                    if (message.generatedTimestamp != null && Object.hasOwnProperty.call(message, "generatedTimestamp"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generatedTimestamp);
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProto message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.inviteCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.generatedTimestamp = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        if (!$util.isString(message.inviteCode))
                            return "inviteCode: string expected";
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (!$util.isInteger(message.generatedTimestamp) && !(message.generatedTimestamp && $util.isInteger(message.generatedTimestamp.low) && $util.isInteger(message.generatedTimestamp.high)))
                            return "generatedTimestamp: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an InvitationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 */
                InvitationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProto)
                        return object;
                    var message = new $root.space.kenko.proto.InvitationProto();
                    if (object.inviteCode != null)
                        message.inviteCode = String(object.inviteCode);
                    if (object.generatedTimestamp != null)
                        if ($util.Long)
                            (message.generatedTimestamp = $util.Long.fromValue(object.generatedTimestamp)).unsigned = false;
                        else if (typeof object.generatedTimestamp === "string")
                            message.generatedTimestamp = parseInt(object.generatedTimestamp, 10);
                        else if (typeof object.generatedTimestamp === "number")
                            message.generatedTimestamp = object.generatedTimestamp;
                        else if (typeof object.generatedTimestamp === "object")
                            message.generatedTimestamp = new $util.LongBits(object.generatedTimestamp.low >>> 0, object.generatedTimestamp.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.InvitationProto} message InvitationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.inviteCode = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedTimestamp = options.longs === String ? "0" : 0;
                    }
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        object.inviteCode = message.inviteCode;
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (typeof message.generatedTimestamp === "number")
                            object.generatedTimestamp = options.longs === String ? String(message.generatedTimestamp) : message.generatedTimestamp;
                        else
                            object.generatedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.generatedTimestamp) : options.longs === Number ? new $util.LongBits(message.generatedTimestamp.low >>> 0, message.generatedTimestamp.high >>> 0).toNumber() : message.generatedTimestamp;
                    return object;
                };

                /**
                 * Converts this InvitationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProto";
                };

                return InvitationProto;
            })();

            proto.UserProfileSchemaProto = (function() {

                /**
                 * Properties of a UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IUserProfileSchemaProto
                 * @property {Array.<space.kenko.proto.IClientAppointmentSchemaProto>|null} [appointments] UserProfileSchemaProto appointments
                 * @property {string|null} [name] UserProfileSchemaProto name
                 * @property {space.kenko.proto.IWellnessSurveySchemaProto|null} [wellnessSurvey] UserProfileSchemaProto wellnessSurvey
                 */

                /**
                 * Constructs a new UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a UserProfileSchemaProto.
                 * @implements IUserProfileSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 */
                function UserProfileSchemaProto(properties) {
                    this.appointments = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserProfileSchemaProto appointments.
                 * @member {Array.<space.kenko.proto.IClientAppointmentSchemaProto>} appointments
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.appointments = $util.emptyArray;

                /**
                 * UserProfileSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.name = "";

                /**
                 * UserProfileSchemaProto wellnessSurvey.
                 * @member {space.kenko.proto.IWellnessSurveySchemaProto|null|undefined} wellnessSurvey
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.wellnessSurvey = null;

                /**
                 * Creates a new UserProfileSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto instance
                 */
                UserProfileSchemaProto.create = function create(properties) {
                    return new UserProfileSchemaProto(properties);
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointments != null && message.appointments.length)
                        for (var i = 0; i < message.appointments.length; ++i)
                            $root.space.kenko.proto.ClientAppointmentSchemaProto.encode(message.appointments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.wellnessSurvey != null && Object.hasOwnProperty.call(message, "wellnessSurvey"))
                        $root.space.kenko.proto.WellnessSurveySchemaProto.encode(message.wellnessSurvey, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.appointments && message.appointments.length))
                                    message.appointments = [];
                                message.appointments.push($root.space.kenko.proto.ClientAppointmentSchemaProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserProfileSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProfileSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointments != null && message.hasOwnProperty("appointments")) {
                        if (!Array.isArray(message.appointments))
                            return "appointments: array expected";
                        for (var i = 0; i < message.appointments.length; ++i) {
                            var error = $root.space.kenko.proto.ClientAppointmentSchemaProto.verify(message.appointments[i]);
                            if (error)
                                return "appointments." + error;
                        }
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey")) {
                        var error = $root.space.kenko.proto.WellnessSurveySchemaProto.verify(message.wellnessSurvey);
                        if (error)
                            return "wellnessSurvey." + error;
                    }
                    return null;
                };

                /**
                 * Creates a UserProfileSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 */
                UserProfileSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UserProfileSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    if (object.appointments) {
                        if (!Array.isArray(object.appointments))
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: array expected");
                        message.appointments = [];
                        for (var i = 0; i < object.appointments.length; ++i) {
                            if (typeof object.appointments[i] !== "object")
                                throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: object expected");
                            message.appointments[i] = $root.space.kenko.proto.ClientAppointmentSchemaProto.fromObject(object.appointments[i]);
                        }
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.wellnessSurvey != null) {
                        if (typeof object.wellnessSurvey !== "object")
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.wellnessSurvey: object expected");
                        message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.fromObject(object.wellnessSurvey);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a UserProfileSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.UserProfileSchemaProto} message UserProfileSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProfileSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.appointments = [];
                    if (options.defaults) {
                        object.name = "";
                        object.wellnessSurvey = null;
                    }
                    if (message.appointments && message.appointments.length) {
                        object.appointments = [];
                        for (var j = 0; j < message.appointments.length; ++j)
                            object.appointments[j] = $root.space.kenko.proto.ClientAppointmentSchemaProto.toObject(message.appointments[j], options);
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey"))
                        object.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.toObject(message.wellnessSurvey, options);
                    return object;
                };

                /**
                 * Converts this UserProfileSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProfileSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserProfileSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProfileSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UserProfileSchemaProto";
                };

                return UserProfileSchemaProto;
            })();

            proto.WellnessSurveySchemaProto = (function() {

                /**
                 * Properties of a WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IWellnessSurveySchemaProto
                 * @property {Array.<string>|null} [holisticHealthInterests] WellnessSurveySchemaProto holisticHealthInterests
                 * @property {string|null} [familiarityWithEasternDietaryPractices] WellnessSurveySchemaProto familiarityWithEasternDietaryPractices
                 * @property {Array.<string>|null} [fitnessGoals] WellnessSurveySchemaProto fitnessGoals
                 * @property {string|null} [currentSkincareRoutine] WellnessSurveySchemaProto currentSkincareRoutine
                 * @property {Array.<string>|null} [hairRelatedConcerns] WellnessSurveySchemaProto hairRelatedConcerns
                 */

                /**
                 * Constructs a new WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a WellnessSurveySchemaProto.
                 * @implements IWellnessSurveySchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 */
                function WellnessSurveySchemaProto(properties) {
                    this.holisticHealthInterests = [];
                    this.fitnessGoals = [];
                    this.hairRelatedConcerns = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WellnessSurveySchemaProto holisticHealthInterests.
                 * @member {Array.<string>} holisticHealthInterests
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.holisticHealthInterests = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto familiarityWithEasternDietaryPractices.
                 * @member {string} familiarityWithEasternDietaryPractices
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.familiarityWithEasternDietaryPractices = "";

                /**
                 * WellnessSurveySchemaProto fitnessGoals.
                 * @member {Array.<string>} fitnessGoals
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.fitnessGoals = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto currentSkincareRoutine.
                 * @member {string} currentSkincareRoutine
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.currentSkincareRoutine = "";

                /**
                 * WellnessSurveySchemaProto hairRelatedConcerns.
                 * @member {Array.<string>} hairRelatedConcerns
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.hairRelatedConcerns = $util.emptyArray;

                /**
                 * Creates a new WellnessSurveySchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto instance
                 */
                WellnessSurveySchemaProto.create = function create(properties) {
                    return new WellnessSurveySchemaProto(properties);
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.holisticHealthInterests != null && message.holisticHealthInterests.length)
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.holisticHealthInterests[i]);
                    if (message.familiarityWithEasternDietaryPractices != null && Object.hasOwnProperty.call(message, "familiarityWithEasternDietaryPractices"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.familiarityWithEasternDietaryPractices);
                    if (message.fitnessGoals != null && message.fitnessGoals.length)
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fitnessGoals[i]);
                    if (message.currentSkincareRoutine != null && Object.hasOwnProperty.call(message, "currentSkincareRoutine"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentSkincareRoutine);
                    if (message.hairRelatedConcerns != null && message.hairRelatedConcerns.length)
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.hairRelatedConcerns[i]);
                    return writer;
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.holisticHealthInterests && message.holisticHealthInterests.length))
                                    message.holisticHealthInterests = [];
                                message.holisticHealthInterests.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.familiarityWithEasternDietaryPractices = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.fitnessGoals && message.fitnessGoals.length))
                                    message.fitnessGoals = [];
                                message.fitnessGoals.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.currentSkincareRoutine = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.hairRelatedConcerns && message.hairRelatedConcerns.length))
                                    message.hairRelatedConcerns = [];
                                message.hairRelatedConcerns.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WellnessSurveySchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WellnessSurveySchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.holisticHealthInterests != null && message.hasOwnProperty("holisticHealthInterests")) {
                        if (!Array.isArray(message.holisticHealthInterests))
                            return "holisticHealthInterests: array expected";
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            if (!$util.isString(message.holisticHealthInterests[i]))
                                return "holisticHealthInterests: string[] expected";
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        if (!$util.isString(message.familiarityWithEasternDietaryPractices))
                            return "familiarityWithEasternDietaryPractices: string expected";
                    if (message.fitnessGoals != null && message.hasOwnProperty("fitnessGoals")) {
                        if (!Array.isArray(message.fitnessGoals))
                            return "fitnessGoals: array expected";
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            if (!$util.isString(message.fitnessGoals[i]))
                                return "fitnessGoals: string[] expected";
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        if (!$util.isString(message.currentSkincareRoutine))
                            return "currentSkincareRoutine: string expected";
                    if (message.hairRelatedConcerns != null && message.hasOwnProperty("hairRelatedConcerns")) {
                        if (!Array.isArray(message.hairRelatedConcerns))
                            return "hairRelatedConcerns: array expected";
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            if (!$util.isString(message.hairRelatedConcerns[i]))
                                return "hairRelatedConcerns: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a WellnessSurveySchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 */
                WellnessSurveySchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.WellnessSurveySchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    if (object.holisticHealthInterests) {
                        if (!Array.isArray(object.holisticHealthInterests))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.holisticHealthInterests: array expected");
                        message.holisticHealthInterests = [];
                        for (var i = 0; i < object.holisticHealthInterests.length; ++i)
                            message.holisticHealthInterests[i] = String(object.holisticHealthInterests[i]);
                    }
                    if (object.familiarityWithEasternDietaryPractices != null)
                        message.familiarityWithEasternDietaryPractices = String(object.familiarityWithEasternDietaryPractices);
                    if (object.fitnessGoals) {
                        if (!Array.isArray(object.fitnessGoals))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.fitnessGoals: array expected");
                        message.fitnessGoals = [];
                        for (var i = 0; i < object.fitnessGoals.length; ++i)
                            message.fitnessGoals[i] = String(object.fitnessGoals[i]);
                    }
                    if (object.currentSkincareRoutine != null)
                        message.currentSkincareRoutine = String(object.currentSkincareRoutine);
                    if (object.hairRelatedConcerns) {
                        if (!Array.isArray(object.hairRelatedConcerns))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.hairRelatedConcerns: array expected");
                        message.hairRelatedConcerns = [];
                        for (var i = 0; i < object.hairRelatedConcerns.length; ++i)
                            message.hairRelatedConcerns[i] = String(object.hairRelatedConcerns[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WellnessSurveySchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.WellnessSurveySchemaProto} message WellnessSurveySchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WellnessSurveySchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.holisticHealthInterests = [];
                        object.fitnessGoals = [];
                        object.hairRelatedConcerns = [];
                    }
                    if (options.defaults) {
                        object.familiarityWithEasternDietaryPractices = "";
                        object.currentSkincareRoutine = "";
                    }
                    if (message.holisticHealthInterests && message.holisticHealthInterests.length) {
                        object.holisticHealthInterests = [];
                        for (var j = 0; j < message.holisticHealthInterests.length; ++j)
                            object.holisticHealthInterests[j] = message.holisticHealthInterests[j];
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        object.familiarityWithEasternDietaryPractices = message.familiarityWithEasternDietaryPractices;
                    if (message.fitnessGoals && message.fitnessGoals.length) {
                        object.fitnessGoals = [];
                        for (var j = 0; j < message.fitnessGoals.length; ++j)
                            object.fitnessGoals[j] = message.fitnessGoals[j];
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        object.currentSkincareRoutine = message.currentSkincareRoutine;
                    if (message.hairRelatedConcerns && message.hairRelatedConcerns.length) {
                        object.hairRelatedConcerns = [];
                        for (var j = 0; j < message.hairRelatedConcerns.length; ++j)
                            object.hairRelatedConcerns[j] = message.hairRelatedConcerns[j];
                    }
                    return object;
                };

                /**
                 * Converts this WellnessSurveySchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WellnessSurveySchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WellnessSurveySchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WellnessSurveySchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.WellnessSurveySchemaProto";
                };

                return WellnessSurveySchemaProto;
            })();

            proto.ClientAppointmentSchemaProto = (function() {

                /**
                 * Properties of a ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IClientAppointmentSchemaProto
                 * @property {string|null} [appointmentId] ClientAppointmentSchemaProto appointmentId
                 * @property {number|Long|null} [startTimeMs] ClientAppointmentSchemaProto startTimeMs
                 * @property {number|Long|null} [endTimeMs] ClientAppointmentSchemaProto endTimeMs
                 * @property {string|null} [serviceName] ClientAppointmentSchemaProto serviceName
                 * @property {string|null} [providerName] ClientAppointmentSchemaProto providerName
                 */

                /**
                 * Constructs a new ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ClientAppointmentSchemaProto.
                 * @implements IClientAppointmentSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 */
                function ClientAppointmentSchemaProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ClientAppointmentSchemaProto appointmentId.
                 * @member {string} appointmentId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.appointmentId = "";

                /**
                 * ClientAppointmentSchemaProto startTimeMs.
                 * @member {number|Long} startTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto endTimeMs.
                 * @member {number|Long} endTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto serviceName.
                 * @member {string} serviceName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.serviceName = "";

                /**
                 * ClientAppointmentSchemaProto providerName.
                 * @member {string} providerName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.providerName = "";

                /**
                 * Creates a new ClientAppointmentSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto instance
                 */
                ClientAppointmentSchemaProto.create = function create(properties) {
                    return new ClientAppointmentSchemaProto(properties);
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
                    if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
                    if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
                    if (message.serviceName != null && Object.hasOwnProperty.call(message, "serviceName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceName);
                    if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerName);
                    return writer;
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appointmentId = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.serviceName = reader.string();
                                break;
                            }
                        case 5: {
                                message.providerName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClientAppointmentSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClientAppointmentSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        if (!$util.isString(message.appointmentId))
                            return "appointmentId: string expected";
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                            return "startTimeMs: integer|Long expected";
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                            return "endTimeMs: integer|Long expected";
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        if (!$util.isString(message.serviceName))
                            return "serviceName: string expected";
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        if (!$util.isString(message.providerName))
                            return "providerName: string expected";
                    return null;
                };

                /**
                 * Creates a ClientAppointmentSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 */
                ClientAppointmentSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ClientAppointmentSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    if (object.appointmentId != null)
                        message.appointmentId = String(object.appointmentId);
                    if (object.startTimeMs != null)
                        if ($util.Long)
                            (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                        else if (typeof object.startTimeMs === "string")
                            message.startTimeMs = parseInt(object.startTimeMs, 10);
                        else if (typeof object.startTimeMs === "number")
                            message.startTimeMs = object.startTimeMs;
                        else if (typeof object.startTimeMs === "object")
                            message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                    if (object.endTimeMs != null)
                        if ($util.Long)
                            (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                        else if (typeof object.endTimeMs === "string")
                            message.endTimeMs = parseInt(object.endTimeMs, 10);
                        else if (typeof object.endTimeMs === "number")
                            message.endTimeMs = object.endTimeMs;
                        else if (typeof object.endTimeMs === "object")
                            message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                    if (object.serviceName != null)
                        message.serviceName = String(object.serviceName);
                    if (object.providerName != null)
                        message.providerName = String(object.providerName);
                    return message;
                };

                /**
                 * Creates a plain object from a ClientAppointmentSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.ClientAppointmentSchemaProto} message ClientAppointmentSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClientAppointmentSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.appointmentId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMs = options.longs === String ? "0" : 0;
                        object.serviceName = "";
                        object.providerName = "";
                    }
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        object.appointmentId = message.appointmentId;
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (typeof message.startTimeMs === "number")
                            object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                        else
                            object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (typeof message.endTimeMs === "number")
                            object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                        else
                            object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        object.serviceName = message.serviceName;
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        object.providerName = message.providerName;
                    return object;
                };

                /**
                 * Converts this ClientAppointmentSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClientAppointmentSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ClientAppointmentSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClientAppointmentSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ClientAppointmentSchemaProto";
                };

                return ClientAppointmentSchemaProto;
            })();

            return proto;
        })();

        return kenko;
    })();

    return space;
})();

module.exports = $root;
