import React, {useContext} from 'react';
import './Provider.css';
import NewProviderSurvey from "./Survey/NewProviderSurvey";
import {ProviderProfileContext} from "../../ProviderProfileProvider";
import {Routes} from "react-router";
import {Route} from "react-router-dom";
import {DeleteBusinessDebug} from "./Sections/DeleteBusinessDebug";
import {Services} from "./Sections/Services/Services";
import {LeftNavIcon} from "./LeftNavIcon";
import {MySchedule} from "./Schedule/MySchedule";
import {CreateProviderProfile} from "./Survey/CreateProviderProfile";
import {Home} from "./Sections/Home";
import {BusinessProfile} from "./Sections/BusinessProfile/BusinessProfile";
import {AddOrUpdateService} from "./Sections/Services/AddOrUpdateService";
import {Team} from "./Sections/Team/Team";
import {AddOrUpdateTeam} from "./Sections/Team/AddOrUpdateTeam";
import {Website} from "./Sections/Website/Website";

/**
 * The user is guaranteed to be logged in at this point because the
 * authentication happens in App.tsx.
 */
export const ProviderHome = () => {

  const {provider, businessProfile} = useContext(ProviderProfileContext);

  // This should never happen here cause the parent state forbids it.
  if (!provider || !businessProfile || !businessProfile.providers) {
    return <div>Error, no profile</div>;
  }

  // If this is empty the provider proto has to be created; this is the first
  // step of onboarding after redeeming the invite.
  let providerIndex = -1;
  for (let i = 0; i < businessProfile.providers.length; i++) {
    if (businessProfile.providers[i].providerId == provider.providerId) {
      providerIndex = i;
      break;
    }
  }

  // The person doesn't have any details listed out in the business.
  if (providerIndex < 0) {
    return <CreateProviderProfile/>
  }

  // Second step in the onboarding is the provider survey. This first draft
  // version is only created after the survey is taken.
  // Survey has to be taken.
  if (!businessProfile?.proto) {
    return <NewProviderSurvey/>;
  }
  
  const isAdmin = provider.roles && provider.roles.indexOf('admin') >= 0;

  return (<div className={'ProviderMainArea'}>

        {/* The left nav bar containing all the tools */}
        <div className={'LeftNav'}>
          <LeftNavIcon text='Home' icon={require('../../images/icons/home.png')} link={'/'}/>
          <LeftNavIcon text='Calendar' icon={require('../../images/icons/calendar_today.png')} link={'/schedule'}/>
          {/*<LeftNavIcon text='Clients' icon={require('../../images/icons/contacts.png')} link={'/'}/>*/}
          {isAdmin && <LeftNavIcon text='Services' icon={require('../../images/icons/format_list_bulleted.png')} link={'/services'}/>}
          <LeftNavIcon text='Team' icon={require('../../images/icons/groups.png')} link={'/team'}/>
          {/*<LeftNavIcon text='Data' icon={require('../../images/icons/monitoring.png')} link={'#'}/>*/}
          {isAdmin && <LeftNavIcon text='Business Profile' icon={require('../../images/icons/contact_page.png')} link={'/profile'}/>}
          {isAdmin && <LeftNavIcon text='Website' icon={require('../../images/icons/web.png')} link={'/website'}/>}
          {/*<LeftNavIcon text='Tools' icon={require('../../images/icons/stacks.png')} link={'#'}/>*/}
          {/*<LeftNavIcon text='Settings' icon={require('../../images/icons/settings.png')} link={'#'}/>*/}
          {isAdmin && <LeftNavIcon text='Debug Tools' icon={require('../../images/icons/build.png')} link={'/delete-business'}/>}
          <br/>
        </div>


        {/* The main body of the tool- And the right which takes up the most of the page*/}
        <div className='ProviderToolArea'>

          <Routes>
            <Route path={'/*'} element={<Home/>}/>
            {isAdmin && <Route path={'/profile'} element={<BusinessProfile/>}/>}
            <Route path={'/schedule'} element={<MySchedule/>}/>
            {isAdmin && <Route path={'/services/add'} element={<AddOrUpdateService/>}/>}
            {isAdmin && <Route path={'/services/edit/:skuParam'} element={<AddOrUpdateService/>}/>}
            {isAdmin && <Route path={'/services'} element={<Services/>}/>}
            {isAdmin && <Route path={'/team/add'} element={<AddOrUpdateTeam/>}/>}
            <Route path={'/team/edit/:providerIdParam'} element={<AddOrUpdateTeam/>}/>
            <Route path={'/team'} element={<Team/>}/>
            {isAdmin && <Route path={'/website'} element={<Website/>}/>}
            {isAdmin && <Route path={'/delete-business'} element={<DeleteBusinessDebug/>}/>}
          </Routes>

        </div>
      </div>
  );
}
