import React, {useState} from 'react';
import {UpdateBusinessProfileRequest, UpdateBusinessProfileResponse} from "../../../provider_api";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../rpcSender";
import './NewProviderSurvey.css';
import {useNavigate} from "react-router-dom";
import SurveyMapInput from "./SurveyMapInput";
import {useProviderProfile} from "../../../ProviderProfileProvider";

const Survey = () => {

  const {businessProfile, provider, setBusinessProfile, setProvider} = useProviderProfile();

  const {getIdTokenClaims} = useAuth0();

  const [currentStep, setCurrentStep] = useState(0);

  // Survey results are kept as a plain JSON object and then converted to proto
  // at the end. This turned out way easier than trying to convert types and mash
  // things into a proto right away.
  const [surveyResults, setSurveyResults] = useState<{ [key: string]: any }>({});

  // Client-side error validating one of the fields
  const [validationError, setValidationError] = useState<string | null>(null);

  // If there was an RPC error sending the profile.
  const [updateProfileError, setUpdateProfileError] = useState('');

  const navigate = useNavigate();

  const sendUpdateBusinessProfileRequest = () => {

    return new Promise<UpdateBusinessProfileResponse>((resolve, reject) => {

          // Build up the thing from the survey questions
          const request = UpdateBusinessProfileRequest.create({
            businessProfile: {
              proto: {},
            }
          });

          surveyPages.forEach(page => {
            if (page.visibleWhen(surveyResults) && surveyResults[page.name]) {
              page.setter(request, surveyResults[page.name])
            }
          });

          console.log('creating profile with', JSON.stringify(request, null, 2));

          let encoded: Uint8Array = UpdateBusinessProfileRequest.encode(request).finish();

          SendRpc(getIdTokenClaims, 'update_business_profile', encoded)
              .then(value => {
                resolve(UpdateBusinessProfileResponse.decode(value));
              })
              .catch(e => {
                reject(e);
              });
        }
    )
  };

  const shortUrlRegex = /^[a-z]+(-[a-z]+)*$/

  type surveyPage = {
    name: string,
    title: () => string,
    subtitle?: () => string,
    type: string,
    choices?: { value: string, text: string }[],
    visibleWhen: (results: any) => boolean,
    setter: (request: UpdateBusinessProfileRequest, value: any) => void,

    // Optional -- in case you need to handle something when the value changes.
    // Like setting a suggested short url from the provider name..
    onValueChange?: (value: any) => void,
    isValidValue: (value: any) => boolean,
    validationMessage: string,
    initialValue?: any,
  }

  const surveyPages: surveyPage[] = [
    {
      name: 'BusinessName',
      title: () => 'What\'s your business name?',
      subtitle: () => 'This is the name that your clients will see. You can add your billing and legal name later.',
      type: 'text',
      visibleWhen: () => true,
      setter: (request: UpdateBusinessProfileRequest, value: string) => {
        if (request.businessProfile?.proto) {
          request.businessProfile.proto.businessName = value.trim();
        }
      },
      isValidValue: (value) => value?.trim().length > 4,
      onValueChange: (value: string) => {

        // They can change this later but here's an easy version.
        let suggestedShortUrl = value.trim();

        suggestedShortUrl = suggestedShortUrl.toLowerCase();

        // All whitespace with a single dash
        suggestedShortUrl = suggestedShortUrl.replace(/[\s]+/g, "-");

        // Remove all non-numbers and non-lowercase letters
        suggestedShortUrl = suggestedShortUrl.replace(/[^a-z0-9-]+/g, "");

        console.log('suggesting short url', suggestedShortUrl)

        // It's possible that the survey results get mutated multiple
        // times before the next render! Because the onValueChange
        // also can do a mutate. Therefore use a function to change it.
        setSurveyResults(surveyResults => {
          return {
            ...surveyResults,
            'ShortUrl': suggestedShortUrl
          }
        });
      },
      validationMessage: 'Please enter a little longer name.',
    },
    {
      name: 'BusinessWebsite',
      title: () => 'Please enter your existing web address (optional)',
      subtitle: () => 'Leave this empty if you do not have one.',
      type: 'text',
      visibleWhen: () => true,
      setter: (request: UpdateBusinessProfileRequest, value: string) => {
        if (request.businessProfile?.proto) {
          request.businessProfile.proto.websiteUrl = value.trim();
        }
      },
      isValidValue: (value) => true,
      validationMessage: '',
    },

    {
      name: 'Services',
      title: () => 'What services do you offer?',
      subtitle: () => 'Select all that apply.',
      type: 'checklist',
      visibleWhen: () => true,
      choices: [
        {value: "Acupuncture", text: 'Acupuncture'},
        {value: "Traditional Chinese Medicine", text: 'Traditional Chinese Medicine'},
        {value: 'Diet & Nutrition', text: 'Diet & Nutrition'},
        {value: 'Massage Therapy', text: 'Massage Therapy'},
        {value: 'Sound Therapy', text: 'Sound Therapy'},
        {value: 'Meditation', text: 'Meditation'},
        {value: 'Naturopathy', text: 'Naturopathy'},
        {value: 'Homeopathy', text: 'Homeopathy'},
        {value: 'Yoga', text: 'Yoga'},
        {value: 'Chiropractic', text: 'Chiropractic'},
        {value: 'Physical Therapy', text: 'Physical Therapy'},
        {value: 'Other', text: 'Other'},
      ],
      setter: (request: UpdateBusinessProfileRequest, value: string) => {
      },
      isValidValue: (value) => {
        return value != null;
      },
      validationMessage: 'Please select one',
    },

    {
      name: 'Sessions',
      title: () => 'What types of sessions do you offer?',
      subtitle: () => 'Select all that apply.',
      type: 'checklist',
      visibleWhen: () => true,
      choices: [
        {value: "Private", text: 'Private 1:1 sessions'},
        {value: "Semi-private", text: 'Semi-private sessions (2+ clients)'},
        {value: "Group classes", text: 'Group Classes'},
      ],
      setter: (request: UpdateBusinessProfileRequest, value: string) => {
      },
      isValidValue: (value: string[]) => {
        return value == null || value.indexOf('Group classes') < 0;
      },
      validationMessage: 'Sorry, classes are not supported just yet! Check back soon.',
    },

    {
      name: 'ServiceLocations',
      title: () => 'Where do you offer your services?',
      subtitle: () => 'Select all that apply. Our platform supports multiple office locations as well as online-only businesses.',
      type: 'checklist',
      visibleWhen: () => true,
      choices: [
        {value: "Office", text: 'In-person at a business location(s)'},
        {value: "Virtual", text: 'Virtual appointments'},
        {value: "In-Home", text: 'I travel to my clients (e.g., in-home appointments)'},
      ],
      setter: (request: UpdateBusinessProfileRequest, value: string) => {
      },
      isValidValue: (value: string[]) => {
        return value && value.length > 0;
      },
      validationMessage: 'Please choose at least one.',
    },

    {
      name: 'Location',
      title: () => 'Set your location',
      type: 'location',
      visibleWhen: (results) => (results['ServiceLocations'].indexOf('Office') >= 0 ||
          results['ServiceLocations'].indexOf('In-Home') >= 0),
      setter: (request: UpdateBusinessProfileRequest, location: google.maps.places.PlaceResult | null) => {
        // request.address = location?.formatted_address || "";
        // request.lat = location?.geometry?.location?.lat() || 0
        // request.lng = location?.geometry?.location?.lng() || 0
      },
      isValidValue: (value) => {
        return value != null;
      },
      validationMessage: 'Please choose a location',
    },

    // {
    //   name: 'Description',
    //   title: () => 'Tell us about your practice!',
    //   subtitle: () => 'Please make it descriptive! This will show up on your landing page. 2-3 paragraphs is ideal.',
    //   type: 'textarea',
    //   visibleWhen: (results) => true,
    //   setter: (request: UpdateBusinessProfileRequest, value: string) => {
    //     request.businessDescription = value;
    //   },
    //   isValidValue: (value) => true,
    //   validationMessage: 'Please enter a valid description. ',
    // },

    {
      name: 'AreYouPractitioner',
      title: () => 'Are you a practitioner?',
      subtitle: () => "If so, we'll add you as a practitioner. Select no " +
          "if you are solely an admin for this business.",
      type: 'radio',
      visibleWhen: (results) => true,
      choices: [
        {value: "true", text: 'Yes'},
        {value: "false", text: 'No'},
      ],
      setter: (request: UpdateBusinessProfileRequest, value: string) => {
      },
      isValidValue: (value) => {
        return value != null;
      },
      validationMessage: 'Please select one',
    },
      
    {
      name: 'ShortUrl',
      title: () => 'Please choose a short url for your booking page',
      subtitle: () => 'This must contain only lowercase letters and dashes only, and be at least 8 characters long. ' +
          '(e.g `fur-by-gordie` or `joes-hair-salon`)',
      type: 'text',
      visibleWhen: (results) => true,
      setter: (request: UpdateBusinessProfileRequest, value: string) => {
        if (request.businessProfile?.proto) {
          request.businessProfile.proto.shortUrl = value;
        }
      },
      isValidValue: (value) => {
        console.log('testing', value)
        return value && value.length >= 8 && (value as string).match(shortUrlRegex);
      },
      validationMessage: 'Please enter a valid short URL at least 8 characters long. ',
    },
  ];

  /**
   * @param results - the current survey results. this typically uses the react state
   *                  "surveyResults", but you can also pass in a modified once, which is
   *                  necessary when you mutate state (e.g. select a radio button) AND
   *                  want to figure out the next state immediately.
   *
   * @return {int} the next visible step, or the array length if there are no further pages.
   * By returning the array length we know that the
   */
  const getNextVisibleStep = (results: any) => {
    for (let i = currentStep + 1; i < surveyPages.length; i++) {
      if (surveyPages[i].visibleWhen(results)) {
        return i;
      }
    }

    return surveyPages.length;
  };

  /**
   * @return {int} The previous step or -1 if there is no previous step
   */
  const getPreviousVisibleStep = (results: any) => {
    for (let i = currentStep - 1; i >= 0; i--) {
      if (surveyPages[i].visibleWhen(results)) {
        return i;
      }
    }

    return -1;
  };

  const page = surveyPages[currentStep];

  /**
   *
   * @param results the most recent survey results--- make sure not to grab the state
   *                variable if you have mutated it in this frame! pass a new copy.
   *                this is important when using radio buttons
   */
  const advancePage = (results: any) => {
    const nextStep = getNextVisibleStep(results);

    if (nextStep < surveyPages.length) {
      // There's still another page to go. Advance the step

      setCurrentStep(nextStep);
      setValidationError(null);

      const nextPage = surveyPages[nextStep];

      // Set the default value! This only needs to be done in the forward direction.
      // When the page gets rendered the first time the default value (if there is one)
      // will be put in the survey results. 
      if (surveyResults[nextPage.name] == null && nextPage.initialValue != null) {
        console.log('[Survey] Populating initial value for step', nextPage.name,
            nextPage.initialValue);

        setSurveyResults({
          ...results,
          [nextPage.name]: nextPage.initialValue,
        });
      }
    } else {

      // This is the last step! Kick off the RPC. Do not advance the page.
      sendUpdateBusinessProfileRequest().then(profile => {

        if (profile.profile) {
          console.info('[Survey] Profile send success!');

          setBusinessProfile(profile.profile)

          // Would be better to use navigate but oh well.
          navigate('/')

        } else {
          console.warn('[Survey] Profile send failure!', profile.profileErrors);
          setUpdateProfileError(profile.profileErrors.join(", "));
          return;
        }

      }).catch(e => {
        console.warn('[Survey] Profile send failure!', e);
        setUpdateProfileError(e);
      });
    }

  };

  const goBackAPage = (results: any) => {
    // This just clears out the RPC error when going back
    setUpdateProfileError('');
    setCurrentStep(getPreviousVisibleStep(results));
    setValidationError(null);
  };

  // This is a null parameter if there is no next step available which hides the button
  const onBackPress = (getPreviousVisibleStep(surveyResults) >= 0) ? (() => {
        goBackAPage(surveyResults);
      })
      : null;

  // This gets happen on 'OK' press AND 'enter' press in text box.
  const onContinue = () => {

    if (!page.isValidValue(surveyResults[page.name])) {
      setValidationError(page.validationMessage);
    } else {

      // It's okay to use the state variable to advance a page here because
      // there is a re-render between the time the users clicks a value and
      // hits the continue button.
      advancePage(surveyResults);
    }
  };

  return (<>

    { /*progress bar*/}
    <div className={'SurveyProgressBar'}>
      {new Array(5).map((_, i) => {
        return (
            <div key={i} className={currentStep > i ?
                'SurveyProgressBarItem SurveyProgressBarItemSelected' :
                'SurveyProgressBarItem SurveyProgressBarItemNotSelected'}/>
        );
      })}
    </div>

    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent'}>
        <div className='SurveySection'>


          <span className={'SurveyTitle'}>{page.title()}</span>

          {page.subtitle && <div className={'SurveySubtitle'}>{page.subtitle()}</div>}

          {
              page.type == 'text' && (
                  <input type={'text'} className={'SurveyTextInput'}
                         placeholder={'Enter text here'}
                         value={surveyResults[page.name] || ''}
                         onKeyPress={event => {
                           if (event.key == 'Enter') {
                             onContinue()
                           }
                         }}
                         onChange={event => {

                           // If the onValueChange callback exists then call that.
                           page.onValueChange && page.onValueChange(event.target.value);

                           // It's possible that the survey results get mutated multiple
                           // times before the next render! Because the onValueChange
                           // also can do a mutate. Therefore use a function to change it.
                           setSurveyResults(surveyResults => {
                                 return {
                                   ...surveyResults,
                                   [page.name]: event.target.value,
                                 }
                               }
                           );
                         }}
                  />
              )
          }

          {
              page.type == 'textarea' && (
                  <textarea rows={10} cols={50} style={{
                    borderWidth: 1,
                    padding: 10,
                  }}
                            placeholder={'Enter text here'}
                            value={surveyResults[page.name] || ''}
                            onChange={event => {

                              // If the onValueChange callback exists then call that.
                              page.onValueChange && page.onValueChange(event.target.value);

                              // It's possible that the survey results get mutated multiple
                              // times before the next render! Because the onValueChange
                              // also can do a mutate. Therefore use a function to change it.
                              setSurveyResults(surveyResults => {
                                    return {
                                      ...surveyResults,
                                      [page.name]: event.target.value,
                                    }
                                  }
                              );
                            }}
                  />
              )
          }


          {
              page.type == 'radio' && page.choices?.map((choice, i) => {

                // This is essentially the same as a checklist except only one is selected
                return (
                    <div
                        key={i}
                        className={'survey-checklist ' +
                            ((surveyResults[page.name] == choice.value) ? 'selected' : 'notSelected')}
                        onClick={() => {
                          const newSurveyResults = {
                            ...surveyResults,
                            [page.name]: choice.value,
                          };

                          setSurveyResults(newSurveyResults);
                        }}>
                      {choice.text}
                    </div>);
              })
          }

          {
              page.type == 'checklist' && page.choices?.map((choice, i) => {
                return (
                    <button
                        key={i}
                        className={'survey-checklist ' +
                            ((surveyResults[page.name]?.includes(choice.value)) ? 'selected' : 'notSelected')}
                        onClick={() => {

                          if (!surveyResults[page.name]) {
                            // The list was empty. Create new.
                            setSurveyResults({
                              ...surveyResults,
                              [page.name]: [choice.value],
                            });

                          } else if (surveyResults[page.name].includes(choice.value)) {
                            // Deselecting (remove each instance from list)
                            setSurveyResults({
                              ...surveyResults,
                              [page.name]: surveyResults[page.name].filter(
                                  (item: any) => {
                                    return item != choice.value;
                                  }),
                            });

                          } else {
                            // Selecting (append to list)
                            setSurveyResults({
                              ...surveyResults,
                              [page.name]: [
                                ...surveyResults[page.name],
                                (choice.value)],
                            });
                          }
                        }}>
                      {
                        choice.text
                      }
                    </button>);
              })
          }

          {page.type == 'location' && <SurveyMapInput onLocationPicked={(location) => {
            setSurveyResults({
              ...surveyResults,
              [page.name]: location
            });
          }}/>}

          {validationError && (<div className='SurveyError'>{validationError}</div>)}

          <button className='SurveyContinueButton' style={{}} onClick={onContinue}>
            OK
          </button>

          {updateProfileError &&
              (<div className={'SurveyError'}>Error building your profile: {updateProfileError}</div>)
          }

          {onBackPress &&
              <button style={{
                width: 120,
                height: 20,
                marginTop: 30,
                padding: 15,
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'gray',
                border: '1px solid lightgray',
                borderRadius: 5,
                fontFamily: 'Nunito',
                fontSize: 16,
                overflow: 'hidden',
              }} onClick={() => {
                onBackPress();
              }}>
                Back
              </button>
          }

          <div style={{fontSize: 'smaller', color: '#ccc'}}>
            {JSON.stringify(surveyResults)}
          </div>

        </div>
      </div>

    </div>
  </>);
};

export default Survey;