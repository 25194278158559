import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../../rpcSender";
import {
  GetWebsiteVersionsRequest,
  GetWebsiteVersionsResponse,
  IApiBusinessProfileProto, SetLiveVersionRequest, SetLiveVersionResponse,
  space,
  SubmitProfileForReviewRequest,
  SubmitProfileForReviewResponse
} from "../../../../provider_api";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import './Website.css'
import ApprovalStatus = space.kenko.proto.ApprovalStatus;

export const Website = () => {

  const {getIdTokenClaims} = useAuth0();

  const {provider} = useProviderProfile();

  const [rpcError, setRpcError] = useState<string>();
  const [versions, setVersions] = useState<IApiBusinessProfileProto[]>([]);
  const [liveVersion, setLiveVersion] = useState<string>();

  const getBookingHost = () => {
    if (window.location.host.startsWith("localhost")) {
      return "http://localhost:3001";
    } else if (window.location.host.endsWith("thymebook.com")) {
      return "https://thymebook.com";
    }
    
    return "https://kenko.space";
  }
  
  useEffect(() => {

    SendRpc(getIdTokenClaims, "get_website_versions",
        GetWebsiteVersionsRequest.encode(new GetWebsiteVersionsRequest()).finish())
        .then(bytes => {
          const response = GetWebsiteVersionsResponse.decode(bytes)
          if (!response.okay) {
            setRpcError('Error loading website versions');
            return;
          }
          setVersions(response.profiles);
          setLiveVersion(response.liveVersion);
        })
        .catch(e => {
          setRpcError(e);
        })

  }, [])

  const submit = () => {
    setRpcError('');
    SendRpc(getIdTokenClaims, "submit_profile_for_review",
        SubmitProfileForReviewRequest.encode(new SubmitProfileForReviewRequest()).finish())
        .then(bytes => {
          const response = SubmitProfileForReviewResponse.decode(bytes)
          if (!response.okay) {
            setRpcError(response.errors || 'Error submitting for review');
            return;
          }

          if (response.newProfile) {
            setVersions([...versions, response.newProfile]);
          }
        })
        .catch(e => {
          setRpcError(e);
        })
  }


  const sendSetLiveVersion = (version: string) => {
    setRpcError('');
    SendRpc(getIdTokenClaims, "set_live_version",
        SetLiveVersionRequest.encode(new SetLiveVersionRequest({
          version: version
        })).finish())
        .then(bytes => {
          const response = SetLiveVersionResponse.decode(bytes)
          if (!response.okay) {
            setRpcError(response.error || 'Unknown error setting live version');
            return;
          }

          if (response.liveVersion) {
            setLiveVersion(response.liveVersion);
          }
        })
        .catch(e => {
          setRpcError(e);
        })
  }
  
  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Your website</h1>
        <Link target={'_blank'}
              to={`${getBookingHost()}/p/test?v=DRAFT&b=${provider?.businessId}`}>
          <button className={'BusinessProfileButton DarkButton'}>
            Preview Website
          </button>
        </Link>
      </div>
    </div>

    <div className={'VersionHistoryTable'}>
      <table>
        <thead>
        <tr>
          <th>Version</th>
          <th>Last Edited</th>
          <th>Status</th>
          <th>Website Link</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {versions?.sort((a, b) => -(a.versionId || '').localeCompare(b.versionId || ''))
            .map(version => {
              const isLiveVersion = version.versionId == liveVersion;
              return <tr>
                <td>{version.versionId}</td>
                <td>{version.lastModifiedTimestamp ? new Date(version.lastModifiedTimestamp as number).toLocaleString() : ''}</td>
                <td>{
                  isLiveVersion ? <div className={'LiveVersion'}>LIVE</div> :
                  version.review?.status ? ApprovalStatus[version.review.status] : ''}
                </td>
                <td>
                  <Link target={'_blank'} to={
                    isLiveVersion ? `${getBookingHost()}/p/${version.proto?.shortUrl}` : 
                    `${getBookingHost()}/p/test?v=${version.versionId}&b=${provider?.businessId}`
                  }>
                    Website link
                  </Link>
                </td>
                <td>
                  {!version.review?.status &&
                    <button className={'SubmitButton'} onClick={submit}>submit for review</button>}

                  {version.review?.status == ApprovalStatus.APPROVED &&
                  <button className={'SubmitButton'} onClick={() => sendSetLiveVersion(version.versionId || '')}>publish</button>}
                
                </td>
              </tr>
            })}

        </tbody>
      </table>
    </div>

    {rpcError && <div className={'ErrorText'}>{rpcError}</div>}

  </div>

}