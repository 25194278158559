import React, {useEffect, useState} from 'react';
import {CreateServiceRequest, CreateServiceResponse, space} from "../../../../provider_api";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../../rpcSender";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FieldWithError} from "../../Survey/CreateProviderProfile";
import {LabeledTextInput} from "../../Survey/LabeledTextInput";
import ServiceProto = space.kenko.proto.ServiceProto;
import {RpcState} from "../../../../RpcState";
import {LabeledYesNoInput} from "../../Survey/LabeledYesNoInput";
import IPriceProto = space.kenko.proto.IPriceProto;
import IServiceProto = space.kenko.proto.IServiceProto;
import IPractitionerServiceProto = space.kenko.proto.IPractitionerServiceProto;

// This is the page for editing (or adding) a service.
export const AddOrUpdateService = () => {

  // If editing, the sku is a param. 
  const {skuParam} = useParams();
  const isEditing = !!skuParam;

  const {businessProfile, setBusinessProfile} = useProviderProfile();
  const {getIdTokenClaims} = useAuth0();

  const [service, setService] = useState<IServiceProto>();
  const [rpcState, setRpcState] = useState<RpcState>();
  const [saveTime, setSaveTime] = useState<Date>();

  const [sku, setSku] = useState<FieldWithError<string>>({value: ''});
  const [name, setName] = useState<FieldWithError<string>>({value: ''});
  const [category, setCategory] = useState<FieldWithError<string>>({value: ''});
  const [description, setDescription] = useState<FieldWithError<string>>({value: ''});
  const [price, setPrice] = useState<FieldWithError<number>>({value: 0});
  const [currency, setCurrency] = useState<FieldWithError<string>>({value: ''});
  const [length, setLength] = useState<FieldWithError<number>>({value: 0});
  const [availableLocations, setAvailableLocations] = useState<number[]>([]);
  const [availableVirtual, setAvailableVirtual] = useState<boolean>(false);

  const navigate = useNavigate();


  useEffect(() => {

    // Initial population of values if this is in edit mode.
    if (skuParam) {
      if (!businessProfile?.practice?.services) {
        return;
      }

      for (let i = 0; i < businessProfile.practice.services?.length; i++) {
        let service = businessProfile.practice.services[i];
        if (service.sku == skuParam) {
          setService(service);
          setSku({value: service.sku});
          setName({value: service.name});
          setCategory({value: service.category});
          setDescription({value: service.description});
          setLength({value: service.lengthMinutes});
          setAvailableLocations(service.availableLocations ?? []);
          setAvailableVirtual(service.virtualAvailable ?? false);
        }
      }
    }
  }, [])


  const submit = () => {

    // Clear the error
    setRpcState({});

    let serviceProto = new ServiceProto({
      sku: sku.value,
      description: description.value,
      name: name.value,
      category: category.value,
      lengthMinutes: length.value,
      availableLocations: availableLocations,
      virtualAvailable: availableVirtual,
      offeredBy: service?.offeredBy
    })

    let request = CreateServiceRequest.encode(new CreateServiceRequest({
      isUpdate: isEditing,
      service: serviceProto
    })).finish();

    SendRpc(getIdTokenClaims, 'create_service', request)
        .then(bytes => {
          let response = CreateServiceResponse.decode(bytes)
          if (response.errors && response.errors.length > 0) {
            response.errors.forEach(error => {
              switch (error.fieldName) {
                case 'sku':
                  setSku(sku => ({...sku, error: error.error}));
                  break;
                case 'lengthMinutes':
                  setLength({...length, error: error.error});
                  break;
                case 'name':
                  setName({...name, error: error.error});
                  break;
                case 'description':
                  setDescription({...description, error: error.error});
                  break;
              }
            })

            setRpcState({state: 'error', message: 'Error creating service. Please fix any invalid fields.'})

          } else if (!response.okay) {
            setRpcState({state: 'error', message: 'Unknown error creating service. Please try again later.'})
          }

          if (response.newProfile) {

            setBusinessProfile(response.newProfile);

            if (isEditing) {
              // Set the message that it was updated
              setRpcState({
                message: `Service saved at ${
                    new Date()?.toLocaleTimeString(undefined, {
                      hour: 'numeric',
                      minute: '2-digit',
                    })}`
              });

            } else {
              // Leave the page.
              navigate("/services");
            }
          }
        }).catch(reason => {
      setRpcState({state: 'error', message: 'Error creating service, code: ' + 32})
    })
  }

  const doesPractitionerOfferService = (providerId: string):
      { offered: boolean, price?: IPriceProto | null } => {

    if (!service?.offeredBy) {
      return {offered: false};
    }

    for (let i = 0; i < service.offeredBy.length; i++) {
      const offeredBy = service.offeredBy[i];
      if (offeredBy.providerId == providerId) {
        return {offered: true, price: offeredBy.price};
      }
    }

    return {offered: false};
  }

  //
  const addProviderToOffering = (providerId: string) => {
    console.log('adding provider', providerId)

    setService(service => ({
      ...service, offeredBy: [...(service?.offeredBy ?? []), {
        providerId: providerId,
        price: {
          currencyCode: 'USD',
          price: 0,
        }
      }]
    }));
  }

  const removeProviderFromOffering = (providerId: string) => {

    setService(service => ({
      ...service, offeredBy: service?.offeredBy ? [...service.offeredBy.filter(p => {
        return p.providerId != providerId
      })] : []
    }));
  }

  const updateProviderPrice = (providerId: string, price: number) => {

    setService(service => ({
      ...service, offeredBy: [...(service?.offeredBy ?? []).map(prev => {
        return prev.providerId == providerId ? {
          providerId: providerId,
          price: {
            currencyCode: 'USD',
            price: price
          }
        } : prev;
      })]
    }));
  }

  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>
          {isEditing ? `Edit service "${skuParam}"` : `Add a new Service`}
        </h1>
        <Link to={'/services'}>
          <button className={'BusinessProfileButton StandardButton'}>
            <>Back</>
          </button>
        </Link>
        <button onClick={(e) => {
          e.preventDefault();
          submit()
        }} className={'BusinessProfileButton DarkButton'}>
          Save
        </button>
      </div>


      <div className={'SectionHeaderRow'}>
        <a href={'#details'}>Details</a>
        <a href={'#locations'}>Locations</a>
        <a href={'#team'}>Team Members</a>

        <div style={{flex: 1, textAlign: "end"}}>
          {rpcState?.state == 'error' &&
              <div className={'OnboardingInputError'}>Error saving your profile. Please check for errors below.</div>}
          {rpcState?.state == 'success' && <div className={''}>Profile saved at {
            saveTime?.toLocaleTimeString(undefined, {
              hour: 'numeric',
              minute: '2-digit',
            })}</div>}
        </div>
      </div>
      <div className={'SectionHeaderRow'}>
        <div style={{flex: 1, textAlign: "end"}}>
          {rpcState?.state == 'error' && <div className={'InputError'}>{rpcState?.message}</div>}
          {rpcState?.state != 'error' && rpcState?.message && <div className={''}>{rpcState.message}</div>}
        </div>
      </div>
    </div>

    <div className={'ProviderToolAreaScroll'}>
      <div className={'ProviderToolAreaContent'}>


        <h2 id={'details'}>Details</h2>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>

          <LabeledTextInput label={'SKU'} disabled={isEditing}
                            value={sku} onChange={v => setSku({value: v})} inputType={'text'}/>
          <LabeledTextInput label={'Name'} value={name} onChange={v => setName({value: v})} inputType={'text'}/>
          <LabeledTextInput label={'Description'} value={description} onChange={v => setDescription({value: v})}
                            inputType={'textarea'}/>
          <LabeledTextInput label={'Category'} value={category} onChange={v => setCategory({value: v})}
                            inputType={'text'}/>
          <LabeledTextInput label={'Length (minutes)'} value={{value: length.toString(), error: length.error}}
                            onChange={v => setLength({value: parseInt(v)})}
                            inputType={'number'}/>
        </div>

        <br/>
        <br/>

        <h2 id={'locations'}>Locations</h2>
        <div>Select the locations where this service will be offered.</div>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>

          {businessProfile?.locations?.map((location, index) => {

            const availableHere = availableLocations.indexOf(index) >= 0;

            return <LabeledYesNoInput value={{value: availableHere}}
                                      label={location.locationName || `Unnamed Location ${index}`}
                                      onChange={yes => {
                                        if (yes) {
                                          setAvailableLocations(previous => {
                                            return [...previous, index];
                                          });
                                        } else {
                                          setAvailableLocations(previous => {
                                            return previous.filter(item => item != index)
                                          });
                                        }
                                      }}
                                      helpText={location.address ?? ''}

            />
          })}
        </div>

        <br/>
        <br/>

        <h2 id={'team'}>Team Members</h2>
        <div>Select which team members will offer this service.</div>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>

          {/* Each provider gets a row here. There's a checkbox to say whether they offer this
              service or not. If they do offer the service, there's an additional price field. */}
          {businessProfile?.providers?.map((provider, index) => {

            const offeringInfo = doesPractitionerOfferService(provider.providerId as string);

            return <div className={'HorizontalFlex'} style={{gap: 30}}>
              <LabeledYesNoInput value={{value: offeringInfo.offered}}
                                 label={provider.firstName + ' ' + provider.lastName}
                                 onChange={yes => {
                                   if (yes) {
                                     addProviderToOffering(provider.providerId as string)
                                   } else {
                                     removeProviderFromOffering(provider.providerId as string)
                                   }
                                 }}
                                 helpText={''}/>

              {offeringInfo.offered &&
                  <LabeledTextInput label={'price'}
                                    value={{value: offeringInfo.price?.price?.toString() ?? ''}}
                                    onChange={(v) => updateProviderPrice(provider.providerId as string, parseInt(v))}
                                    inputType={'number'}
                                    helpText={''}/>
              }

            </div>
          })}
        </div>

        <br/>
        <br/>
      </div>
    </div>
  </div>
};

