import React from 'react';
import './Onboarding.css';
import {FieldWithError} from "./CreateProviderProfile";
import 'react-phone-number-input/style.css'
import '../../Styles.css'

type Props = {
  label: string;
  helpText?: string; // an optional string underneath 
  value: FieldWithError<string>;
  onChange: (value: string) => void;
  inputType: 'text' | 'tel' | 'email' | 'textarea' | 'number'
  disabled?: boolean,
}

export const LabeledTextInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'OnboardingTextInputLabel'}>{props.label}</div>

    {props.inputType != 'textarea' &&
        <input className={'OnboardingTextInput'} type={props.inputType} 
               value={props.value.value || ''}
               disabled={props.disabled}
               onChange={event => props.onChange(event.target.value)}/>}

    {props.inputType == 'textarea' &&
        <textarea className={'OnboardingTextAreaInput'}
                  value={props.value.value || ''}
                  rows={6}
                  disabled={props.disabled}
                  onChange={event => props.onChange(event.target.value)}/>}

    {props.helpText && <div className={'InputHelpText'}>{props.helpText}</div>}
    {props.value.error && <div className={'OnboardingInputError'}>{props.value.error}</div>}
  </div>

}