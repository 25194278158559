import {Link} from 'react-router-dom';
import React from "react";
import '../App.css'
import {useProviderProfile} from "../../ProviderProfileProvider";

interface Props {
  icon: string,
  link: string,
  text: string,
}

export const LeftNavIcon = (props: Props) => {
  return <Link to={props.link}>
    <div className={'left-nav-item'}>
      <img className={'left-nav-icon'} src={props.icon}/>
      {props.text}
    </div>
  </Link>
};