import React, {useContext, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {ProviderProfileContext, useProviderProfile} from "../../../../ProviderProfileProvider";
import {FieldWithError} from "../../Survey/CreateProviderProfile";
import {
  ErrorsProto,
  space,
  UpdateBusinessProfileRequest,
  UpdateBusinessProfileResponse
} from "../../../../provider_api";
import {SendRpc} from "../../../../rpcSender";
import {LabeledTextInput} from "../../Survey/LabeledTextInput";
import {LabeledPhoneInput} from "../../Survey/LabeledPhoneInput";
import {BusinessLocation} from "./BusinessLocation";
import '../../../App.css';
import {PhotoUpload} from "./PhotoUpload";
import BusinessLocationProto = space.kenko.proto.BusinessLocationProto;
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;

type RpcState = 'pending' | 'success' | 'error' | 'none';

export type LocationData = {
  name: FieldWithError<string>,
  address: FieldWithError<string>,
  // Lat and lng are derived from the address
  lat: number,
  lng: number,
  timeZone: FieldWithError<string>,
  phone: FieldWithError<string>,
  email: FieldWithError<string>,
  imageUrls: FieldWithError<string[]>,
}

export type ServiceData = {
  sku: FieldWithError<string>,
  name: FieldWithError<string>,
  category: FieldWithError<string>,
  description: FieldWithError<string>,
  price: FieldWithError<number>,
  currency: FieldWithError<string>,
  length: FieldWithError<number>,
}

export const BusinessProfile = () => {

  const {user} = useAuth0();

  const profile = useContext(ProviderProfileContext);

  const {businessProfile, setBusinessProfile} = useProviderProfile();
  const {getIdTokenClaims} = useAuth0()

  // If we can't attribute an error to a specific field.
  const [profileErrors, setProfileErrors] = useState<ErrorsProto[]>([]);
  const [locationErrors, setLocationErrors] = useState<ErrorsProto[]>([]);

  const [name, setName] = useState<FieldWithError<string>>({value: ''});
  const [description, setDescription] = useState<FieldWithError<string>>({value: ''});
  const [email, setEmail] = useState<FieldWithError<string>>({value: ''});
  const [phone, setPhone] = useState<FieldWithError<string>>({value: ''});
  const [website, setWebsite] = useState<FieldWithError<string>>({value: ''});
  const [shortUrl, setShortUrl] = useState<FieldWithError<string>>({value: ''});
  const [imageUrls, setImageUrls] = useState<FieldWithError<string[]>>({value: []});

  const [locations, setLocations] = useState<LocationData[]>([]);
  const [services, setServices] = useState<ServiceData[]>([]);

  const [rpcState, setRpcState] = useState<RpcState>('none')
  const [saveTime, setSaveTime] = useState<Date>();


  // Make a copy of the business proto so we can store edits.
  useEffect(() => {
    if (businessProfile) {
      setName({value: businessProfile.proto?.businessName});
      setDescription({value: businessProfile.proto?.businessDescription})
      setEmail({value: businessProfile.proto?.email});
      setPhone({value: businessProfile.proto?.phoneNumber});
      setName({value: businessProfile.proto?.businessName});
      setImageUrls({value: businessProfile.proto?.imageUrl})
      setShortUrl({value: businessProfile.proto?.shortUrl})

      setLocations(businessProfile.locations ? businessProfile.locations?.map((value, index) => {
        return {
          locationId: index,
          name: {value: value.locationName},
          address: {value: value.address},
          lat: value.lat  || 0,
          lng: value.lng || 0,
          timeZone: {value: value.timeZone},
          phone: {value: value.phone},
          email: {value: value.email},
          imageUrls: {value: value.imageUrl}
        };
      }) : []);

    }
  }, [businessProfile]);

  if (!businessProfile || !businessProfile.proto) {
    return <div>error - no profile!</div>
  }

  const addLocation = () => {
    setLocations([...locations, {
      address: {value: ''},
      timeZone: {value: Intl.DateTimeFormat().resolvedOptions().timeZone},
      lat: 0,
      lng: 0,
      name: {value: ''},
      email: {value: ''},
      phone: {value: ''},
      imageUrls: {value: []}
    }]);
  };

  // Updates a specific location by index. I need to do lambda in order to allow multiple
  // calls per frame (e.g. RPC callback, when there are many errors). Otherwise only
  // the last one takes.
  const updateLocation = (i: number, lambda: (previous: LocationData) => LocationData) => {
    setLocations(previous => previous?.map((value, index) => {
      return index == i ? lambda(value) : value;
    }));
  };

  const submit = () => {

    let error = false;

    setProfileErrors([]);
    setLocationErrors([]);
    setRpcState('pending');

    let request = UpdateBusinessProfileRequest.encode(new UpdateBusinessProfileRequest({
      businessProfile: {
        proto: {
          email: email.value,
          businessDescription: description.value,
          businessName: name.value,
          phoneNumber: phone.value,
          websiteUrl: website.value,
          imageUrl: imageUrls.value,
          shortUrl: shortUrl.value
        },
        locations: locations.map(location => new BusinessLocationProto({
          locationName: location.name?.value,
          email: location.email?.value,
          phone: location.phone?.value,
          address: location.address?.value,
          lat: location.lat,
          lng: location.lng,
          timeZone: location.timeZone?.value,
          imageUrl: location.imageUrls?.value,
        }))
      }
    })).finish();

    SendRpc(getIdTokenClaims, 'update_business_profile', request)
        .then(resp => {
          let response = UpdateBusinessProfileResponse.decode(resp);
          if (!response.okay) {
            setRpcState('error');
            response.profileErrors.forEach(value => {
              if (value.fieldName == 'shortUrl') {
                shortUrl.error = value.error;
              } else if (value.fieldName == 'businessName') {
                name.error = value.error;
              }
              if (value.fieldName == 'phone') {
                phone.error = value.error;
              } else {
                setProfileErrors((previous) => [...previous, value as ErrorsProto]);
              }
            })

            response.locationErrors.forEach(error => {
              const i = error.index || 0;
              console.log(error);
              if (i >= 0) {
                const location = locations[i];
                if (error.fieldName == 'locationName') {
                  updateLocation(i, (previous: LocationData) => {
                    return {...previous, name: {...previous.name, error: error.error}};
                  });
                } else if (error.fieldName == 'address') {
                  updateLocation(i, (previous: LocationData) => {
                    return {...previous, address: {...previous.address, error: error.error}};
                  });
                } else if (error.fieldName == 'timeZone') {
                  updateLocation(i, (previous: LocationData) => {
                    return {...previous, timeZone: {...previous.timeZone, error: error.error}};
                  });
                } else {
                  setLocationErrors(previous => [...previous, error as ErrorsProto])
                }
              } else {
                setLocationErrors(previous => [...previous, error as ErrorsProto])
              }
            });

            return;
          }

          console.log('New profile: ' + response.profile)
          setBusinessProfile(response.profile);
          setRpcState('success');
          setSaveTime(new Date())

        }).catch(e => {
      setRpcState('error');
    });
  };

  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Business Profile</h1>
        <button className={'BusinessProfileButton'}>Cancel</button>
        <button onClick={submit} disabled={rpcState == 'pending'} className={'BusinessProfileButton DarkButton'}>
          {rpcState == 'pending' &&
              <img className={"BusinessProfileSaveSpiner"} src={require('../../../../images/loading_spinner.gif')}/>}
          {rpcState != 'pending' && <>Save</>}
        </button>
      </div>

      <div className={'SectionHeaderRow'}>
        <a href={'#general'}>General</a>
        <a href={'#locations'}>Locations</a>
        <a href={'#photos'}>Photos</a>

        <div style={{flex: 1, textAlign: "end"}}>
          {rpcState == 'error' &&
              <div className={'OnboardingInputError'}>Error saving your profile. Please check for errors below.</div>}
          {rpcState == 'success' && <div className={''}>Profile saved at {
            saveTime?.toLocaleTimeString(undefined, {
              hour: 'numeric',
              minute: '2-digit',
            })}</div>}
        </div>
      </div>
    </div>

    <div className={'ProviderToolAreaScroll'}>
      <div className={'ProviderToolAreaContent'}>

        <h2 id={'general'}>General</h2>
        <div className={'InputSection'}>
          <LabeledTextInput label={'Business name'} value={name} onChange={v => setName({value: v})}
                            inputType={'text'}/>
          <LabeledTextInput label={'About your business'} value={description} onChange={v => setDescription({value: v})}
                            inputType={'textarea'}/>
          <LabeledPhoneInput label={'Primary business phone number'} value={phone}
                             onChange={v => setPhone({value: v})}/>
          <LabeledTextInput label={'Primary business email'} value={email} onChange={v => setEmail({value: v})}
                            inputType={'email'}/>
          <LabeledTextInput label={'Short URL'} value={shortUrl} onChange={v => setShortUrl({value: v})}
                            helpText={`This is the landing page for your site, like https://kenko.space/p/my-business. Once your site is published this cannot be changed.`}
                            inputType={'text'}/>
          <LabeledTextInput label={'Business website'} value={website} onChange={v => setWebsite({value: v})}
                            inputType={'text'}/>
        </div>

        <div className={'Flex1 HorizontalFlex'}>
          <div className={'Flex1'}><a id={'locations'}><h2>Business Locations</h2></a></div>
          <button className={'AddLocationButton'} onClick={addLocation}>Add a location</button>
        </div>

        {locationErrors && <div className={'VerticalFlex'}>{locationErrors.map(e => {
          return <div className={'ErrorText'}>{e.error}</div>
        })}</div>}

        <div className={'BusinessLocationSection'}>
          {locations?.map((location, i) => {
            // Any change to a field clears all the values.
            return <BusinessLocation value={location}
                                     onEmailChange={value => updateLocation(i, location => { return {...location, email: {value: value}}})}
                                     onAddressChange={value => updateLocation(i, location => {
                                       return {...location,
                                       address: {value: value?.formatted_address},
                                       lat: value?.geometry?.location?.lat() || 0,
                                       lng: value?.geometry?.location?.lng() || 0,
                                     }})}
                                     onTimeZoneChange={value => updateLocation(i, location => { return {...location, timeZone: {value: value}}})}
                                     onNameChange={value => updateLocation(i, location => { return {...location, name: {value: value}}})}
                                     onPhoneChange={value => updateLocation(i, location => { return {...location, phone: {value: value}}})}
            />
          })}
        </div>

        <h2 id={'photos'}>Photos</h2>
        <div className={'BusinessLocationSection'}>

          <PhotoUpload locationName={'General'} imageUrl={imageUrls.value ? imageUrls.value[0] : ''}
              // Note this is a hack enforcement of one image only.
                       onImageUrlChange={value => setImageUrls({value: [value]})}/>

          {locations?.map((location, i) => {
            return <PhotoUpload
                locationName={location.name.value || 'Location ' + i}
                imageUrl={location.imageUrls.value ? location.imageUrls.value[0] : ''}
                onImageUrlChange={value => updateLocation(i, location => { return {...location, imageUrls: {value: [value]}}})}/>
          })}
        </div>
      </div>
    </div>
  </div>;

}