import {LabeledTextInput} from "./LabeledTextInput";
import React, {useState} from "react";
import {LabeledCountryInput} from "./LabeledCountryInput";
import {AcceptTosInput} from "./AcceptTosInput";
import {SendRpc} from "../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {CreateOrUpdateTeamMemberRequest, CreateOrUpdateTeamMemberResponse, space} from "../../../provider_api";
import {useProviderProfile} from "../../../ProviderProfileProvider";
import {LabeledPhoneInput} from "./LabeledPhoneInput";

export type FieldWithError<T> = {
  value: T | null | undefined,
  error?: string | null,
}

export const CreateProviderProfile = () => {

  const {getIdTokenClaims} = useAuth0();
  const {provider, setProvider, businessProfile, setBusinessProfile} = useProviderProfile();


  const [firstName, setFirstName] = useState<FieldWithError<string>>({value: ''});
  const [lastName, setLastName] = useState<FieldWithError<string>>({value: ''});
  const [mobileNumber, setMobileNumber] = useState<FieldWithError<string>>({value: ''});
  const [country, setCountry] = useState<FieldWithError<string>>({value: 'US'});
  const [acceptedTos, setAcceptedTos] = useState<FieldWithError<boolean>>({value: false});
  const [sendingRpc, setSendingRpc] = useState<FieldWithError<boolean>>({value: false})


  const submit = () => {

    let error = false;

    if (!firstName.value) {
      setFirstName({...firstName, error: "Please enter your first name"});
      error = true;
    }

    if (!lastName.value) {
      setLastName({...lastName, error: "Please enter your last name"});
      error = true;
    }

    if (!mobileNumber.value) {
      setMobileNumber({...mobileNumber, error: "Please enter your mobile number"});
      error = true;
    }

    if (country.value != 'US') {
      setCountry({...country, error: "Sorry, this country is not supported yet!"});
      error = true;
    }

    if (!acceptedTos.value) {
      setAcceptedTos({...acceptedTos, error: "You must accept these to use Kenko"});
      error = true;
    }

    if (!error) {

      setSendingRpc({value: true, error: null});

      let request = CreateOrUpdateTeamMemberRequest.encode(new CreateOrUpdateTeamMemberRequest({
        isUpdate: false,
        bio: {
          providerId: provider?.providerId,
          firstName: firstName.value,
          lastName: lastName.value,
          phone: mobileNumber.value,
        }}
      )).finish();

      SendRpc(getIdTokenClaims, 'create_team_member', request)
          .then(resp => {
            let response = CreateOrUpdateTeamMemberResponse.decode(resp);
            if (!response.okay) {
              setSendingRpc({value: false, error: 'Error updating profile. Please try again later.'})
              return;
            }

            console.log('New profile: ' + response)
            setBusinessProfile(response.newProfile);

          }).catch(e => {
        setSendingRpc({value: false, error: `Error updating profile. ${e}`});
      });
    }
  };

  return <div className={'CreateProfileSection'}>

    <h1>Create your profile</h1>

    <LabeledTextInput label={'First name'} value={firstName} inputType={'text'}
                      onChange={v => setFirstName({value: v, error: null})}/>
    <LabeledTextInput label={'Last name'} value={lastName} inputType={'text'}
                      onChange={v => setLastName({value: v, error: null})}/>
    <LabeledPhoneInput label={'Mobile number'} value={mobileNumber}
                       onChange={v => setMobileNumber({value: v, error: null})}/>

    <LabeledCountryInput label={'Country'} value={country} onChange={v =>
        setCountry({value: v, error: v == 'US' ? null : "Sorry, this country is not supported yet!"})}/>

    <AcceptTosInput value={acceptedTos} onChange={v => setAcceptedTos({value: v, error: null})}/>

    <button onClick={submit} disabled={sendingRpc.value || false} className={'CreateProfileDarkButton'}>
      {sendingRpc.value &&
          <img className={"CreateProfileSpinner"} src={require('../../../images/loading_spinner.gif')}/>}
      {!sendingRpc.value && <>Join Kenko</>}
    </button>

    {sendingRpc.error && <div className={'OnboardingInputError'}>{sendingRpc.error}</div>}

    <div style={{height: 100}}/>

  </div>


}