import React, {useContext} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {ProviderProfileContext} from "../../../ProviderProfileProvider";

export const Home = () => {

  const {user} = useAuth0();

  const profile = useContext(ProviderProfileContext);
  
  return <div style={{textAlign: 'start'}}>
    <h2>
      Your home page for {profile.businessProfile?.proto?.businessName}!
    </h2>

    <pre style={{color: 'lightgray'}}>
  {JSON.stringify(profile, null, 2)}
    </pre>

  </div>
}