import {AppState, Auth0Provider, User} from '@auth0/auth0-react';
import React, {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';

interface Props {
  children?: ReactNode
}

export const Auth0ProviderWithNavigate = (props: Props) => {
  const navigate = useNavigate();

  // const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  // const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  // const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

  const onRedirectCallback = (appState?: AppState, user?: User) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  // if (!(domain && clientId && redirectUri)) {
  //   return null;
  // }

  return (
      <Auth0Provider
          domain="login.thymebook.com"
          clientId="ofL05yXSrmwOYCMw0C21VSyEsBgmwO31"
          authorizationParams={{
            scope: 'openid email profile',
            redirect_uri: window.location.origin,
            prompt: "select_account", // allow them to pick a different google account 
          }}
          onRedirectCallback={onRedirectCallback}
      >
        {props.children}
      </Auth0Provider>
  );
};