import React, {useEffect, useState} from 'react';
import {
  GetLoginsForBusinessRequest,
  GetLoginsForBusinessResponse,
  IApiProviderLoginProto
} from "../../../../provider_api";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {Link} from "react-router-dom";
import {SendRpc} from "../../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";

export const Team = () => {

  const {provider, businessProfile} = useProviderProfile();

  const [logins, setLogins] = useState<IApiProviderLoginProto[]>([]);
  const [loginsRpcError, setLoginsRpcError] = useState<string>();

  const {getIdTokenClaims, user} = useAuth0();

  useEffect(() => {
    const request = GetLoginsForBusinessRequest.encode(new GetLoginsForBusinessRequest()).finish();

    SendRpc(getIdTokenClaims, 'get_logins', request)
        .then(r => {
          const response = GetLoginsForBusinessResponse.decode(r);
          if (!response.okay) {
            setLoginsRpcError("error loading logins");
          }

          setLogins(response.provider);

        })
        .catch(e => {
          setLoginsRpcError(e);
        })
  }, []);

  const getLogin = (providerId: string | null | undefined) => {
    for (let i = 0; i < logins?.length; i++) {
      if (logins[i].providerId == providerId) {
        return logins[i];
      }
    }

    return null;
  }

  const isAdmin = () => {
    return provider?.roles && provider.roles.indexOf('admin') >= 0;
  }

  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Team Members</h1>
        <Link to={'add'} state={{newService: true}}>
          <button className={'BusinessProfileButton DarkButton'}>
            Add
          </button>
        </Link>
      </div>
    </div>

    <div className={'ProviderToolAreaScroll'}>
      <div className={'ProviderToolAreaContent'}>

        <table>
          <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Contact email</th>
            <th>Phone number</th>
            {/*<th>Job Title</th>*/}
            {/*<th>Provider ID</th>*/}
            <th>Last login email</th>
            <th>Roles</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          {businessProfile?.providers?.map(bio => {
            const login = getLogin(bio.providerId);
            return <tr key={bio.providerId}>
              <td><img style={{maxWidth: 50, maxHeight: 50}}
                       src={bio.imageUrls?.length ? bio.imageUrls[0] : undefined}/></td>
              <td>{bio.firstName} {bio.lastName}</td>
              <td>{bio.email}</td>
              <td>{bio.phone}</td>
              {/*<td>{bio.jobTitle}</td>*/}
              {/*<td>{bio.providerId}</td>*/}
              <td>{login?.lastLoginEmail || ''}
                {login?.loginId == user?.sub && <div><b>(you)</b></div>}
              </td>
              <td>{login?.roles?.join(', ')}</td>
              <td>
                {(isAdmin() || (provider?.providerId == bio.providerId)) &&
                    <Link to={`/team/edit/${bio.providerId}`}>Edit</Link>}
              </td>
            </tr>
          })}
          </tbody>

        </table>


        {!businessProfile?.providers?.length && <div>
          <br/><br/>
          Click "Add" above to add your first team member!
        </div>}

      </div>
    </div>
  </div>

};

